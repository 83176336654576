import { Box, Button, Container, Grid, Paper, Skeleton, Typography, useTheme } from "@mui/material";
import Welcome from "../welcome";
import { MotivationIllustration } from "src/assets/illustrations";
import { useResponsive } from "src/hooks/use-responsive";
import { setFilterMinDatePicker } from "src/context/reducers/filter-settings/filterSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useGetStatsCustomLeadQuery } from "src/context/api/businessOverview/api";
import { fDate } from "src/utils/format-time";
import { useCurrentWorkspace } from "src/context/reducers/app-settings";
import { SplashScreen } from "src/components/loading-screen";
import Iconify from "src/components/iconify";
import BankingWidgetSummary from "../banking-widget-summary";
import ChartMixedCustomLeadStacked from "src/sections/charts/chart-mixed-custom-lead-stacked";
import ChartMixedCustomLeadNoStacked from "src/sections/charts/chart-mixed-custom-lead-nostacked";
import ChartColumnStacked from "src/sections/charts/chart-column-standart-lead";
import ChartMixed from "src/sections/charts/chart-standart-lead";
import { differenceInDays } from "date-fns";
import { useLocales } from "src/locales";
import { useBoolean } from "src/hooks/use-boolean";
import { formatWeekLabel } from "src/utils/dates-labels";

export default function CustomLead() {
    const { t, currentLang } = useLocales();

    const lgUp = useResponsive('up', 'lg');
    const dispatch = useDispatch();
    const workspace = useCurrentWorkspace();
    const filterStore = useSelector((state: any) => state.filters);
    const theme = useTheme()
    const disableWelcome = useBoolean();

    const skipQuery = !filterStore.start || !filterStore.end || !workspace?.id;
    const { data: statsData, error: statsError, isFetching } = useGetStatsCustomLeadQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        compareStartDate: filterStore.compareStart !== undefined ? fDate(filterStore.compareStart, "yyyy-MM-dd") : null,
        compareEndDate: filterStore.compareEnd !== undefined ? fDate(filterStore.compareEnd, "yyyy-MM-dd") : null,
        timestep: filterStore.timestep,
        device: filterStore.devices,
        countries: filterStore.countries,
        keywords: filterStore.keywords,
        workspaceId: workspace?.id
    },{
        skip: skipQuery,
        refetchOnMountOrArgChange: false,

    });
    
    const [customLabels, setCustomLabels] = useState<any>(statsData?.stats.map((item: any) => item?.timestep) || []);




    enum ColorSchema {
        PRIMARY = 'primary',
        SECONDARY = 'secondary',
        TERTIARY = 'tertiary',
        ERROR = 'error',
        WARNING = 'warning',
        INFO = 'info',
        SUCCESS = 'success'
    }

    useEffect(() => {
        dispatch(setFilterMinDatePicker(statsData?.minDatePicker[0]?.first_date))
    }, [statsData])

    const getDaysDifference = (startDate: any, endDate: any) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        return differenceInDays(end, start);
    }

    const getTimestep = (startDate: any, endDate: any) => {
        const daysDifference = getDaysDifference(startDate, endDate);

        if (daysDifference > 365) {
            return 'year_month';
        } else if (daysDifference > 90) {
            return 'year_week';
        } else {
            return 'date';
        }
    }

    const { data: sparkLinesData, error: sparkLinesDataError, isFetching: isFetchingSparkLinesData } = useGetStatsCustomLeadQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        compareStartDate: filterStore.compareStart !== undefined ? fDate(filterStore.compareStart, "yyyy-MM-dd") : null,
        compareEndDate: filterStore.compareEnd !== undefined ? fDate(filterStore.compareEnd, "yyyy-MM-dd") : null,
        timestep: getTimestep(filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null, filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null),
        device: filterStore.devices,
        countries: filterStore.countries,
        keywords: filterStore.keywords,
        workspaceId: workspace?.id
    },{
        skip: skipQuery,
        refetchOnMountOrArgChange: false,

    });

    const sparkLinesDataTotalAdCost = sparkLinesData?.stats?.map((item: any) => {
        const daysDifference = getDaysDifference(filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null, filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null);

        if (daysDifference > 365) {
            const sum = (item?.total_ad_cost || 0);
            return { y: sum, x: item?.timestep };
        } else if (daysDifference > 90) {
            const sum = (item?.total_ad_cost || 0);
            return { y: sum, x: item?.timestep };
        } else {
            const sum = (item?.total_ad_cost || 0);

            const date = new Date(item?.timestep);
            const year = date.getUTCFullYear();
            const month = String(date.getUTCMonth() + 1).padStart(2, '0');
            const day = String(date.getUTCDate()).padStart(2, '0');
            return { y: sum, x: `${year}-${month}-${day}` };
        }
    });

    const sparkLinesDataTotalActualSavings = sparkLinesData?.stats_savings?.map((item: any) => {
        const daysDifference = getDaysDifference(filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null, filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null);

        if (daysDifference > 365) {
            const sum = (item?.total_actual_savings || 0);
            return { y: sum, x: item?.year_month };
        } else if (daysDifference > 90) {
            const sum = (item?.total_actual_savings || 0);
            return { y: sum, x: item?.year_week };
        } else {
            const sum = (item?.total_actual_savings || 0);

            const date = new Date(item?.date);
            const year = date.getUTCFullYear();
            const month = String(date.getUTCMonth() + 1).padStart(2, '0');
            const day = String(date.getUTCDate()).padStart(2, '0');
            return { y: sum, x: `${year}-${month}-${day}` };
        }
    });

    const sparkLinesDataTotalBlendedCPL = sparkLinesData?.stats?.map((item: any) => {
        const daysDifference = getDaysDifference(
            filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
            filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null
        );

        const blendedConversions = item?.total_blended_conversions || 0;
        const adCost = item?.total_ad_cost || 0;
        const cpl = blendedConversions > 0 ? adCost / blendedConversions : 0;

        if (daysDifference > 365) {
            return { y: cpl, x: item?.timestep };
        } else if (daysDifference > 90) {
            return { y: cpl, x: item?.timestep };
        } else {
            const date = new Date(item?.timestep);
            const year = date.getUTCFullYear();
            const month = String(date.getUTCMonth() + 1).padStart(2, '0');
            const day = String(date.getUTCDate()).padStart(2, '0');
            return { y: cpl, x: `${year}-${month}-${day}` };
        }
    });

    const sparkLinesDataTotalBlendedLeads = sparkLinesData?.stats?.map((item: any) => {
        const daysDifference = getDaysDifference(filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null, filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null);

        if (daysDifference > 365) {
            const sum = (item?.total_blended_conversions || 0);
            return { y: sum, x: item?.timestep };
        } else if (daysDifference > 90) {
            const sum = (item?.total_blended_conversions || 0);
            return { y: sum, x: item?.timestep };
        } else {
            const sum = (item?.total_blended_conversions || 0);

            const date = new Date(item?.timestep);
            const year = date.getUTCFullYear();
            const month = String(date.getUTCMonth() + 1).padStart(2, '0');
            const day = String(date.getUTCDate()).padStart(2, '0');
            return { y: sum, x: `${year}-${month}-${day}` };
        }
    });

    const stats = [
        {
            title: t('dashboard.global.savings'),
            value: statsData?.total_actual_savings,
            compareData: parseFloat((((statsData?.total_actual_savings - statsData?.total_actual_savings_compare) / statsData?.total_actual_savings_compare) * 100).toFixed(2)),
            color: ColorSchema.SUCCESS,
            textColor: '#118d57',
            spanColor: theme.palette.mode === "dark" ? '#6dad8d' : 'transparent',
            icon: "solar:hand-money-bold-duotone",
            series: sparkLinesDataTotalActualSavings
        },
        {
            title: t('dashboard.global.ad_cost'),
            value: statsData?.total_ad_cost,
            color: ColorSchema.WARNING,
            textColor: '#b76e00',
            icon: "solar:money-bag-bold-duotone",
            compareData: parseFloat((((statsData?.total_ad_cost - statsData?.total_ad_cost_compare) / statsData?.total_ad_cost_compare) * 100).toFixed(2)),
            spanColor: theme.palette.mode === "dark" ? '#ffd066' : 'transparent',
            series: sparkLinesDataTotalAdCost
        },
        {
            title: t('dashboard.global.blended_leads'),
            value: parseFloat((statsData?.total_blended_conversions)),
            compareData: parseFloat((((statsData?.total_blended_conversions - statsData?.total_blended_conversions_compare) / statsData?.total_blended_conversions_compare) * 100).toFixed(2)),
            color: ColorSchema.TERTIARY,
            textColor: '#006c9c',
            spanColor: theme.palette.mode === "dark" ? '#65b5c9' : 'transparent',
            icon: "solar:magnet-wave-bold-duotone",
            series: sparkLinesDataTotalBlendedLeads
        },
        {
            title: t('dashboard.global.blended_cpl'),
            value: statsData?.total_blended_conversions > 0
                ? parseFloat((statsData?.total_ad_cost / statsData?.total_blended_conversions).toFixed(3))
                : 0,
            compareData: parseFloat((((statsData?.total_ad_cost / statsData?.total_blended_conversions) - (statsData?.total_ad_cost_compare / statsData?.total_blended_conversions_compare)) / (statsData?.total_ad_cost_compare / statsData?.total_blended_conversions_compare) * 100).toFixed(2)),
            color: ColorSchema.INFO,
            textColor: '#5119b7',
            icon: "solar:tag-price-bold-duotone",
            spanColor: theme.palette.mode === "dark" ? '#9e77da' : 'transparent',
            series: sparkLinesDataTotalBlendedCPL
        },
    ]

    const getFilteredColumns = (data: any) => {
        if (!data || data.length === 0) return [];
        const firstRow = data[0];
        return Object.keys(firstRow).filter(key =>
            key.toLowerCase().includes('_line') ||
            key.toLowerCase().includes('_bar') ||
            key.toLowerCase().includes('_stacked') ||
            key.toLowerCase().includes('_usd')
        );
    };

    const formatColumnName = (col: any) => {
        return col.replace(/_/g, ' ').replace(/ (line|bar|usd|stacked)$/i, '').replace(/Line|Bar|Stacked|USD/i, '');
    };

    const filteredColumns = getFilteredColumns(statsData?.stats || []);

    const getTitleFromColumns = (columns: any) => {
        if (!columns || columns.length === 0) return '';

        const capitalizeFirstLetter = (string: string) => {
            return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        };

        const capitalizeWords = (str: string) => {
            return str.split(' ').map(capitalizeFirstLetter).join(' ');
        };

        const formattedNames = columns.map((col: any) => capitalizeWords(formatColumnName(col)));

        if (formattedNames.length > 5) return 'Custom Metrics';

        if (formattedNames.length === 1) return formattedNames[0];

        const lastColumn = formattedNames.pop();
        return formattedNames.join(', ') + ' & ' + lastColumn;
    };

    const dynamicTitle = getTitleFromColumns(filteredColumns);

    const dynamicSeries = filteredColumns.map((col, index) => ({
        name: formatColumnName(col),
        data: statsData?.stats?.map((item: any) => (typeof item[col] === 'number' && !isNaN(item[col]) ? item[col] : 0)),
        type: col.toLowerCase().includes('_line') ? 'line' : 'bar',
        stacked: col.toLowerCase().includes('_stacked') ? true : false,
        format: col.toLowerCase().includes('_usd') ? 'usd' : 'number'
    }));

    const savingsOverTime = [
        { name: t('dashboard.business_overview.actual_savings'), data: statsData?.stats_savings?.map((item: any) => item?.total_actual_savings) },
        { name: t('dashboard.business_overview.remaining_pot_savings'), data: statsData?.stats_savings?.map((item: any) => item?.total_savings) },
    ];

    const blendedLeadsAdCosts = [
        { name: t('dashboard.global.ad_cost'), data: statsData?.stats?.map((item: any) => item?.total_ad_cost ?? 0), type: "bar" },
        { name: t('dashboard.global.blended_leads'), data: statsData?.stats?.map((item: any) => item?.total_blended_conversions ?? 0), type: "line" },
        { name: t('dashboard.global.blended_cpl'), data: statsData?.stats?.map((item: any) => item?.total_blended_cpl ?? 0), type: "line" },
        { name: t('dashboard.global.blended_cpc'), data: statsData?.stats?.map((item: any) => item?.total_blended_cpc ?? 0), type: "line" }
    ]

    const isStacked = dynamicSeries.some((seriesItem: any) => seriesItem.stacked === true);

    useEffect(() => {
        if (statsData && !isFetching) {
            if (filterStore.timestep === 'year_week') {
                const formattedLabels = statsData.stats.map((item: any) => {
                    const [year, week] = item.timestep.split('-');
                    return formatWeekLabel(parseInt(year), parseInt(week), currentLang.value);
                });
                setCustomLabels(formattedLabels);
            } else if (filterStore.timestep === 'year_month') {
                const formattedDailyLabels = statsData.stats.map((item: any) => {
                    const [year, week] = item.timestep.split('-');
                    return `${week}/${year}`;
                })
                setCustomLabels(formattedDailyLabels);

            } else {
                const  formattedDailyDates = statsData.stats.map((item: any) => {
                    const newDate = new Date(item.timestep);
                    const month = String(newDate.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth() is zero-based, so we add 1
                    const day = String(newDate.getUTCDate()).padStart(2, '0');
                    const year = newDate.getUTCFullYear();
                    if (currentLang.value === 'fr') {
                        return `${day}/${month}/${year}`;
                    } else {
                        return `${month}/${day}/${year}`;
                    }
                })
                setCustomLabels(formattedDailyDates);
            }
        }

    }, [currentLang.value, statsData, filterStore.timestep]);
    return (
        <>
            {(!filterStore?.countries || !filterStore?.keywords || !filterStore?.devices)
                ? <SplashScreen />
                : <Container maxWidth="xl" sx={{ marginTop: 1 }}>

                    {!disableWelcome?.value &&
                        <Grid container spacing={2} sx={{ marginBottom: 3, borderRadius: 2 }}>
                            <Grid item xs={12}>
                                <Welcome
                                    onClose={() => disableWelcome.onTrue()}
                                    title={t('dashboard.global.welcome')}
                                    description={t('dashboard.business_overview.descriptionWelcome')}
                                    img={lgUp ? <MotivationIllustration /> : <></>}
                                />
                            </Grid>
                        </Grid>
                    }

                    <Grid container spacing={2}>
                        {stats.map((stat, index) => (
                            <Grid item key={index} xs={6} md={4} lg={3}>
                                {isFetching || (!statsData && !statsError) ? (
                                    <Skeleton
                                        width={'100%'}
                                        height={'110px'}
                                        style={{ borderRadius: 10 }}
                                        variant="rounded"
                                        animation="wave"
                                    >
                                        <>
                                            <Iconify py="auto" icon="eva:checkmark-circle-2-fill" color="success.main" />
                                            <Typography color="success.main">Active</Typography>
                                        </>
                                    </Skeleton>
                                ) : (
                                    <BankingWidgetSummary
                                        spanColor={stat.spanColor}
                                        title={stat?.title}
                                        compareData={stat?.compareData}
                                        textColor={stat?.textColor}
                                        icon={stat?.icon}
                                        color={stat.color}
                                        percent={0}
                                        total={stat?.value}
                                        chart={{
                                            series: stat.series,
                                        }}
                                    />
                                )}
                            </Grid>
                        ))}
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                        <Paper
                            sx={{
                                backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                                height: '500px',
                                borderRadius: 2,
                                marginTop: 3,
                                marginBottom: 1
                            }}
                        >

                            {isFetching || (!statsData && !statsError)
                                ? <Skeleton width={'100%'} height={'100%'} style={{ borderRadius: 10 }} variant="rectangular" animation={"wave"}>
                                    <>
                                        <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                        <Typography color={"success.main"}>Active</Typography>
                                    </>
                                </Skeleton>
                                : <div>
                                    <div style={{ paddingTop: 18, paddingLeft: 25, paddingRight: 25 }}>
                                        <Typography variant="overline" color="textSecondary" sx={{ mb: 1 }}>{t('dashboard.business_overview.business')}</Typography>

                                        <Typography variant="h6" sx={{ mb: 1 }}>
                                            {t('dashboard.business_overview.blended_leads_ad_cost')}
                                        </Typography>

                                        <Typography fontSize={16} sx={{ mb: 5, color: "#999999", opacity: .7 }}>
                                            {t('dashboard.business_overview.evolution_ad_cost_and_blended_conversions_organic_paid_over_time')}
                                        </Typography>

                                    </div>

                                    <Box sx={{ paddingLeft: 2 }}>
                                        <ChartMixed
                                            series={blendedLeadsAdCosts}
                                            labels={customLabels}
                                        />
                                    </Box>

                                </div>
                            }
                        </Paper>
                    </Grid>


                    <Grid item xs={12} md={6} lg={6}>
                        <Paper
                            sx={{
                                backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                                height: '500px',
                                borderRadius: 2,
                                marginTop: 3,
                                marginBottom: 1
                            }}
                        >

                            {isFetching || (!statsData && !statsError)
                                ? <Skeleton width={'100%'} height={'100%'} style={{ borderRadius: 10 }} variant="rectangular" animation={"wave"}>
                                    <>
                                        <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                        <Typography color={"success.main"}>Active</Typography>
                                    </>
                                </Skeleton>
                                : <div>
                                    <div style={{ paddingTop: 18, paddingLeft: 25, paddingRight: 25 }}>
                                        <Typography variant="overline" color="textSecondary" sx={{ mb: 1 }}>{t('dashboard.business_overview.business')}</Typography>

                                        <Typography variant="h6" sx={{ mb: 1 }}>
                                            {dynamicTitle}
                                        </Typography>

                                        <Typography fontSize={16} sx={{ mb: 5, color: "#999999", opacity: .7 }}>
                                            {t('dashboard.business_overview.evolution_custom_metrics_over_time')}
                                        </Typography>

                                    </div>

                                    <Box sx={{ paddingLeft: 2, paddingRight: 4 }}>
                                        {isStacked
                                            ? <ChartMixedCustomLeadStacked
                                                series={dynamicSeries}
                                                labels={customLabels}
                                            />
                                            : <ChartMixedCustomLeadNoStacked
                                                series={dynamicSeries}
                                                labels={customLabels}
                                            />
                                        }
                                    </Box>

                                </div>
                            }
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                        <Paper
                            sx={{
                                backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                                height: '600px',
                                borderRadius: 2,
                                marginTop: 2,
                                marginBottom: 1
                            }}
                        >

                            {isFetching || (!statsData && !statsError)
                                ? <Skeleton width={'100%'} height={'100%'} style={{ borderRadius: 10 }} variant="rectangular" animation={"wave"}>
                                    <>
                                        <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                        <Typography color={"success.main"}>Active</Typography>
                                    </>
                                </Skeleton>
                                : <div>
                                    <div style={{ paddingTop: 18, paddingLeft: 25, paddingRight: 25 }}>
                                        <Typography variant="overline" color="textSecondary" sx={{ mb: 1 }}>{t('dashboard.business_overview.business')}</Typography>

                                        <Typography variant="h6" sx={{ mb: 1 }}>
                                            {t('dashboard.business_overview.savings_over_time')}
                                        </Typography>

                                        <Typography fontSize={16} sx={{ mb: 5, color: "#999999", opacity: .7 }}>
                                            {t('dashboard.business_overview.evolution_brend_spends_savings_over_time')}
                                        </Typography>

                                    </div>

                                    <Box sx={{ paddingLeft: 2, paddingRight: 4 }}>
                                        <ChartColumnStacked
                                            series={savingsOverTime}
                                            labels={customLabels}
                                        />
                                    </Box>

                                </div>
                            }
                        </Paper>
                    </Grid>

                </Container>
            }
        </>
    )
}
import { Box, Button, Card, List, ListItem, Typography } from "@mui/material";
import { Stack, useTheme } from "@mui/system";
import Iconify from 'src/components/iconify';
import { useUpdateSsoMutation } from 'src/context/api/auth';
import { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'src/components/snackbar'
import { useAuth0 } from '@auth0/auth0-react';

export default function LinkedAccounts(data: any) {
    const { loginWithPopup, getAccessTokenSilently } = useAuth0();
    const theme = useTheme();

    const [updateSso] = useUpdateSsoMutation();
    const [isGoogleLinked, setIsGoogleLinked] = useState(data.data.user.sso_google);
    const [isMicrosoftLinked, setIsMicrosoftLinked] = useState(data.data.user.sso_microsoft);

    const handleLinkGoogleAccount = async () => {
        try {
            await loginWithPopup({ authorizationParams: { connection: 'google-oauth2', state: { action: 'link_account' }, prompt: 'consent' } }) //scope: 'openid profile email',
            const token = await getAccessTokenSilently();
            const response: any = await updateSso({ token, provider: 'google', user_email: data.data.user.email })
            setIsGoogleLinked(true)
            enqueueSnackbar('Success : Account linked', {
                variant: 'success',
            });
        } catch (error) {
            enqueueSnackbar('Error : Error during linking account', {
                variant: 'error',
            });
        }
    }

    const handleLinkMicrosoftAccount = async () => {
        try {
            await loginWithPopup({ authorizationParams: { connection: 'windowslive', state: { action: 'link_account' }, scope: 'openid profile email', prompt: 'consent' } })
            const token = await getAccessTokenSilently();
            const response: any = await updateSso({ token, provider: 'microsoft', user_email: data.data.user.email })
            setIsMicrosoftLinked(true)
            enqueueSnackbar('Success : Account linked', {
                variant: 'success',
            });
        } catch (error) {
            enqueueSnackbar('Error : Error during linking account', {
                variant: 'error',
            });
        }
    }

    useEffect(() => {
        if (data?.data) {
            setIsGoogleLinked(data.data.user.sso_google);
            setIsMicrosoftLinked(data.data.user.sso_microsoft);
        }
    }, [data]);


    return (
        <Stack direction={"column"} spacing={3}>
            <Card sx={{ padding: 3 }}>
                <Typography variant="subtitle1" fontWeight={'bold'} sx={{ color: theme.palette.mode == "dark" ? "white" : 'black', textAlign: 'start', pb: 3 }}>
                    Single Sign-on
                </Typography>
                <List sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>

                    {/* GOOGLE */}
                    <ListItem sx={{ border: 'solid 0.3px', borderRadius: "8px", p: "8px", borderColor: 'rgba(145, 158, 171, 0.2)' }} >
                        <Stack direction="row" spacing={1} sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>


                            <Typography variant="body2" color="#637381">Link Google Account</Typography>
                            {
                                !isGoogleLinked ? (
                                    <Button onClick={handleLinkGoogleAccount} variant="contained" sx={{ width: '260px', display: 'flex', gap: 5, backgroundColor: theme.palette.mode == 'dark' ? 'white' : 'black', color: theme.palette.mode == 'dark' ? 'black' : 'white' }} >
                                        <Stack direction='row' alignItems='center' sx={{ width: '100%' }} >
                                            <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                <Iconify icon="flat-color-icons:google" width={24} />
                                            </Box>
                                            <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                <Typography variant="subtitle2">
                                                    Sign-in with Google
                                                </Typography>
                                            </Box>

                                        </Stack>
                                    </Button>
                                ) : (
                                    <Stack direction='row' alignItems='center' gap={1} sx={{ width: '260px', display: 'flex', gap: 4, justifyContent: 'unset', backgroundColor: '#77ed8b', color: theme.palette.mode == 'dark' ? 'black' : 'white', p: '8px', borderRadius: '8px' }} >
                                        <Iconify icon="flat-color-icons:google" width={24} />
                                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                                            Linked with Google
                                        </Typography>
                                    </Stack>

                                )
                            }
                        </Stack>
                    </ListItem>
                    {/* Microsoft */}
                    <ListItem sx={{ border: 'solid 0.3px', borderRadius: "8px", p: "8px", borderColor: 'rgba(145, 158, 171, 0.2)' }} >
                        <Stack direction="row" spacing={1} sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                            <Typography variant="body2" color="#637381">Link Microsoft Account</Typography>
                            {
                                !isMicrosoftLinked ? (
                                    <Button onClick={handleLinkMicrosoftAccount} variant="contained" sx={{ width: '260px', display: 'flex', gap: 5, justifyContent: 'unset', ackgroundColor: theme.palette.mode == 'dark' ? 'white' : 'black', color: theme.palette.mode == 'dark' ? 'black' : 'white' }} >
                                        <Stack direction='row' alignItems='center' sx={{ width: '100%' }}>
                                            <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                <Iconify icon="logos:microsoft-icon" width={24} />
                                            </Box>
                                            <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                <Typography variant="subtitle2">
                                                    Sign-in with Microsoft
                                                </Typography>
                                            </Box>
                                        </Stack>


                                    </Button>
                                ) : (
                                    <Stack direction='row' alignItems='center' gap={1} sx={{ width: '260px', display: 'flex', gap: 5, justifyContent: 'unset', backgroundColor: '#77ed8b', color: theme.palette.mode == 'dark' ? 'black' : 'white', p: '8px', borderRadius: '8px' }} >
                                        <Iconify icon="logos:microsoft-icon" width={24} />
                                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                                            Linked with Microsoft
                                        </Typography>
                                    </Stack>

                                )
                            }
                        </Stack>
                    </ListItem>
                </List>
            </Card>
        </Stack>
    )
}
import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Autocomplete, Box, Button, MenuItem, Stack, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { RHFCheckbox, RHFSelect, RHFTextField } from "src/components/hook-form";
import FormProvider from "src/components/hook-form/form-provider";
import { useCurrentUserQuery } from "src/context/api/auth";
import { useUploadMutation } from "src/context/api/medias";
import { useGetAdAccountsQuery } from "src/context/api/resources";
import { ErrorResponse } from "src/context/api/shared/models";
import { useCreateWorkspaceMutation, useGetClientsNameQuery, useGetWorkspacesQuery, useUpdateWorkspaceMutation, useAddClientMutation } from "src/context/api/workspaces";
import { useLocales } from "src/locales";
import * as Yup from "yup";

interface Data {
    id?: number;
    name: string;
    url: string;
    bqTable: string;
    custom: boolean;
    averageCart?: number;
    template: string;
    logo?: File;
    currency: string; // Nouveau champ
}

interface Props {
    onCancel?: () => void;
    onSuccess?: () => void;
    values?: Data;
}

export default function WorkspaceCreate({ onCancel, onSuccess, values }: Props) {
    const { t } = useLocales();
    const schema = Yup.object({
        id: Yup.number().optional(),
        name: Yup.string().required(t("workspaces.form.create.name_required")),
        template: Yup.string().required(t("workspaces.form.create.template_required")),
        url: Yup.string()
            .required(t("workspaces.form.create.url_required"))
            .matches(/^(?!www\.)[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/, t("workspaces.form.create.url_invalid")),
        bqTable: Yup.string().required(t("workspaces.form.create.bqTable_required")),
        averageCart: Yup.number()
            .typeError(t("workspaces.form.create.averageCart_invalid"))
            .test('is-valid-format', t("workspaces.form.create.averageCart_invalid"), (value) => {
                if (value === undefined || value === null) return false; // Si la valeur est vide ou nulle, ne pas valider
                const stringValue = value.toString();
                return /^(\d+(\.\d+)?)?$/.test(stringValue);
            })
            .when('template', (template, schema) => {
                return (Array.isArray(template) ? template.includes('E-commerce') : template === 'E-commerce')
                    ? schema.required(t("workspaces.form.create.averageCart_required"))
                    : schema;
            }),
        custom: Yup.boolean().required(t("workspaces.form.create.custom_required")),
        logo: Yup.mixed<File>().optional().test('isFile', t("workspaces.form.logo_must_be_valid_file"), (value) => {
            return value !== undefined ? value instanceof File : true;
        }).test('tooLarge', t('workspaces.form.logo_must_be_under_2mb'), (value) => {
            return value !== undefined ? (value as File).size <= 2 * Math.pow(1000, 2) : true;
        }),
        currency: Yup.string().required(t("workspaces.form.create.currency_required")), // Validation pour le champ currency
    });

    const defaultValues: Data = useMemo(() => {
        if (values) {
            const { id, name, template, url, bqTable, custom, averageCart, currency } = values;
            return {
                id,
                logo: values.logo,
                name,
                url,
                bqTable,
                averageCart,
                template,
                custom,
                currency // Valeur par défaut pour le champ currency
            };
        }
        return {
            name: "",
            url: "",
            bqTable: "",
            averageCart: 0,
            template: "",
            custom: false,
            currency: "", // Valeur par défaut pour le champ currency
        };
    }, [values]);

    const methods = useForm<Data>({
        defaultValues: defaultValues,
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        methods.reset(defaultValues);
    }, [defaultValues, methods]);

    const selectedTemplate = methods.watch("template");
    const adAccounts = useGetAdAccountsQuery(null);
    const [create, workspace] = useCreateWorkspaceMutation();
    const workspaces = useGetWorkspacesQuery(null);
    const [update, updateWorkspace] = useUpdateWorkspaceMutation();
    const [upload, media] = useUploadMutation();
    const user = useCurrentUserQuery();
    const clients = useGetClientsNameQuery(null);
    const [clientsName, setClientsName] = useState<any>([]);
    const [bqTable, setBqTable] = useState<any>(methods.getValues("bqTable") || "");
    const [currency, setCurrency] = useState<any>(methods.getValues("currency") || "");
    const bqTableValue = methods.watch("bqTable");
    const { enqueueSnackbar } = useSnackbar();


    const [addClient] = useAddClientMutation();

    const onSubmit = (form: Data) => {
        const { logo, ...workspace } = form;

        const request = workspace.id ? update(workspace).unwrap().then(() => {
            enqueueSnackbar('Workspace update successfully', {
                variant: 'success',
            });
        }) : create(workspace).unwrap().then(() => {
            enqueueSnackbar('Workspace create successfully', {
                variant: 'success',
            });
        });

        if (logo) {
            request.then((res: any) => {
                const formData = new FormData();
                formData.append("file", logo);
                formData.append("resource_type", "workspace");
                formData.append("resource_id", res.id.toString());
                upload(formData);
            });
        }
    }

    useEffect(() => {
        if (updateWorkspace.isSuccess && onCancel && updateWorkspace.data) {
            onCancel();
            workspaces.refetch();
        }
    }, [workspace.isSuccess, updateWorkspace.isSuccess]);

    useEffect(() => {
        if (workspace.isSuccess && onSuccess) {
            onSuccess();
        }
    }, [media.isSuccess, workspace.isSuccess]);

    const [error, status] = useMemo(() => {
        let err = null;
        if (workspace.isError) {
            err = workspace.error;
        }
        if (media.isError) {
            err = media.error;
        }
        if (err && 'data' in err) {
            return [err.data as ErrorResponse, err.status];
        } else if (err && 'error' in err) {
            return [err.data as any, err.status];
        } else {
            return [err, err?.code];
        }
    }, [workspace.isError, media.isError]);

    const getErrorMessage = () => {
        return 'message' in error && typeof error.message === "string" ? error.message : 'Unknown error occurred';
    }

    useEffect(() => {
        if (clients?.data) {
            setClientsName(clients?.data);
        }
        const table = methods.getValues('bqTable');
        if (table.length > 0) {
            setBqTable(table);
        }

    }, [methods, clients]);

    const currencyOptions = [
        { label: 'Dollar (USD)', value: 'usd' },
        { label: 'Euro (EUR)', value: 'eur' },
    ];

    const handleCurrencyChange = (event: any, newValue: any) => {
        methods.setValue('currency', newValue ? newValue.value : "");
    };

    useEffect(() => {
        const urlValue = methods.watch('url');
        if (urlValue) {
            const domain = urlValue.replace(/(https?:\/\/)?(www\.)?/, '')
                .replace(/\.[a-z]{2,}(\.[a-z]{2,})?$/, '');

            let sanitizedBQTable = domain.replace(/[-.\s]/g, '').replace(/[^\w]/g, '');

            const existingBqTables = workspaces?.data?.map((ws: Data) => ws.bqTable) || [];
            let counter = 1;
            let originalBqTable = sanitizedBQTable;

            while (existingBqTables.includes(sanitizedBQTable)) {
                sanitizedBQTable = `${originalBqTable}${counter}`;
                counter++;
            }

            methods.setValue('bqTable', sanitizedBQTable.toLowerCase());
        }
    }, [methods.watch('url'), workspaces?.data]);

    return (
        <FormProvider autoComplete="off" methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
            {status === 400 && (
                <Alert severity="error">
                    Check the form for errors
                </Alert>
            )}
            {status === 500 && (
                <Alert severity="error">
                    Unexpected error has occurred
                </Alert>
            )}
            {status && !([500, 400] as any[]).includes(status) && error && (
                <Alert severity="error">
                    {getErrorMessage()}
                </Alert>
            )}
            <Stack sx={{ width: 400 }} padding={1} spacing={3}>
                <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
                    <RHFTextField fullWidth label={"Workspace Name"} name="name" />
                    <Box flexGrow={1} />
                </Stack>
                <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
                    <RHFTextField fullWidth label={"Workspace url"} name="url" />
                    <Box flexGrow={1} />
                </Stack>
                <Stack spacing={2} flex={1} direction={{ xs: "column", md: "row" }}>
                    <RHFSelect sx={{ width: '100%' }} label="Template" name="template">
                        <MenuItem value={"E-commerce"}>E-commerce</MenuItem>
                        <MenuItem value={'Lead'}>Lead</MenuItem>
                    </RHFSelect>
                    <Box flexGrow={1} />
                </Stack>

                {selectedTemplate === "E-commerce" && (
                    <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
                        <RHFTextField fullWidth label={"Average Cart"} name="averageCart" type="number" />
                        <Box flexGrow={1} />
                    </Stack>
                )}

                <Stack spacing={2} direction={{ xs: "column", md: "row" }} flex={1}>
                    <Autocomplete
                        sx={{ width: '100%' }}
                        options={currencyOptions}
                        value={currencyOptions?.find(option => option.value === currency)}
                        getOptionLabel={(option) => option.value}
                        onChange={handleCurrencyChange}
                        renderInput={(params) => <TextField {...params} label="Currency" />}
                        renderOption={(props, option: any) => (
                            <li {...props} key={option}>
                                {option?.value}
                            </li>
                        )}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                    />
                    <Box flexGrow={1} />
                </Stack>

                <Stack spacing={2} direction={{ xs: "column", md: "row" }} flex={1}>
                    <RHFCheckbox label="Custom" name="custom" />
                </Stack>

                <Stack spacing={2} alignItems={"end"} py={3} direction={{ xs: "column-reverse", md: "row-reverse" }}>
                    <Button onClick={onCancel}>
                        Cancel
                    </Button>
                    <LoadingButton
                        loading={workspace.isLoading || media.isLoading || updateWorkspace.isLoading}
                        variant="contained"
                        type="submit"
                    >
                        {values?.id ? "Update" : "Create"}
                    </LoadingButton>
                </Stack>
            </Stack>
        </FormProvider>
    );
}
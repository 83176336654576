import { Autocomplete, Avatar, Button, Checkbox, Chip, Grid, Link, Paper, Skeleton, TextField, Typography } from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import { MotivationIllustration } from "src/assets/illustrations";
import Welcome from "./welcomeCustom";
import { useResponsive } from "src/hooks/use-responsive";
import { useBoolean } from "src/hooks/use-boolean";
import { useLocales } from "src/locales";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { setFilterKeywordTopCompetitors, setFilterKeywordTopCompetitorsAds, setFilterKeywordTopCountries, setFilterKeywordTrafficAnalysis, setFilterMinDatePicker } from "src/context/reducers/filter-settings/filterSlice";
// import { useGetStatsDashQuery } from "src/context/api/trafficAnalysis/api";
import { fDate } from "src/utils/format-time";
import { useCurrentWorkspace } from "src/context/reducers/app-settings";
import { useTheme } from '@mui/material/styles';
import ChartDonutCustom from "src/sections/charts/chart-donut-custom";
import ChartColumnStacked from "src/sections/charts/chart-column-stacked-hub";
import DataGridCustomCountries from "src/components/data-grid/data-grid-custom-countries-hub";
import { useGetCountriesQuery } from "src/context/api/trafficDetails/api";
import DataGridCustomPresence from "src/components/data-grid/data-grid-custom-presence-custom-pagination-hub";
import { useGetAdsPlaLimitQuery, useGetAdsTxtLimitQuery, useGetPresenceQuery } from "src/context/api/competitorsMonitoring/api";
import Iconify from "src/components/iconify";
import { useRouter } from "src/routes/hooks";
import ChartMixed from "src/sections/charts/chart-mixed-hub";
import DataGridCustomKeywords from "src/components/data-grid/data-grid-custom-keywords-hub";
import SankeyChart from "src/sections/charts/chart-sankey";
import * as am5 from "@amcharts/amcharts5";
import { useGetStatsCustomLeadQuery, useGetStatsStandartEcommerceQuery } from "src/context/api/businessOverview/api";
import { fPercent } from "src/utils/format-number";
import ChartColumn from "src/sections/charts/chart-column-custom-hub";
import ChartPolarArea from "src/sections/charts/polar-area-chart";
import ChartPolarAreaLead from "src/sections/charts/polar-area-chart-lead";
import { FlagIcon } from "src/components/iconify/flagIcon";
import countriesJson from "../../../../utils/countries.json"
import CarouselBrandOverview from "src/components/carousel/carrousel-brand-overview";
import { useGetBrandPerformanceFlowQuery, useGetBrandTrafficPotentialQuery, useGetBusinessStatisticsQuery, useGetCountriesStatsQuery, useGetFiltersTopCompetitorsQuery, useGetFiltersTrafficAnalysisQuery, useGetKeywordsQuery, useGetStatsDashQuery, useGetTopCompetitorsQuery, useGetTopKeywordsQuery, useGetTrafficAnalysisQuery } from "src/context/api/brandOverview/api";
import InfiniteScrollKeywords from "./InfiniteScrollKeywordsCustom";
import { useDateRangeSelect } from "src/components/generic-select/date-range-select";
import InfiniteScrollKeywordsTrafficAnalysis from "./InfiniteScrollKeywordsTrafficAnalysis";
import InfiniteScrollKeywordsTopCompetitors from "./InfiniteScrollKeywordsTopCompetitors";
import InfiniteScrollKeywordsTopCompetitorsAds from "./InfiniteScrollKeywordsTopCompetitorsAds";
import { formatWeekLabel } from "src/utils/dates-labels";
import { skipToken } from '@reduxjs/toolkit/query/react';

export default function BrandOverview() {
    const { t, currentLang } = useLocales();
    const lgUp = useResponsive('up', 'lg');
    const disableWelcome = useBoolean();
    const dispatch = useDispatch();
    const filterStore = useSelector((state: any) => state.filters);
    const workspace: any = useCurrentWorkspace();
    const theme = useTheme();
    const router = useRouter();
    const [trafficAnalysis, setTrafficAnalysis] = useState("Traffic distribution");
    const [topCompetitorsAds, setTopCompetitorsAds] = useState("Text Ads");
    const [brandBalance, setBrandBalance] = useState("Blended Revenue")
    const [minWidth, setMinWidth] = useState(200);
    const [filterKeyword, setFilterKeywordState] = useState<any>(undefined);
    const [filterCountry, setFilterCountry] = useState<any>([]);
    const [filterKeywordTrafficAnalysis, setFilterKeywordTrafficAnalysisState]: any = useState<any>(undefined);
    const [filterCountryTrafficAnalysis, setFilterCountryTrafficAnalysis] = useState<any>([]);
    const [filterKeywordTopCompetitors, setFilterKeywordTopCompetitorsState]: any = useState<any>(undefined);
    const [filterCountryTopCompetitors, setFilterCountryTopCompetitors] = useState<any>([]);
    const [filterKeywordTopCompetitorsAds, setFilterKeywordTopCompetitorsAdsState]: any = useState<any>(undefined);
    const [filterCountryTopCompetitorsAds, setFilterCountryTopCompetitorsAds] = useState<any>([]);
    const [selectedCountriesBrandPerformanceFlow, setSelectedCountriesBrandPerformanceFlow] = useState<any>([]);
    const [selectedCountriesBusinessStatistic, setSelectedCountriesBusinessStatistic] = useState<any>([]);
    const [selectedCountriesTrafficAnalysis, setSelectedCountriesTrafficAnalysis] = useState<any>([])
    const [selectedCountriesTopCompetitors, setSelectedCountriesTopCompetitors] = useState<any>([])
    const [selectedCountriesTopCompetitorsAds, setSelectedCountriesTopCompetitorsAds] = useState<any>([])
    const [selectedCountriesBrandTrafficPotential, setSelectedCountriesBrandTrafficPotential] = useState<any>([])
    const [selectedCountriesTopKeywords, setSelectedCountriesTopKeywords] = useState<any>([])
    const [allDataFetched, setAllDataFetched] = useState(false);

    useEffect(() => {
        setSelectedCountriesTopKeywords([]);
        setSelectedCountriesBrandTrafficPotential([]);
        setSelectedCountriesTopCompetitorsAds([]);
        setSelectedCountriesTopCompetitors([]);
        setSelectedCountriesTrafficAnalysis([]);
        setSelectedCountriesBusinessStatistic([]);
        setSelectedCountriesBrandPerformanceFlow([]);
        setFilterCountryTopCompetitorsAds([]);
        setFilterKeywordTopCompetitorsAdsState([]);
        setFilterCountryTopCompetitors([]);
        setFilterKeywordTopCompetitorsState([]);
        setFilterCountryTrafficAnalysis([]);
        setFilterKeywordTrafficAnalysisState([]);

        dispatch(setFilterKeywordTopCompetitorsAds([]));
        dispatch(setFilterKeywordTopCompetitors([]));
        dispatch(setFilterKeywordTrafficAnalysis([]));
        dispatch(setFilterKeywordTopCountries([]));
    }, [workspace])

    const [start, end] = useMemo(() => {
        const startDate = new Date();
        startDate.setMonth(startDate.getUTCMonth() - 1);
        startDate.setDate(startDate.getUTCDate());
        const endDate = new Date();
        endDate.setDate(endDate.getUTCDate() - 1);
        return [startDate, endDate];
    }, []);

    const dateRangeSelect = useDateRangeSelect({
        preset: "last_30d",
        step: "year_week",
        selected: "custom",
        start,
        end
    });

    const skipQuery = !filterStore.start || !filterStore.end || !workspace?.id;

    const { data: keywordsData } = useGetKeywordsQuery({
        startDate: dateRangeSelect.start !== undefined ? fDate(dateRangeSelect.start, "yyyy-MM-dd") : null,
        endDate: dateRangeSelect.end !== undefined ? fDate(dateRangeSelect.end, "yyyy-MM-dd") : null,
        workspaceId: workspace?.id
    },
        {
            skip: skipQuery,
            refetchOnMountOrArgChange: false,

        }
    );

    enum ColorSchema {
        PRIMARY = 'primary',
        SECONDARY = 'secondary',
        TERTIARY = 'tertiary',
        ERROR = 'error',
        WARNING = 'warning',
        INFO = 'info',
        SUCCESS = 'success'
    }

    const { data: topCountriesData, error: topCountriesDataError, isFetching: isFetchingTopCountries } = useGetCountriesStatsQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        compareStartDate: filterStore.compareStart !== undefined ? fDate(filterStore.compareStart, "yyyy-MM-dd") : null,
        compareEndDate: filterStore.compareEnd !== undefined ? fDate(filterStore.compareEnd, "yyyy-MM-dd") : null,
        keywords: filterStore?.keywordsTopCountries,
        workspaceId: workspace?.id
    },
        {
            skip: skipQuery,
            refetchOnMountOrArgChange: false,

        }
    );

    const { data: dataBrandPerformanceFlow, error: dataBrandPerformanceFlowError, isFetching: isFetchingDataBrandPerformanceFlow } = useGetBrandPerformanceFlowQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        compareStartDate: filterStore.compareStart !== undefined ? fDate(filterStore.compareStart, "yyyy-MM-dd") : null,
        compareEndDate: filterStore.compareEnd !== undefined ? fDate(filterStore.compareEnd, "yyyy-MM-dd") : null,
        timestep: filterStore.timestep,
        countries: selectedCountriesBrandPerformanceFlow,
        workspaceId: workspace?.id
    },
        {
            skip: skipQuery,
            refetchOnMountOrArgChange: false,

        }
    );

    const { data: dataBusinessStatistics, error: dataBusinessStatisticsError, isFetching: isFetchingDataBusinessStatistics } = useGetBusinessStatisticsQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        compareStartDate: filterStore.compareStart !== undefined ? fDate(filterStore.compareStart, "yyyy-MM-dd") : null,
        compareEndDate: filterStore.compareEnd !== undefined ? fDate(filterStore.compareEnd, "yyyy-MM-dd") : null,
        timestep: filterStore.timestep,
        countries: selectedCountriesBusinessStatistic,
        workspaceId: workspace?.id
    },
        {
            skip: skipQuery,
            refetchOnMountOrArgChange: false,

        }

    );

    const { data: trafficAnalysisData, error: trafficAnalysisDataError, isFetching: isFetchingTrafficAnalysisData } = useGetTrafficAnalysisQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        timestep: filterStore.timestep,
        countries: selectedCountriesTrafficAnalysis,
        keywords: filterStore?.keywordsTrafficAnalysis,
        workspaceId: workspace?.id
    },
        {
            skip: skipQuery,
            refetchOnMountOrArgChange: false,

        }
    );

    const { data: filtersTrafficAnalysis, error: filtersTrafficAnalysisError, isFetching: isFetchingFiltersTrafficAnalysis } = useGetFiltersTrafficAnalysisQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        countries: selectedCountriesTrafficAnalysis,
        keywords: filterStore?.keywordsTrafficAnalysis,
        workspaceId: workspace?.id
    },
        {
            skip: skipQuery,
            refetchOnMountOrArgChange: false,

        }
    );

    const { data: filtersTopCompetitors, error: filtersTopCompetitorsError, isFetching: isFetchingFiltersTopCompetitors } = useGetFiltersTopCompetitorsQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        countries: selectedCountriesTopCompetitors,
        keywords: filterStore?.keywordsTopCompetitors,
        workspaceId: workspace?.id
    },
        {
            skip: skipQuery,
            refetchOnMountOrArgChange: false,

        }
    );

    const { data: filtersTopCompetitorsAds, error: filtersTopCompetitorsAdsError, isFetching: isFetchingFiltersTopCompetitorsAds } = useGetFiltersTopCompetitorsQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        countries: selectedCountriesTopCompetitorsAds,
        keywords: filterStore?.keywordsTopCompetitorsAds,
        workspaceId: workspace?.id
    },
        {
            skip: skipQuery,
            refetchOnMountOrArgChange: false,

        }
    );

    const { data: brandTrafficPotentialData, error: brandTrafficPotentialDataError, isFetching: isFetchingBrandTrafficPotentialData } = useGetBrandTrafficPotentialQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        timestep: filterStore.timestep,
        countries: selectedCountriesBrandTrafficPotential,
        workspaceId: workspace?.id
    },
        {
            skip: skipQuery,
            refetchOnMountOrArgChange: false,

        }
    );

    const { data: topKeywordsData, error: topKeywordsDataError, isFetching: isFetchingTopKeywordsData } = useGetTopKeywordsQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        timestep: filterStore.timestep,
        countries: selectedCountriesTopKeywords,
        workspaceId: workspace?.id
    },
        {
            skip: skipQuery,
            refetchOnMountOrArgChange: false,

        }
    );

    const { data: topCompetitorsData, error: topCompetitorsDataEror, isFetching: isFetchingTopCompetitorsData } = useGetTopCompetitorsQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        timestep: filterStore.timestep,
        countries: selectedCountriesTopCompetitors,
        keywords: filterStore?.keywordsTopCompetitors,
        workspaceId: workspace?.id,
        domain: workspace?.url,
    },
        {
            skip: skipQuery,
            refetchOnMountOrArgChange: false,

        }
    );

    const shouldFetchCustomQuery = workspace?.custom === true && workspace?.template === "Lead";

    const { data: customLead, error: statsErrorCustomLead, isFetching: isFetchingCustomLead } = useGetStatsCustomLeadQuery(
        shouldFetchCustomQuery
            ? {
                startDate: fDate(filterStore.start, "yyyy-MM-dd"),
                endDate: fDate(filterStore.end, "yyyy-MM-dd"),
                compareStartDate: filterStore.compareStart ? fDate(filterStore.compareStart, "yyyy-MM-dd") : null,
                compareEndDate: filterStore.compareEnd ? fDate(filterStore.compareEnd, "yyyy-MM-dd") : null,
                timestep: filterStore.timestep,
                device: filterStore.devices,
                countries: filterStore.countries,
                keywords: filterStore.keywords,
                workspaceId: workspace.id,
            }
            : skipToken,// Empêche l'appel si les conditions ne sont pas remplies
    );

    const { data: statsData, error: statsError, isFetching } = useGetStatsDashQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        compareStartDate: filterStore.compareStart !== undefined ? fDate(filterStore.compareStart, "yyyy-MM-dd") : null,
        compareEndDate: filterStore.compareEnd !== undefined ? fDate(filterStore.compareEnd, "yyyy-MM-dd") : null,
        timestep: filterStore.timestep,
        device: filterStore.devices,
        workspaceId: workspace?.id
    },
        {
            skip: skipQuery,
            refetchOnMountOrArgChange: false,
        }
    );

    const handleKeywordFilterChangeTopCountries = (value: any) => {
        if (filterStore?.keywordsTopCountries) {
            if (filterStore?.keywordsTopCountries.includes(value)) {
                dispatch(setFilterKeywordTopCountries(filterStore?.keywordsTopCountries.filter((keyword: any) => keyword !== value)))
            } else {
                const updatedKeywords = [...filterStore?.keywordsTopCountries, value];
                dispatch(setFilterKeywordTopCountries(updatedKeywords))
            }
        }

    };

    const handleKeywordFilterChangeTrafficAnalysis = (value: any) => {
        if (filterStore?.keywordsTrafficAnalysis) {
            if (filterStore?.keywordsTrafficAnalysis.includes(value)) {
                dispatch(setFilterKeywordTrafficAnalysis(filterStore?.keywordsTrafficAnalysis.filter((keyword: any) => keyword !== value)))
            } else {
                const updatedKeywords = [...filterStore?.keywordsTrafficAnalysis, value];
                dispatch(setFilterKeywordTrafficAnalysis(updatedKeywords))
            }
        }
    };

    const handleKeywordFilterChangeTopCompetitors = (value: any) => {
        if (filterStore?.keywordsTopCompetitors) {
            if (filterStore?.keywordsTopCompetitors.includes(value)) {
                dispatch(setFilterKeywordTopCompetitors(filterStore?.keywordsTopCompetitors.filter((keyword: any) => keyword !== value)))
            } else {
                const updatedKeywords = [...filterStore?.keywordsTopCompetitors, value];
                dispatch(setFilterKeywordTopCompetitors(updatedKeywords))
            }
        }
    };

    const handleKeywordFilterChangeTopCompetitorsAds = (value: any) => {
        if (filterStore?.keywordsTopCompetitorsAds) {
            if (filterStore?.keywordsTopCompetitorsAds.includes(value)) {
                dispatch(setFilterKeywordTopCompetitorsAds(filterStore?.keywordsTopCompetitorsAds.filter((keyword: any) => keyword !== value)))
            } else {
                const updatedKeywords = [...filterStore?.keywordsTopCompetitorsAds, value];
                dispatch(setFilterKeywordTopCompetitorsAds(updatedKeywords))
            }
        }
    };

    const { data: adsPla, error: adsError, isFetching: isFetchingAds } = useGetAdsPlaLimitQuery({
        startDate: filterStore.start ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        countries: selectedCountriesTopCompetitorsAds,
        keywords: filterStore.keywordsTopCompetitorsAds,
        domain: workspace?.url,
        workspaceTable: workspace?.bqTable
    },
        {
            skip: skipQuery,
            refetchOnMountOrArgChange: false,

        }
    );

    const { data: data, error: dataError, isFetching: isFetchingData } = useGetStatsStandartEcommerceQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        compareStartDate: filterStore.compareStart !== undefined ? fDate(filterStore.compareStart, "yyyy-MM-dd") : null,
        compareEndDate: filterStore.compareEnd !== undefined ? fDate(filterStore.compareEnd, "yyyy-MM-dd") : null,
        timestep: filterStore.timestep,
        device: filterStore.devices,
        countries: filterStore.countries,
        keywords: filterStore.keywords,
        workspaceId: workspace?.id
    },
        {
            skip: skipQuery,
            refetchOnMountOrArgChange: false,

        });


    const { data: adsTxt, error: txtAdsError, isFetching: isFetchingTxtAds } = useGetAdsTxtLimitQuery({
        startDate: filterStore.start ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        domain: workspace?.url,
        countries: selectedCountriesTopCompetitorsAds,
        keywords: filterStore.keywordsTopCompetitorsAds,
        workspaceTable: workspace?.bqTable
    },
        {
            skip: skipQuery,
            refetchOnMountOrArgChange: false,

        });

    const [customLabels, setCustomLabels] = useState<any>(dataBusinessStatistics?.businessStatistics?.stats?.map((item: any) => item[filterStore?.timestep]) || []);

    useEffect(() => {
        if (dataBusinessStatistics && !isFetchingDataBusinessStatistics) {
            if (filterStore.timestep === 'year_week') {
                const formattedLabels = dataBusinessStatistics?.businessStatistics?.stats.map((item: any) => {
                    const [year, week] = item[filterStore.timestep].split('-');
                    return formatWeekLabel(parseInt(year), parseInt(week), currentLang.value);
                });
                setCustomLabels(formattedLabels);
            } else if (filterStore.timestep === 'year_month') {
                const formattedLabels = dataBusinessStatistics?.businessStatistics?.stats.map((item: any) => {
                    const [year, week] = item[filterStore.timestep].split('-');
                    return `${week}/${year}`;
                });
                setCustomLabels(formattedLabels);
            } else {
                const formatedLabels = dataBusinessStatistics?.businessStatistics?.stats.map((item: any) => {
                    const newDate = new Date(item[filterStore.timestep]);
                    const month = String(newDate.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth() is zero-based, so we add 1
                    const day = String(newDate.getUTCDate()).padStart(2, '0');
                    const year = newDate.getUTCFullYear();
                    if (currentLang.value === 'fr') {
                        return `${day}/${month}/${year}`;
                    } else {
                        return `${month}/${day}/${year}`;
                    }
                })
                setCustomLabels(formatedLabels);
            }
        }

    }, [currentLang.value, dataBusinessStatistics, filterStore.timestep]);

    const [customTrafficAnalysisLabels, setCustomTrafficAnalysisLabels] = useState<any>(trafficAnalysisData?.stats?.map((item: any) => item[filterStore?.timestep]) || []);

    useEffect(() => {
        if (trafficAnalysisData && !isFetchingTrafficAnalysisData) {
            if (filterStore.timestep === 'year_week') {
                const formattedLabels = trafficAnalysisData?.stats.map((item: any) => {
                    const [year, week] = item[filterStore.timestep].split('-');
                    return formatWeekLabel(parseInt(year), parseInt(week), currentLang.value);
                });
                setCustomTrafficAnalysisLabels(formattedLabels);
            }
            else if (filterStore.timestep === 'year_month') {
                const formattedLabels = trafficAnalysisData?.stats.map((item: any) => {
                    const [year, week] = item[filterStore.timestep].split('-');
                    return `${week}/${year}`;
                });
                setCustomTrafficAnalysisLabels(formattedLabels);
            } else {
                const formatedDailyDates = trafficAnalysisData?.stats.map((item: any) => {

                    const newDate = new Date(item[filterStore.timestep]);
                    const month = String(newDate.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth() is zero-based, so we add 1
                    const day = String(newDate.getUTCDate()).padStart(2, '0');
                    const year = newDate.getUTCFullYear();
                    if (currentLang.value === 'fr') {
                        return `${day}/${month}/${year}`;
                    } else {
                        return `${month}/${day}/${year}`;
                    }
                });
                setCustomTrafficAnalysisLabels(formatedDailyDates);
            }
        }
    }, [currentLang.value, trafficAnalysisData, filterStore.timestep]);


    const txtAds = adsTxt?.slice(0, 5);

    const ads = adsPla?.ads?.filter((ad: any) => ad.domain !== 'google.fr' && ad.domain !== 'google.com')?.slice(0, 8);

    const urlTransformer = (url: any) => {
        const transformUrl = (url: any) => {
            try {
                const parsedUrl = new URL(url);

                const scheme = parsedUrl.protocol.slice(0, -1);
                const hostname = parsedUrl.hostname;

                const pathParts = parsedUrl.pathname.split('/').filter(part => part);
                const languageCode = pathParts[1] || '';

                return `${scheme}://${hostname} > ${languageCode}`;
            } catch (error) {
                return "Invalid URL";
            }
        };

        return (
            transformUrl(url)
        );
    };

    useEffect(() => {
        dispatch(setFilterMinDatePicker(statsData?.minDatePicker[0]?.first_date));
    }, [statsData]);

    const BlendedCTRAdCostChart = [];

    if (workspace?.template !== "E-commerce") {
        BlendedCTRAdCostChart.push(
            { name: t('dashboard.brandTraffic.organicBrandTraffic'), data: statsData?.stats?.map((item: any) => item?.total_organic_clicks) },
            { name: t('dashboard.brandTraffic.adBrandTraffic'), data: statsData?.stats?.map((item: any) => item?.total_ad_clicks) },
            { name: t('dashboard.brandTraffic.uncapturedBrandTraffic'), data: statsData?.stats?.map((item: any) => item?.uncaptured_brand_traffic) }
        );
    } else {
        // Condition pour E-commerce
        BlendedCTRAdCostChart.push(
            { name: t('dashboard.brandTraffic.organicBrandTraffic'), data: statsData?.stats?.map((item: any) => item?.total_organic_clicks) },
            { name: t('dashboard.brandTraffic.searchPaid'), data: statsData?.stats?.map((item: any) => item?.total_ad_clicks_search) },
            { name: t('dashboard.brandTraffic.shoppingPaid'), data: statsData?.stats?.map((item: any) => item?.total_ad_clicks_shopping) }
        );

        // Vérifier si ad_clicks_pmax existe et l'ajouter avant Uncaptured Brand Traffic
        if (statsData?.stats?.some((item: any) => item?.total_ad_clicks_pmax !== undefined)) {
            BlendedCTRAdCostChart.push(
                { name: t('dashboard.brandTraffic.pmaxPaid'), data: statsData?.stats?.map((item: any) => item?.total_ad_clicks_pmax) }
            );
        }

        // Ajouter Uncaptured Brand Traffic
        BlendedCTRAdCostChart.push(
            { name: t('dashboard.brandTraffic.uncapturedBrandTraffic'), data: statsData?.stats?.map((item: any) => item?.uncaptured_brand_traffic) }
        );
    }


    const { data: countriesData, error: countriesError, isFetching: isFetchingCountries } = useGetCountriesQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        compareStartDate: filterStore.compareStart !== undefined ? fDate(filterStore.compareStart, "yyyy-MM-dd") : null,
        compareEndDate: filterStore.compareEnd !== undefined ? fDate(filterStore.compareEnd, "yyyy-MM-dd") : null,
        devices: filterStore?.devices,
        keywords: filterStore?.keywords,
        countries: filterStore?.countries,
        workspaceId: workspace?.id
    },
        {
            skip: skipQuery,
            refetchOnMountOrArgChange: false,

        });

    const dataCountries = topCountriesData?.countries?.filter((item: any) => item?.country !== null)?.slice(0, 6)?.map((item: any, index: any) => ({
        id: index,
        country: item?.country,
        brandSearches: item?.total_organic_searches.toFixed(0),
        organicBrandTraffic: item?.total_organic_clicks?.toFixed(0),
        paidBrandTraffic: item?.total_ad_clicks?.toFixed(0),
        uncapturedBrandTraffic: item?.uncaptured_brand_traffic?.toFixed(0),
        blendedCtr: (item?.blended_ctr * 100),
        paidCtr: (item?.total_ad_clicks / item?.total_organic_searches * 100),
        organicCtr: (item?.total_organic_clicks / item?.total_organic_searches * 100),
        uncapturedPercent: (item?.uncaptured_brand_traffic / item?.total_organic_searches * 100),
        adCost: item?.total_ad_cost?.toFixed(0),
        totalBrandTraffic: (item?.total_ad_clicks + item?.total_organic_clicks).toFixed(0)
    })) || [];

    const { data: presenceData, error: dataPresenceError, isFetching: isFetchingDataPresence } = useGetPresenceQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        compareStartDate: filterStore.compareStart !== undefined ? fDate(filterStore.compareStart, "yyyy-MM-dd") : null,
        compareEndDate: filterStore.compareEnd !== undefined ? fDate(filterStore.compareEnd, "yyyy-MM-dd") : null,
        timestep: filterStore.timestep,
        device: filterStore.devices,
        countries: filterStore.countries,
        keywords: filterStore.keywords,
        workspaceId: workspace?.id,
        domain: workspace?.url
    },
        {
            skip: skipQuery,
            refetchOnMountOrArgChange: false,

        });

    const dataPresence = topCompetitorsData?.presence?.slice(0, 9)?.map((item: any, index: any) => ({
        id: index,
        domain: item?.domain,
        presenceRate: item?.ratio_present_scrap * 100,
        device: item?.device,
        country: item?.country,
    })) || [];

    const dataKeywords = topKeywordsData?.keywords?.slice(0, 7)?.map((item: any, index: any) => ({
        id: index,
        keyword: item?.search_term,
        brandSearches: item?.total_organic_searches,
        organicTraffic: item?.total_organic_clicks,
        paidTraffic: item?.total_ad_clicks,
        uncapturedBrandTraffic: item?.uncaptured_brand_traffic?.toFixed(0),
        blendedCtr: (item?.blended_ctr),
        adCost: item?.total_ad_cost?.toFixed(0),
    })) || [];

    const handleChooseTrafficAnalysis = (option: string) => {
        setTrafficAnalysis((prev) => (prev !== option ? option : prev));
    };

    const handleChooseTopCompetitorsAds = (option: string) => {
        setTopCompetitorsAds((prev) => (prev !== option ? option : prev));
    };

    const handleChooseBrandBalance = (option: string) => {
        setBrandBalance((prev) => (prev !== option ? option : prev));
    };

    const BrandedTrafficDistributionChart = [];

    if (workspace?.template !== "E-commerce") {
        BrandedTrafficDistributionChart.push(
            {
                name: t('dashboard.brandTraffic.adCost'),
                type: 'bar',
                data: statsData?.stats?.map((item: any) => item?.total_ad_cost),
            },
            {
                name: t('dashboard.global.blended_ctr'),
                type: 'line',
                data: statsData?.stats?.map((item: any) => item?.blended_ctr),
            }
        );
    } else {
        BrandedTrafficDistributionChart.push(
            {
                name: t('dashboard.global.blended_ctr'),
                type: 'line',
                data: statsData?.stats?.map((item: any) => item?.blended_ctr),
            },
            {
                name: t('dashboard.brandTraffic.searchAdCost'),
                type: 'bar',
                data: statsData?.stats?.map((item: any) => item?.total_ad_cost_search),
            },
            {
                name: t('dashboard.brandTraffic.shoppingAdCost'),
                type: 'bar',
                data: statsData?.stats?.map((item: any) => item?.total_ad_cost_shopping),
            }
        );

        // Vérifier si total_ad_cost_pmax existe dans les données
        if (statsData?.stats?.some((item: any) => item?.total_ad_cost_pmax !== undefined)) {
            BrandedTrafficDistributionChart.push(
                {
                    name: t('dashboard.brandTraffic.pmaxAdCost'),
                    type: 'bar',
                    data: statsData?.stats?.map((item: any) => item?.total_ad_cost_pmax),
                }
            );
        }
    }

    const formatCurrency = (number: any, options: any) => {
        const lang = localStorage.getItem('i18nextLng');

        const currencySymbol = workspace?.currency === 'eur' ? '€' : workspace?.currency === 'usd' ? '$' : '';

        let formattedNumber;
        if (number >= 1000000) {
            formattedNumber = (number / 1000000).toFixed(1) + 'M';
        } else if (number >= 10000) {
            formattedNumber = (number / 1000).toFixed(1) + 'K';
        } else {
            formattedNumber = number.toLocaleString(lang, options);
        }

        if (lang === 'fr') {
            formattedNumber = formattedNumber.replace('.', ',');
        }

        return currencySymbol + formattedNumber;
    };

    const formatNumber = (number: any, options: any) => {
        const lang = localStorage.getItem('i18nextLng');

        let formattedNumber;
        if (number >= 1000000) {
            formattedNumber = (number / 1000000).toFixed(3) + 'M';
        } else if (number >= 10000) {
            formattedNumber = (number / 1000).toFixed(3) + 'K';
        } else {
            formattedNumber = number.toLocaleString(lang, options);
        }

        if (lang === 'fr') {
            formattedNumber = formattedNumber.replace('.', ',');
        }

        return formattedNumber;
    };

    const nodeData: any = [
        { id: t('dashboard.brandTraffic.brandSearches'), name: `${t('dashboard.brandTraffic.brandSearches')} (${dataBrandPerformanceFlow?.brandPerformanceFlow?.organic_searches && formatNumber(dataBrandPerformanceFlow?.brandPerformanceFlow?.organic_searches, { minimumFractionDigits: 1, maximumFractionDigits: 1 })})`, fill: am5.color(0xb71d18) },
        { id: t('dashboard.brandOverview.uncaptured_traffic'), name: `${t('dashboard.brandOverview.uncaptured_traffic')} (${dataBrandPerformanceFlow?.brandPerformanceFlow?.uncaptured_brand_traffic && formatNumber(dataBrandPerformanceFlow?.brandPerformanceFlow?.uncaptured_brand_traffic, { minimumFractionDigits: 1, maximumFractionDigits: 1 })})`, fill: am5.color(0xdfe3e8) },
        { id: t('dashboard.brandOverview.organic_traffic'), name: `${t('dashboard.brandOverview.organic_traffic')} (${dataBrandPerformanceFlow?.brandPerformanceFlow?.organic_clicks && formatNumber(dataBrandPerformanceFlow?.brandPerformanceFlow?.organic_clicks, { minimumFractionDigits: 1, maximumFractionDigits: 1 })})`, fill: am5.color(0x118d57) },
        { id: t('dashboard.brandOverview.ad_traffic'), name: `${t('dashboard.brandOverview.ad_traffic')} (${dataBrandPerformanceFlow?.brandPerformanceFlow?.ad_clicks && formatNumber(dataBrandPerformanceFlow?.brandPerformanceFlow?.ad_clicks, { minimumFractionDigits: 1, maximumFractionDigits: 1 })})`, fill: am5.color(0x006c9c) },
        { id: t('dashboard.brandOverview.unconverted'), name: `${t('dashboard.brandOverview.unconverted')} (${dataBrandPerformanceFlow?.brandPerformanceFlow && formatNumber((dataBrandPerformanceFlow?.brandPerformanceFlow?.ad_clicks || 0) + (dataBrandPerformanceFlow?.brandPerformanceFlow?.organic_clicks || 0) + (dataBrandPerformanceFlow?.brandPerformanceFlow?.uncaptured_brand_traffic || 0) - ((dataBrandPerformanceFlow?.brandPerformanceFlow?.paid_conversions || 0) + (dataBrandPerformanceFlow?.brandPerformanceFlow?.organic_conversions || 0)), { minimumFractionDigits: 3, maximumFractionDigits: 4 })})`, fill: am5.color(0xdfe3e8) },
        { id: t('dashboard.brandOverview.conversions'), name: `${t('dashboard.brandOverview.conversions')} (${(dataBrandPerformanceFlow?.brandPerformanceFlow?.paid_conversions || 0) + (dataBrandPerformanceFlow?.brandPerformanceFlow?.organic_conversions || 0) && formatNumber((dataBrandPerformanceFlow?.brandPerformanceFlow?.paid_conversions || 0) + (dataBrandPerformanceFlow?.brandPerformanceFlow?.organic_conversions || 0), { minimumFractionDigits: 1, maximumFractionDigits: 1 })})`, fill: am5.color(0xffab00) },
    ];


    const linkData = [
        { from: t('dashboard.brandTraffic.brandSearches'), to: t('dashboard.brandOverview.uncaptured_traffic'), value: (dataBrandPerformanceFlow?.brandPerformanceFlow?.organic_searches - dataBrandPerformanceFlow?.brandPerformanceFlow?.ad_clicks - dataBrandPerformanceFlow?.brandPerformanceFlow?.organic_clicks)?.toFixed(0), tooltipValue: (dataBrandPerformanceFlow?.brandPerformanceFlow?.organic_searches - dataBrandPerformanceFlow?.brandPerformanceFlow?.ad_clicks - dataBrandPerformanceFlow?.brandPerformanceFlow?.organic_clicks)?.toFixed(0) },
        { from: t('dashboard.brandTraffic.brandSearches'), to: t('dashboard.brandOverview.organic_traffic'), value: dataBrandPerformanceFlow?.brandPerformanceFlow?.organic_clicks?.toFixed(0), tooltipValue: dataBrandPerformanceFlow?.brandPerformanceFlow?.organic_clicks?.toFixed(0) },
        { from: t('dashboard.brandTraffic.brandSearches'), to: t('dashboard.brandOverview.ad_traffic'), value: dataBrandPerformanceFlow?.brandPerformanceFlow?.ad_clicks?.toFixed(0), tooltipValue: dataBrandPerformanceFlow?.brandPerformanceFlow?.ad_clicks?.toFixed(0) },
        { from: t('dashboard.brandOverview.organic_traffic'), to: t('dashboard.brandOverview.conversions'), value: dataBrandPerformanceFlow?.brandPerformanceFlow?.organic_conversions?.toFixed(0), tooltipValue: dataBrandPerformanceFlow?.brandPerformanceFlow?.organic_conversions?.toFixed(0) },
        { from: t('dashboard.brandOverview.uncaptured_traffic'), to: t('dashboard.brandOverview.unconverted'), value: (dataBrandPerformanceFlow?.brandPerformanceFlow?.organic_searches - dataBrandPerformanceFlow?.brandPerformanceFlow?.ad_clicks - dataBrandPerformanceFlow?.brandPerformanceFlow?.organic_clicks)?.toFixed(0), tooltipValue: (dataBrandPerformanceFlow?.brandPerformanceFlow?.organic_searches - dataBrandPerformanceFlow?.brandPerformanceFlow?.ad_clicks - dataBrandPerformanceFlow?.brandPerformanceFlow?.organic_clicks)?.toFixed(0) },
        { from: t('dashboard.brandOverview.ad_traffic'), to: t('dashboard.brandOverview.conversions'), value: dataBrandPerformanceFlow?.brandPerformanceFlow?.paid_conversions?.toFixed(0), tooltipValue: dataBrandPerformanceFlow?.brandPerformanceFlow?.paid_conversions?.toFixed(0) },
        { from: t('dashboard.brandOverview.ad_traffic'), to: t('dashboard.brandOverview.unconverted'), value: (dataBrandPerformanceFlow?.brandPerformanceFlow?.ad_clicks - dataBrandPerformanceFlow?.brandPerformanceFlow?.paid_conversions)?.toFixed(0), tooltipValue: (dataBrandPerformanceFlow?.brandPerformanceFlow?.ad_clicks - dataBrandPerformanceFlow?.brandPerformanceFlow?.paid_conversions)?.toFixed(0) },
        { from: t('dashboard.brandOverview.organic_traffic'), to: t('dashboard.brandOverview.unconverted'), value: (dataBrandPerformanceFlow?.brandPerformanceFlow?.organic_clicks - dataBrandPerformanceFlow?.brandPerformanceFlow?.organic_conversions)?.toFixed(0), tooltipValue: (dataBrandPerformanceFlow?.brandPerformanceFlow?.organic_clicks - dataBrandPerformanceFlow?.brandPerformanceFlow?.organic_conversions)?.toFixed(0) },
    ];

    const businessStatistic: any = []

    if (workspace?.template !== "E-commerce") {
        businessStatistic.push(
            { name: t('dashboard.brandOverview.blended_leads'), data: workspace?.custom === true ? dataBusinessStatistics?.businessStatistics?.blended_conversions_over_time_custom_lead?.map((item: any) => item?.total_blended_conversions || 0) : dataBusinessStatistics?.businessStatistics?.statsSupp?.map((item: any) => item?.total_blended_conversions || 0) },
            { name: t('dashboard.global.ad_cost'), data: dataBusinessStatistics?.businessStatistics?.stats?.map((item: any) => item?.total_ad_cost || 0) },
            { name: t('dashboard.global.savings'), data: dataBusinessStatistics?.businessStatistics?.savings?.map((item: any) => item?.total_actual_savings || 0) },
        )
    } else {
        businessStatistic.push(
            { name: t('dashboard.brandOverview.blended_revenue'), data: dataBusinessStatistics?.businessStatistics?.statsSupp?.map((item: any) => (workspace?.averageCart * item?.total_blended_conversions) || 0) },
            { name: t('dashboard.global.ad_cost'), data: dataBusinessStatistics?.businessStatistics?.stats?.map((item: any) => item?.total_ad_cost || 0) },
            { name: t('dashboard.global.savings'), data: dataBusinessStatistics?.businessStatistics?.savings?.map((item: any) => item?.total_actual_savings || 0) },
        )
    }

    const adCostByCountry = statsData?.ad_cost_by_country || {};

    const dataArrayAdCost = useMemo(() => {
        const adCostData = Object.keys(adCostByCountry).map((key) => ({
            label: key,
            value: adCostByCountry[key],
        }));

        adCostData.sort((a, b) => b.value - a.value);
        return adCostData;
    }, [adCostByCountry]);

    const labelsAdCostByCountry = useMemo(() => dataArrayAdCost.map(item => item.label), [dataArrayAdCost]);
    const seriesAdCostByCountry = useMemo(() => dataArrayAdCost.map(item => item.value), [dataArrayAdCost]);

    const blendedRevenueByCountry = statsData?.business_by_country || {};

    const dataArrayRevenueByCountry = useMemo(() => {
        const revenueData = Object.keys(blendedRevenueByCountry).map((key) => ({
            label: key,
            value: blendedRevenueByCountry[key] * workspace?.averageCart,
        }));

        revenueData.sort((a, b) => b.value - a.value);
        return revenueData;
    }, [blendedRevenueByCountry, workspace?.averageCart]);

    const labelsBlendedRevenueByCountry = useMemo(() => dataArrayRevenueByCountry.map(item => item.label), [dataArrayRevenueByCountry]);
    const seriesBlendedRevenueByCountry = useMemo(() => dataArrayRevenueByCountry.map(item => item.value), [dataArrayRevenueByCountry]);

    const blendedLeadByCountry = statsData?.business_by_country || {};

    const dataArrayBlendedLead = useMemo(() => {
        const leadData = Object.keys(blendedLeadByCountry).map((key) => ({
            label: key,
            value: blendedLeadByCountry[key],
        }));

        leadData.sort((a, b) => b.value - a.value);
        return leadData;
    }, [blendedLeadByCountry]);

    const labelsBlendedLeadByCountry = useMemo(() => dataArrayBlendedLead.map(item => item.label), [dataArrayBlendedLead]);
    const seriesBlendedLeadByCountry = useMemo(() => dataArrayBlendedLead.map(item => item.value), [dataArrayBlendedLead]);

    const { countries, keywords } = useMemo(() => {
        return {
            countries: countriesData?.countries
                .map((countryData: any) => countryData.country)
                .filter((country: any) => country !== null && country !== undefined),
            keywords: keywordsData?.filters?.keywords
        };
    }, [countriesData, keywordsData]);

    useEffect(() => {
        if (keywords) {
            setFilterKeywordState(keywords);
        }
    }, [keywords])

    useEffect(() => {
        if (countries) {
            setFilterCountry(countries);
        }
    }, [countries]);

    useEffect(() => {
        if (filtersTrafficAnalysis?.filters?.keywords) {
            setFilterKeywordTrafficAnalysisState(filtersTrafficAnalysis?.filters?.keywords);
        }
    }, [filtersTrafficAnalysis?.filters?.keywords])

    useEffect(() => {
        if (filtersTrafficAnalysis?.filters?.countries) {
            setFilterCountryTrafficAnalysis(filtersTrafficAnalysis?.filters?.countries.map((countryData: any) => countryData.country));
        }
    }, [filtersTrafficAnalysis?.filters?.countries]);

    useEffect(() => {
        if (filtersTopCompetitors?.filters?.keywords) {
            setFilterKeywordTopCompetitorsState(filtersTopCompetitors?.filters?.keywords);
        }
    }, [filtersTopCompetitors?.filters?.keywords])

    useEffect(() => {
        if (filtersTopCompetitors?.filters?.countries) {
            setFilterCountryTopCompetitors(filtersTopCompetitors?.filters?.countries.map((countryData: any) => countryData.country));
        }
    }, [filtersTopCompetitors?.filters?.countries]);

    useEffect(() => {
        if (filtersTopCompetitorsAds?.filters?.keywords) {
            setFilterKeywordTopCompetitorsAdsState(filtersTopCompetitorsAds?.filters?.keywords);
        }
    }, [filtersTopCompetitorsAds?.filters?.keywords])

    useEffect(() => {
        if (filtersTopCompetitorsAds?.filters?.countries) {
            setFilterCountryTopCompetitorsAds(filtersTopCompetitorsAds?.filters?.countries.map((countryData: any) => countryData.country));
        }
    }, [filtersTopCompetitorsAds?.filters?.countries]);

    const handleCountryChangeBrandPerformanceFlow = (event: any, selectedOptions: any) => {
        setSelectedCountriesBrandPerformanceFlow(selectedOptions?.filter((option: string) => option !== t('dashboard.filters.all')));
    };

    const handleCountryChangeBusinessStatistic = (event: any, selectedOptions: any) => {
        setSelectedCountriesBusinessStatistic(selectedOptions?.filter((option: string) => option !== t('dashboard.filters.all')));
    };

    const handleCountryChangeTrafficAnalysis = (event: any, selectedOptions: any) => {
        setSelectedCountriesTrafficAnalysis(selectedOptions?.filter((option: string) => option !== t('dashboard.filters.all')));
    };

    const handleCountryChangeTopCompetitors = (event: any, selectedOptions: any) => {
        setSelectedCountriesTopCompetitors(selectedOptions?.filter((option: string) => option !== t('dashboard.filters.all')));
    };

    const handleCountryChangeTopCompetitorsAds = (event: any, selectedOptions: any) => {
        setSelectedCountriesTopCompetitorsAds(selectedOptions?.filter((option: string) => option !== t('dashboard.filters.all')));
    };

    const handleCountryChangeBrandTrafficPotential = (event: any, selectedOptions: any) => {
        setSelectedCountriesBrandTrafficPotential(selectedOptions?.filter((option: string) => option !== t('dashboard.filters.all')));
    };

    const handleCountryChangeTopKeywords = (event: any, selectedOptions: any) => {
        setSelectedCountriesTopKeywords(selectedOptions?.filter((option: string) => option !== t('dashboard.filters.all')));
    };

    const dataCarousel = [
        {
            name: workspace?.template === "E-commerce"
                ? `${formatCurrency((workspace?.averageCart * data?.total_blended_conversions) || 0, { minimumFractionDigits: 1, maximumFractionDigits: 1 })} ${t('dashboard.brandOverview.of_brand_revenue')}`
                : workspace?.custom === true
                    ? `${customLead?.total_blended_conversions && formatNumber(customLead?.total_blended_conversions || 0, { minimumFractionDigits: 1, maximumFractionDigits: 1 })} ${t('dashboard.brandOverview.brand_leads')}`
                    : `${statsData?.totals?.blended_conversions && formatNumber(statsData?.totals?.blended_conversions || 0, { minimumFractionDigits: 1, maximumFractionDigits: 1 })} ${t('dashboard.brandOverview.brand_leads')}`,

            type: workspace?.template === "E-commerce" ? t('dashboard.brandOverview.Revenue') : t('dashboard.brandOverview.Leads'),
            comparison: workspace?.template === "E-commerce"
                ? fPercent(((workspace?.averageCart * (dataBusinessStatistics?.businessStatistics?.totals?.blended_conversions || 0)) - (workspace?.averageCart * (data?.total_blended_conversions_compare || 0))) / ((workspace?.averageCart * (data?.total_blended_conversions_compare || 1))) * 100)
                : fPercent(((dataBusinessStatistics?.businessStatistics?.totals?.blended_conversions || 0) - (data?.total_blended_conversions_compare || 0)) / (data?.total_blended_conversions_compare || 1) * 100) || "0%",
            path: `/dash/business-overview`,
            coverUrl: '/assets/carousel/carousel_1.jpg',
            color: '#006C9C',
            gradient: 'linear-gradient(to bottom, #003768aa, #29264aaa, #28182dcc, #1c0e17ee, #000000)'

        },
        {
            name: `${formatCurrency(data?.total_actual_savings || 0, { minimumFractionDigits: 1, maximumFractionDigits: 1 })} ${t('dashboard.brandOverview.were_saved')}`,
            type: t('dashboard.brandOverview.Savings'),
            comparison: fPercent(((data?.total_actual_savings || 0) - (data?.total_actual_savings_compare || 0)) / (data?.total_actual_savings_compare || 1) * 100) || "0%",
            path: `/dash/business-overview`,
            coverUrl: '/assets/carousel/carousel_2.jpg',
            color: '#22C55E',
            gradient: 'linear-gradient(to bottom, #065e4999, #334322aa, #342a16cc, #221612ee, #000000)'

        },
        {
            name: `${t('dashboard.brandOverview.your_brand_ad_cost_is')} ${formatCurrency(statsData?.totals?.ad_cost || 0, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}`,
            type: t('dashboard.brandOverview.Ad cost'),
            comparison: fPercent(((statsData?.totals?.ad_cost || 0) - (statsData?.compare_totals?.ad_cost || 0)) / (statsData?.compare_totals?.ad_cost || 1) * 100),
            path: `/dash/traffic-analysis`,
            coverUrl: '/assets/carousel/carousel_3.jpg',
            color: '#FFAB00',
            gradient: 'linear-gradient(to bottom, #b76e0099, #96481faa, #692c24cc, #37191cee, #000000)'
        },
        {
            name: `${t('dashboard.brandOverview.your_blended_ctr_is')} ${fPercent(statsData?.totals?.blended_ctr)}`,
            type: t('dashboard.brandOverview.Traffic'),
            comparison: fPercent(((statsData?.totals?.blended_ctr || 0) - (statsData?.compare_totals?.blended_ctr || 0)) / (statsData?.compare_totals?.blended_ctr || 1) * 100),
            path: `/dash/traffic-analysis`,
            coverUrl: '/assets/carousel/carousel_4.jpg',
            color: '#7635DC',
            gradient: 'linear-gradient(to bottom, #200a6999, #330044aa, #2e0027cc, #200013ee, #000000)'
        },
    ]

    useEffect(() => {
        const allFetchingStatuses = [
            !isFetching,
        ];

        const areAllRequestsCompleted = allFetchingStatuses.every((fetchStatus) => fetchStatus === true);

        if (areAllRequestsCompleted) {
            setAllDataFetched(true);
        } else {
            setAllDataFetched(false);
        }

    }, [
        isFetching,
    ]);

    return (
        <>
            <Container maxWidth="xl" sx={{ marginTop: 1 }}>
                <Grid container spacing={2} >
                    <Grid item xs={12} md={8}>
                        {!disableWelcome?.value &&
                            <Grid container spacing={2} sx={{ marginBottom: 1, borderRadius: 2 }}>
                                <Grid item xs={12}>

                                    <Welcome
                                        onClose={() => disableWelcome.onTrue()}
                                        title={t('dashboard.brandOverview.welcome_title')}
                                        description={t('dashboard.brandOverview.welcome_description')}
                                        img={lgUp ? <MotivationIllustration /> : <></>}
                                    />

                                </Grid>
                            </Grid>
                        }
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper
                            sx={{
                                backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                                height: '365px',
                                borderRadius: 2,
                            }}
                        >
                            {!allDataFetched ? (
                                <Skeleton width={'100%'} height={'100%'} style={{ borderRadius: 10 }} variant="rectangular" animation={"wave"}>
                                    <>
                                        <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                        <Typography color={"success.main"}>Active</Typography>
                                    </>
                                </Skeleton>
                            ) : (

                                <CarouselBrandOverview data={dataCarousel} />

                            )}
                        </Paper>
                    </Grid>
                </Grid>
                {/* SANKEY BOX */}
                <Grid container spacing={2}  >
                    <Grid item xs={12} md={12} lg={12}>
                        <Paper
                            sx={{
                                backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                                height: { xs: '100%', md: '550px' },
                                borderRadius: 2,
                                marginTop: 2,
                                marginBottom: 1
                            }}
                        >

                            <div style={{ width: '100%' }}>
                                {/* title + filter countries */}
                                <Box sx={{ padding: '20px 25px', display: 'flex', justifyContent: 'space-between' }}>
                                    <Box>
                                        <Typography variant="overline" color="textSecondary" sx={{ mb: 1 }}>Brand Flow</Typography>

                                        <Typography variant="h6" sx={{ mb: 1 }}>
                                            {t('dashboard.brandOverview.brand_performance_flow')}
                                        </Typography>
                                    </Box>

                                    {filterCountry?.length > 1 &&
                                        <Box>
                                            <Autocomplete
                                                multiple
                                                size="small"
                                                limitTags={3}
                                                disableCloseOnSelect
                                                options={filterCountry || []}
                                                getOptionLabel={(option) => option}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={t('dashboard.filters.countries')}
                                                        sx={{ minWidth: minWidth, maxWidth: 315 }}
                                                    />
                                                )}
                                                value={
                                                    selectedCountriesBrandPerformanceFlow?.length === 0
                                                        ? [t('dashboard.filters.all')]
                                                        : selectedCountriesBrandPerformanceFlow
                                                }
                                                renderOption={(props, option) => {
                                                    const country = countriesJson?.find((c) => c.name === option);
                                                    const flagIcon = country ? `circle-flags:${country.code}` : null;

                                                    return (
                                                        <li {...props} style={{ whiteSpace: 'nowrap' }} key={option}>
                                                            {option !== t('dashboard.filters.all') && (
                                                                <>
                                                                    <Checkbox
                                                                        checked={selectedCountriesBrandPerformanceFlow.includes(option)}
                                                                    />
                                                                    {
                                                                        flagIcon ? (
                                                                            <Iconify icon={flagIcon} width={16} sx={{ mr: 1 }} />
                                                                        ) : (
                                                                            <img style={{ width: 14, height: 14, marginRight: 6 }} src="/assets/location.png" />
                                                                        )
                                                                    }
                                                                </>


                                                            )}
                                                            {option}
                                                        </li>
                                                    );
                                                }}
                                                onChange={handleCountryChangeBrandPerformanceFlow}
                                                renderTags={(selected, getTagProps) =>
                                                    selected.map((option, index) => {
                                                        const country = countriesJson?.find((c) => c.name === option);
                                                        const flagCode = country ? country.code : null;

                                                        return (
                                                            <Chip
                                                                {...getTagProps({ index })}
                                                                key={option}
                                                                icon={
                                                                    option !== t('dashboard.filters.all') ? (
                                                                        flagCode ? (
                                                                            <FlagIcon
                                                                                key={flagCode}
                                                                                code={flagCode}
                                                                                sx={{ width: 16, height: 16, borderRadius: '50%' }}
                                                                            />
                                                                        ) : (
                                                                            <img style={{ width: 14, height: 14, marginRight: 1 }} src="/assets/location.png" />
                                                                        )
                                                                    ) : undefined
                                                                }
                                                                label={option}
                                                                size="small"
                                                                deleteIcon={option === t('dashboard.filters.all') ? <></> : undefined}
                                                                variant="soft"
                                                            />
                                                        );
                                                    })
                                                }
                                                isOptionEqualToValue={(option, value) => option === value}
                                            />
                                        </Box>
                                    }
                                </Box>

                                <Box sx={{ height: { xs: '100%', md: 450 } }}>
                                    {!allDataFetched || isFetchingDataBrandPerformanceFlow ? (
                                        <Skeleton width={'100%'} height={'100%'} style={{
                                            borderBottomLeftRadius: '15px',
                                            borderBottomRightRadius: '15px'
                                        }} variant="rectangular" animation={"wave"}>
                                            <>
                                                <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                                <Typography color={"success.main"}>Active</Typography>
                                            </>
                                        </Skeleton>
                                    ) : (
                                        <Box>
                                            <Grid container spacing={1} sx={{ display: { xs: 'block', sm: 'flex' }, flexDirection: { xs: 'column', sm: 'row' } }} justifyContent={'sapce-between'}>
                                                <Grid item xs={12} md={6} lg={6}>
                                                    <Box sx={{ padding: '20px 25px' }}>
                                                        <Box sx={{ mb: { xs: 2, sm: 5, md: 10 }, fontWeight: 900, display: { xs: 'block', sm: 'flex' }, flexDirection: { xs: 'column', sm: 'row', lg: 'column', xl: 'column', whiteSpace: lgUp ? 'nowrap' : 'normal' } }}>
                                                            <Typography color={'#7a31e5'} fontSize={70} fontWeight={700}>
                                                                {fPercent((dataBrandPerformanceFlow?.brandPerformanceFlow?.organic_clicks + dataBrandPerformanceFlow?.brandPerformanceFlow?.ad_clicks) / dataBrandPerformanceFlow?.brandPerformanceFlow?.organic_searches * 100)}
                                                            </Typography>
                                                            {
                                                                currentLang.value === "fr" ? (
                                                                    <Typography fontSize={20}>
                                                                        {t('dashboard.brandOverview.of')} <span style={{ color: "white", backgroundColor: "#8f2320", padding: 5, borderRadius: 4, fontWeight: 700 }}>{t('dashboard.brandOverview.brandTraffic')}</span> {t('dashboard.brandOverview.of_potential_brand_traffic_is_captured_by')} {t('dashboard.brandOverview.channels')} <span style={{ color: "white", backgroundColor: "#138a5f", padding: 5, borderRadius: 4, fontWeight: 700 }}> {t('dashboard.brandOverview.organic')}</span>  {t('dashboard.brandOverview.and')} <span style={{ color: "white", backgroundColor: "#0e6d89", padding: 5, borderRadius: 4, fontWeight: 700 }}> {t('dashboard.brandOverview.paid')}</span>
                                                                    </Typography>
                                                                ) : (
                                                                    <Typography fontSize={20}>
                                                                        {t('dashboard.brandOverview.of')} <span style={{ color: "white", backgroundColor: "#8f2320", padding: 5, borderRadius: 4, fontWeight: 700 }}>{t('dashboard.brandOverview.brandTraffic')}</span> {t('dashboard.brandOverview.of_potential_brand_traffic_is_captured_by')} <span style={{ color: "white", backgroundColor: "#138a5f", padding: 5, borderRadius: 4, fontWeight: 700 }}> {t('dashboard.brandOverview.organic')}</span>  {t('dashboard.brandOverview.and')} <span style={{ color: "white", backgroundColor: "#0e6d89", padding: 5, borderRadius: 4, fontWeight: 700 }}> {t('dashboard.brandOverview.paid')}</span> {t('dashboard.brandOverview.channels')}
                                                                    </Typography>
                                                                )
                                                            }

                                                        </Box>

                                                        <div style={{ borderLeft: `4px solid ${theme.palette[ColorSchema.WARNING].light}`, paddingLeft: '10px' }}>
                                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                <Iconify style={{ position: 'relative' }} width={23} icon={'noto:light-bulb'} />
                                                                <Typography style={{ fontWeight: 'bold' }}> {t('dashboard.brandTraffic.performanceInsight')}</Typography>
                                                            </div>

                                                            <Box sx={{ mt: 2, display: 'flex', flexDirection: 'row' }}>
                                                                <Typography>
                                                                    {t('dashboard.brandOverview.you_total')}
                                                                    <span style={{ color: '#f4b433', fontWeight: 700 }}>
                                                                        {' '}{(dataBrandPerformanceFlow?.brandPerformanceFlow?.paid_conversions || 0) + (dataBrandPerformanceFlow?.brandPerformanceFlow?.organic_conversions || 0) && formatNumber((dataBrandPerformanceFlow?.brandPerformanceFlow?.paid_conversions || 0) + (dataBrandPerformanceFlow?.brandPerformanceFlow?.organic_conversions || 0), { minimumFractionDigits: 1, maximumFractionDigits: 1 })}{' '}
                                                                    </span>
                                                                    {t('dashboard.brandOverview.cumulative_brand_conv_representing').split('conversions')[0]}
                                                                    <span style={{ color: 'black', backgroundColor: '#f3b333', paddingLeft: 5, paddingRight: 5, paddingTop: 3, paddingBottom: 3, borderRadius: 4 }}>conversions</span>
                                                                    {t('dashboard.brandOverview.cumulative_brand_conv_representing').split('conversions')[1]} {" "}
                                                                    <span style={{ color: '#f4b433', fontWeight: 700 }}>
                                                                        {(dataBrandPerformanceFlow?.brandPerformanceFlow?.paid_conversions || 0) + (dataBrandPerformanceFlow?.brandPerformanceFlow?.organic_conversions || 0) && (dataBrandPerformanceFlow?.brandPerformanceFlow?.organic_clicks + dataBrandPerformanceFlow?.brandPerformanceFlow?.ad_clicks)
                                                                            ? (((dataBrandPerformanceFlow?.brandPerformanceFlow?.paid_conversions || 0) + (dataBrandPerformanceFlow?.brandPerformanceFlow?.organic_conversions || 0)) / (dataBrandPerformanceFlow?.brandPerformanceFlow?.organic_clicks + dataBrandPerformanceFlow?.brandPerformanceFlow?.ad_clicks) * 100).toFixed(2) + '%'
                                                                            : '0%'}
                                                                    </span> {t('dashboard.brandOverview.of_captured_brand_traffic')}
                                                                    {filterStore?.compare === true && (
                                                                        <span>
                                                                            , {parseFloat(((((dataBrandPerformanceFlow?.brandPerformanceFlow?.paid_conversions || 0) + (dataBrandPerformanceFlow?.brandPerformanceFlow?.organic_conversions || 0)) - ((data?.total_paid_conversions_compare || 0) + (data?.total_organic_conversions_compare || 0))) / ((data?.total_paid_conversions_compare || 0) + (data?.total_organic_conversions_compare || 0)) * 100).toFixed(2)) > 0
                                                                                ? t('dashboard.brandOverview.up')
                                                                                : t('dashboard.brandOverview.down')
                                                                            }{' '}
                                                                            <span style={{ color: '#f4b433', fontWeight: 700 }}>
                                                                                {"  " + Math.abs(parseFloat(((((dataBrandPerformanceFlow?.brandPerformanceFlow?.paid_conversions || 0) + (dataBrandPerformanceFlow?.brandPerformanceFlow?.organic_conversions || 0)) - ((data?.total_paid_conversions_compare || 0) + (data?.total_organic_conversions_compare || 0))) / ((data?.total_paid_conversions_compare || 0) + (data?.total_organic_conversions_compare || 0)) * 100).toFixed(2)))}{'% '}
                                                                            </span>
                                                                            {t('dashboard.brandOverview.compared_to_previous_period')}
                                                                        </span>
                                                                    )}
                                                                </Typography>
                                                            </Box>

                                                        </div>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} md={6} lg={6}>
                                                    <Box sx={{
                                                        height: '100%',
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        ml: 2,

                                                    }}>
                                                        <SankeyChart nodeData={nodeData} linkData={linkData} />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    )}
                                </Box>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>

                {filterCountry?.length > 1 ?
                    // Busineess Performance 
                    <Grid container spacing={2}  >
                        {/* Brand Balance */}
                        <Grid item xs={12} md={6} sx={{ marginTop: { xs: 3, md: 0 } }} >
                            <Paper
                                sx={{
                                    backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                    boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                                    height: '760px',
                                    borderRadius: 2,
                                    marginTop: 2,
                                    marginBottom: 1
                                }}
                            >
                                <div style={{ width: '100%' }}>
                                    <Box sx={{ padding: '20px 25px' }}>
                                        <Typography variant="overline" color="textSecondary" sx={{ mb: 1 }}>{t('dashboard.brandOverview.business_performance')}</Typography>

                                        <Typography variant="h6" sx={{ mb: 1 }}>
                                            Brand Balance
                                        </Typography>
                                    </Box>

                                    <Box sx={{ height: 590 }}>
                                        {!allDataFetched ? (
                                            <Skeleton width={'100%'} height={'100%'} variant="rectangular" animation={"wave"}>
                                                <>
                                                    <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                                    <Typography color={"success.main"}>Active</Typography>
                                                </>
                                            </Skeleton>
                                        ) : (
                                            <Box sx={{ height: '100%' }} >
                                                <Box sx={{ px: 3 }}>

                                                    <Box sx={{ mb: 3, backgroundColor: theme.palette.mode === "dark" ? '#28323c' : '#f4f6f8', borderRadius: 2, p: 2 }}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={6}>
                                                                <Box sx={{ cursor: 'pointer', backgroundColor: brandBalance === "Blended Revenue" ? theme.palette.mode === "dark" ? '#1a2027' : 'white' : 'transparent', borderRadius: 2, p: 2 }} onClick={() => handleChooseBrandBalance("Blended Revenue")}>
                                                                    <Box sx={{ display: { sm: 'flex' }, alignItems: { md: 'center' }, mb: 1 }}>
                                                                        {lgUp &&
                                                                            <Box sx={{
                                                                                width: 44,
                                                                                height: 44,
                                                                                borderRadius: '50%',
                                                                                backgroundColor: '#0666aa',
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'center',
                                                                                mr: 2,
                                                                                color: '#ffffff'
                                                                            }}>
                                                                                <Iconify
                                                                                    width={24}
                                                                                    icon={"octicon:arrow-up-16"}
                                                                                    sx={{ transform: 'rotate(220deg)' }}
                                                                                />
                                                                            </Box>
                                                                        }
                                                                        <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                                                                            <Typography sx={{ fontWeight: 'bold', fontSize: 14, color: '#919eab', textAlign: 'center' }}>
                                                                                {workspace?.template !== "E-commerce"
                                                                                    ? t('dashboard.brandOverview.blended_leads')
                                                                                    : t('dashboard.brandOverview.blended_revenue')
                                                                                }
                                                                            </Typography>
                                                                            <Typography sx={{ fontWeight: 'bold', fontSize: 24, color: theme.palette.mode === "dark" ? '#ffffff' : 'black', textAlign: 'center' }}>
                                                                                {workspace?.template !== "E-commerce"
                                                                                    ? workspace?.custom === true ? customLead?.total_blended_conversions && formatNumber(customLead?.total_blended_conversions, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) : data?.total_blended_conversions && formatNumber(data?.total_blended_conversions, { minimumFractionDigits: 1, maximumFractionDigits: 1 })
                                                                                    : formatCurrency(workspace?.averageCart * data?.total_blended_conversions, { minimumFractionDigits: 1, maximumFractionDigits: 1 })
                                                                                }
                                                                            </Typography>
                                                                        </Box>

                                                                        {filterStore?.compare === true && (
                                                                            <Box
                                                                                sx={{
                                                                                    backgroundColor: ((workspace?.averageCart * (data?.total_blended_conversions ?? 0)) - (workspace?.averageCart * (data?.total_blended_conversions_compare ?? 0))) / (workspace?.averageCart * (data?.total_blended_conversions_compare ?? 1)) * 100 >= 0
                                                                                        ? theme.palette.mode === "dark" ? '#1d2f26' : '#dbf6e5'
                                                                                        : '#412d2e',
                                                                                    color: ((workspace?.averageCart * (data?.total_blended_conversions ?? 0)) - (workspace?.averageCart * (data?.total_blended_conversions_compare ?? 0))) / (workspace?.averageCart * (data?.total_blended_conversions_compare ?? 1)) * 100 >= 0
                                                                                        ? theme.palette.mode === "dark" ? '#00e676' : '#118d57'
                                                                                        : '#faa87f',
                                                                                    fontWeight: 'bold',
                                                                                    ml: 'auto',
                                                                                    p: '2px 8px',
                                                                                    fontSize: 14,
                                                                                    borderRadius: 1
                                                                                }}
                                                                            >
                                                                                {((workspace?.averageCart * (data?.total_blended_conversions ?? 0)) - (workspace?.averageCart * (data?.total_blended_conversions_compare ?? 0))) / (workspace?.averageCart * (data?.total_blended_conversions_compare ?? 1)) * 100 >= 0
                                                                                    ? `+${fPercent((((workspace?.averageCart * (data?.total_blended_conversions ?? 0)) - (workspace?.averageCart * (data?.total_blended_conversions_compare ?? 0))) / (workspace?.averageCart * (data?.total_blended_conversions_compare ?? 1))) * 100)}`
                                                                                    : `${fPercent((((workspace?.averageCart * (data?.total_blended_conversions ?? 0)) - (workspace?.averageCart * (data?.total_blended_conversions_compare ?? 0))) / (workspace?.averageCart * (data?.total_blended_conversions_compare ?? 1))) * 100)}`}
                                                                            </Box>
                                                                        )}

                                                                    </Box>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Box sx={{ cursor: 'pointer', backgroundColor: brandBalance === "Ad Cost" ? theme.palette.mode === "dark" ? '#1a2027' : 'white' : 'transparent', borderRadius: 2, p: 2 }} onClick={() => handleChooseBrandBalance("Ad Cost")} >
                                                                    <Box sx={{ display: { sm: 'flex' }, alignItems: { md: 'center' }, mb: 1 }}>
                                                                        {lgUp &&
                                                                            <Box sx={{
                                                                                width: 44,
                                                                                height: 44,
                                                                                borderRadius: '50%',
                                                                                backgroundColor: '#fead00',
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'center',
                                                                                mr: 2,
                                                                                color: '#ffffff'
                                                                            }}>
                                                                                <Iconify
                                                                                    width={24}
                                                                                    icon={"octicon:arrow-up-16"}
                                                                                    sx={{ transform: 'rotate(40deg)' }}
                                                                                />
                                                                            </Box>
                                                                        }
                                                                        <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                                                                            <Typography sx={{ fontWeight: 'bold', fontSize: 14, color: '#919eab', textAlign: 'center' }}>
                                                                                {t('dashboard.global.ad_cost')}
                                                                            </Typography>

                                                                            {statsData?.totals?.ad_cost &&
                                                                                <Typography sx={{ fontWeight: 'bold', fontSize: 24, color: theme.palette.mode === "dark" ? '#ffffff' : 'black', textAlign: 'center' }}>
                                                                                    {formatCurrency(statsData?.totals?.ad_cost, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}
                                                                                </Typography>
                                                                            }
                                                                        </Box>

                                                                        {filterStore?.compare === true && (
                                                                            <Box
                                                                                sx={{
                                                                                    backgroundColor: ((statsData?.totals?.ad_cost ?? 0) - (statsData?.compare_totals?.ad_cost ?? 0)) / (statsData?.compare_totals?.ad_cost ?? 1) * 100 >= 0 ? theme.palette.mode === "dark" ? '#1d2f26' : '#dbf6e5' : '#412d2e',
                                                                                    color: ((statsData?.totals?.ad_cost ?? 0) - (statsData?.compare_totals?.ad_cost ?? 0)) / (statsData?.compare_totals?.ad_cost ?? 1) * 100 >= 0 ? theme.palette.mode === "dark" ? '#00e676' : '#118d57' : '#faa87f',
                                                                                    fontWeight: 'bold',
                                                                                    ml: 'auto',
                                                                                    p: '2px 8px',
                                                                                    fontSize: 14,
                                                                                    borderRadius: 1
                                                                                }}
                                                                            >
                                                                                {((statsData?.totals?.ad_cost ?? 0) - (statsData?.compare_totals?.ad_cost ?? 0)) / (statsData?.compare_totals?.ad_cost ?? 1) * 100 >= 0
                                                                                    ? `+${fPercent((((statsData?.totals?.ad_cost ?? 0) - (statsData?.compare_totals?.ad_cost ?? 0)) / (statsData?.compare_totals?.ad_cost ?? 1)) * 100)}`
                                                                                    : `${fPercent((((statsData?.totals?.ad_cost ?? 0) - (statsData?.compare_totals?.ad_cost ?? 0)) / (statsData?.compare_totals?.ad_cost ?? 1)) * 100)}`}
                                                                            </Box>
                                                                        )}
                                                                    </Box>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Box>
                                                <Box sx={{ height: 'auto' }} >
                                                    {brandBalance === "Blended Revenue"
                                                        ? <>
                                                            {workspace?.template === "Lead"
                                                                ? <ChartPolarAreaLead series={seriesBlendedLeadByCountry} labels={labelsBlendedLeadByCountry} />
                                                                : <ChartPolarArea series={seriesBlendedRevenueByCountry} labels={labelsBlendedRevenueByCountry} />
                                                            }
                                                        </>
                                                        : <ChartPolarArea series={seriesAdCostByCountry} labels={labelsAdCostByCountry} />
                                                    }
                                                </Box>
                                            </Box>
                                        )}


                                    </Box>

                                    <Box
                                        sx={{
                                            borderBottom: '1px #5e6d7b dotted',
                                            width: '100%',
                                        }}
                                    />

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            width: '100%',
                                            height: 70
                                        }}
                                    >
                                        <Link
                                            component="button"
                                            onClick={() => router.push(`/dash/business-overview?workspace=${workspace?.id}`)}
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: 900,
                                                px: 3,
                                                position: 'relative',
                                                textDecoration: 'none',
                                                color: 'inherit',
                                                '&:hover': {
                                                    textDecoration: 'underline',
                                                    cursor: 'pointer',
                                                },
                                            }}
                                        >
                                            <Typography fontSize={14} fontWeight={900}>
                                                {t('dashboard.brandOverview.business_overview_dashboard')} {'>'}
                                            </Typography>
                                        </Link>
                                    </Box>

                                </div>
                            </Paper>
                        </Grid>
                        {/* Business Statistics */}
                        <Grid item xs={12} md={6} >
                            <Paper
                                sx={{
                                    backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                    boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                                    height: { xs: '100%', md: '760px' },
                                    borderRadius: 2,
                                    marginTop: 2,
                                    marginBottom: 1,
                                }}
                            >
                                <div style={{ width: '100%' }}>
                                    <Box sx={{ padding: '20px 25px', display: 'flex', justifyContent: 'space-between' }}>
                                        <Box>
                                            <Typography variant="overline" color="textSecondary" sx={{ mb: 1 }}>{t('dashboard.brandOverview.business_performance')}</Typography>

                                            <Typography variant="h6" sx={{ mb: 1 }}>
                                                {t('dashboard.brandOverview.business_statistics')}

                                            </Typography>
                                        </Box>
                                        {filterCountry?.length > 1 &&
                                            <Box sx={{ mt: 1 }}>
                                                <Autocomplete
                                                    multiple
                                                    size="small"
                                                    limitTags={3}
                                                    disableCloseOnSelect
                                                    options={filterCountry || []}
                                                    getOptionLabel={(option) => option}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={t('dashboard.filters.countries')}
                                                            sx={{ minWidth: minWidth, maxWidth: 315 }}
                                                        />
                                                    )}
                                                    value={
                                                        selectedCountriesBusinessStatistic?.length === 0
                                                            ? [t('dashboard.filters.all')]
                                                            : selectedCountriesBusinessStatistic
                                                    }
                                                    renderOption={(props, option) => {
                                                        const country = countriesJson?.find((c) => c.name === option);
                                                        const flagIcon = country ? `circle-flags:${country.code}` : null;

                                                        return (
                                                            <li {...props} style={{ whiteSpace: 'nowrap' }} key={option}>
                                                                {option !== t('dashboard.filters.all') && (
                                                                    <>
                                                                        <Checkbox
                                                                            checked={selectedCountriesBusinessStatistic.includes(option)}
                                                                        />
                                                                        {
                                                                            flagIcon ? (
                                                                                <Iconify icon={flagIcon} width={16} sx={{ mr: 1 }} />
                                                                            ) : (
                                                                                <img style={{ width: 14, height: 14, marginRight: 6 }} src="/assets/location.png" />
                                                                            )
                                                                        }
                                                                    </>


                                                                )}
                                                                {option}
                                                            </li>
                                                        );
                                                    }}
                                                    onChange={handleCountryChangeBusinessStatistic}
                                                    renderTags={(selected, getTagProps) =>
                                                        selected.map((option, index) => {
                                                            const country = countriesJson?.find((c) => c.name === option);
                                                            const flagCode = country ? country.code : null;

                                                            return (
                                                                <Chip
                                                                    {...getTagProps({ index })}
                                                                    key={option}
                                                                    icon={
                                                                        option !== t('dashboard.filters.all') ? (
                                                                            flagCode ? (
                                                                                <FlagIcon
                                                                                    key={flagCode}
                                                                                    code={flagCode}
                                                                                    sx={{ width: 16, height: 16, borderRadius: '50%' }}
                                                                                />
                                                                            ) : (
                                                                                <img style={{ width: 14, height: 14, marginRight: 1 }} src="/assets/location.png" />
                                                                            )
                                                                        ) : undefined
                                                                    }
                                                                    label={option}
                                                                    size="small"
                                                                    deleteIcon={option === t('dashboard.filters.all') ? <></> : undefined}
                                                                    variant="soft"
                                                                />
                                                            );
                                                        })
                                                    }
                                                    isOptionEqualToValue={(option, value) => option === value}
                                                />
                                            </Box>
                                        }
                                    </Box>
                                    <Box sx={{ height: 590 }}>
                                        {!allDataFetched || isFetchingDataBusinessStatistics ? (
                                            <Skeleton width={'100%'} height={'100%'} variant="rectangular" animation={"wave"}>
                                                <>
                                                    <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                                    <Typography color={"success.main"}>Active</Typography>
                                                </>
                                            </Skeleton>
                                        ) : (
                                            <Box >
                                                <Box sx={{ mb: 3, backgroundColor: theme.palette.mode === "dark" ? '#28323c' : '#f4f6f8', py: 3 }}>
                                                    <Grid container justifyContent="center" spacing={2} sx={{ px: 5 }}>
                                                        <Grid item xs={12} sm={4} md={4}>
                                                            {workspace?.template !== "E-commerce"
                                                                ? <Box sx={{ width: '100%', backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white', p: 2, borderRadius: 2 }}>
                                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Typography fontSize={13} variant="body1" sx={{ fontWeight: 'bold', whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row' }}>
                                                                            <span style={{ width: 14, height: 14, borderRadius: '50%', backgroundColor: '#0666aa', marginRight: 5, position: 'relative', top: 1 }} />
                                                                            {t('dashboard.brandOverview.blended_leads')}
                                                                            {filterStore?.compare === true && (
                                                                                <span style={{ marginLeft: 4 }}>
                                                                                    {((dataBusinessStatistics?.businessStatistics?.totals?.blended_conversions || 0 - data?.total_blended_conversions_compare || 0) / (data?.total_blended_conversions_compare || 1)) * 100 >= 0
                                                                                        ? `(+${fPercent(((dataBusinessStatistics?.businessStatistics?.totals?.blended_conversions || 0 - data?.total_blended_conversions_compare || 0) / (data?.total_blended_conversions_compare || 1)) * 100)})`
                                                                                        : `(${fPercent(((dataBusinessStatistics?.businessStatistics?.totals?.blended_conversions || 0 - data?.total_blended_conversions_compare || 0) / (data?.total_blended_conversions_compare || 1)) * 100)})`}
                                                                                </span>
                                                                            )}
                                                                        </Typography>
                                                                    </Box>
                                                                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                                                                        {formatNumber(dataBusinessStatistics?.businessStatistics?.totals?.blended_conversions, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}
                                                                    </Typography>
                                                                </Box>
                                                                : <Box sx={{ width: '100%', backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white', p: 2, borderRadius: 2 }}>
                                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Typography fontSize={13} variant="body1" sx={{ fontWeight: 'bold', whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row' }}>
                                                                            <span style={{ width: 14, height: 14, borderRadius: '50%', backgroundColor: '#0666aa', marginRight: 5, position: 'relative', top: 1 }} />
                                                                            {t('dashboard.brandOverview.blended_revenue')}
                                                                            {filterStore?.compare === true && (
                                                                                <span style={{ marginLeft: 4 }}>
                                                                                    {((workspace?.averageCart * (dataBusinessStatistics?.businessStatistics?.totals?.blended_conversions ?? 0) - workspace?.averageCart * (data?.total_blended_conversions_compare ?? 0))
                                                                                        / (workspace?.averageCart * (data?.total_blended_conversions_compare ?? 1))) * 100 >= 0
                                                                                        ? `(+${fPercent(((workspace?.averageCart * (dataBusinessStatistics?.businessStatistics?.totals?.blended_conversions ?? 0) - workspace?.averageCart * (data?.total_blended_conversions_compare ?? 0))
                                                                                            / (workspace?.averageCart * (data?.total_blended_conversions_compare ?? 1))) * 100)})`
                                                                                        : `(${fPercent(((workspace?.averageCart * (dataBusinessStatistics?.businessStatistics?.totals?.blended_conversions ?? 0) - workspace?.averageCart * (data?.total_blended_conversions_compare ?? 0))
                                                                                            / (workspace?.averageCart * (data?.total_blended_conversions_compare ?? 1))) * 100)})`}
                                                                                </span>
                                                                            )}
                                                                        </Typography>

                                                                    </Box>
                                                                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                                                                        {formatCurrency(workspace?.averageCart * dataBusinessStatistics?.businessStatistics?.totals?.blended_conversions, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}
                                                                    </Typography>
                                                                </Box>
                                                            }
                                                        </Grid>

                                                        <Grid item xs={12} sm={4} md={4}>
                                                            <Box sx={{ width: '100%', backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white', p: 2, borderRadius: 2 }}>
                                                                <Box>
                                                                    <Typography fontSize={13} variant="body1" sx={{ fontWeight: 'bold', whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row' }}>
                                                                        <span style={{ width: 14, height: 14, borderRadius: '50%', backgroundColor: '#fdab0f', marginRight: 5, position: 'relative', top: 1 }} />
                                                                        {t('dashboard.global.ad_cost')}
                                                                        {filterStore?.compare === true && (
                                                                            <span style={{ marginLeft: 4 }}>
                                                                                {((dataBusinessStatistics?.businessStatistics?.totals?.ad_cost ?? 0) - (dataBusinessStatistics?.businessStatistics?.totals?.ad_cost_compare ?? 0)) / (dataBusinessStatistics?.businessStatistics?.totals?.ad_cost_compare ?? 1) * 100 >= 0
                                                                                    ? `(+${fPercent((((dataBusinessStatistics?.businessStatistics?.totals?.ad_cost ?? 0) - (dataBusinessStatistics?.businessStatistics?.totals?.ad_cost_compare ?? 0)) / (dataBusinessStatistics?.businessStatistics?.totals?.ad_cost_compare ?? 1)) * 100)})`
                                                                                    : `(${fPercent((((dataBusinessStatistics?.businessStatistics?.totals?.ad_cost ?? 0) - (dataBusinessStatistics?.businessStatistics?.totals?.ad_cost_compare ?? 0)) / (dataBusinessStatistics?.businessStatistics?.totals?.ad_cost_compare ?? 1)) * 100)})`}
                                                                            </span>
                                                                        )}
                                                                    </Typography>

                                                                    {dataBusinessStatistics?.businessStatistics?.totals?.ad_cost &&
                                                                        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                                                                            {formatCurrency(dataBusinessStatistics?.businessStatistics?.totals?.ad_cost, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}
                                                                        </Typography>
                                                                    }

                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4} md={4}>
                                                            <Box sx={{ width: '100%', backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white', p: 2, borderRadius: 2 }}>
                                                                <Box>
                                                                    <Typography fontSize={13} variant="body1" sx={{ fontWeight: 'bold', whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row' }}>
                                                                        <span style={{ width: 14, height: 14, borderRadius: '50%', backgroundColor: '#0b9255', marginRight: 5, position: 'relative', top: 1 }} />
                                                                        {t('dashboard.global.savings')}
                                                                        {filterStore?.compare === true && (
                                                                            <span style={{ marginLeft: 4 }}>
                                                                                {((dataBusinessStatistics?.businessStatistics?.totals?.savings ?? 0) - (dataBusinessStatistics?.businessStatistics?.totals?.savingsCompare ?? 0)) / (dataBusinessStatistics?.businessStatistics?.totals?.savingsCompare ?? 1) * 100 >= 0
                                                                                    ? `(+${fPercent((((dataBusinessStatistics?.businessStatistics?.totals?.savings ?? 0) - (dataBusinessStatistics?.businessStatistics?.totals?.savingsCompare ?? 0)) / (dataBusinessStatistics?.businessStatistics?.totals?.savingsCompare ?? 1)) * 100) || '0%'} )`
                                                                                    : `(${fPercent((((dataBusinessStatistics?.businessStatistics?.totals?.savings ?? 0) - (dataBusinessStatistics?.businessStatistics?.totals?.savingsCompare ?? 0)) / (dataBusinessStatistics?.businessStatistics?.totals?.savingsCompare ?? 1)) * 100) || '0%'})`}
                                                                            </span>
                                                                        )}
                                                                    </Typography>

                                                                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                                                                        {formatCurrency(dataBusinessStatistics?.businessStatistics?.totals?.savings || 0, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                <Box>
                                                    <ChartColumn
                                                        series={businessStatistic}
                                                        labels={customLabels}
                                                    />
                                                </Box>
                                            </Box>
                                        )}
                                    </Box>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                    : <Grid item xs={12} md={12} lg={12} sx={{ marginTop: { xs: 3, md: 0 } }}>
                        <Paper
                            sx={{
                                backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                                height: { xs: '100%', sm: '760px' },
                                borderRadius: 2,
                                marginTop: 2,
                                marginBottom: 1
                            }}
                        >

                            <div style={{ width: '100%' }}>
                                <Box sx={{ padding: '20px 25px', display: 'flex', justifyContent: 'space-between' }}>
                                    <Box>
                                        <Typography variant="overline" color="textSecondary" sx={{ mb: 1 }}>{t('dashboard.brandOverview.business_performance')}</Typography>

                                        <Typography variant="h6" sx={{ mb: 1 }}>
                                            {t('dashboard.brandOverview.business_statistics')}

                                        </Typography>
                                    </Box>
                                    {filterCountry?.length > 1 &&

                                        <Box sx={{ mt: 1 }}>
                                            <Autocomplete
                                                multiple
                                                size="small"
                                                limitTags={3}
                                                disableCloseOnSelect
                                                options={filterCountry || []}
                                                getOptionLabel={(option) => option}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={t('dashboard.filters.countries')}
                                                        sx={{ minWidth: minWidth, maxWidth: 315 }}
                                                    />
                                                )}
                                                value={
                                                    selectedCountriesBusinessStatistic?.length === 0
                                                        ? [t('dashboard.filters.all')]
                                                        : selectedCountriesBusinessStatistic
                                                }
                                                renderOption={(props, option) => {
                                                    const country = countriesJson?.find((c) => c.name === option);
                                                    const flagIcon = country ? `circle-flags:${country.code}` : null;

                                                    return (
                                                        <li {...props} style={{ whiteSpace: 'nowrap' }} key={option}>
                                                            {option !== t('dashboard.filters.all') && (
                                                                <>
                                                                    <Checkbox
                                                                        checked={selectedCountriesBusinessStatistic.includes(option)}
                                                                    />
                                                                    {
                                                                        flagIcon ? (
                                                                            <Iconify icon={flagIcon} width={16} sx={{ mr: 1 }} />
                                                                        ) : (
                                                                            <img style={{ width: 14, height: 14, marginRight: 6 }} src="/assets/location.png" />
                                                                        )
                                                                    }
                                                                </>


                                                            )}
                                                            {option}
                                                        </li>
                                                    );
                                                }}
                                                onChange={handleCountryChangeBusinessStatistic}
                                                renderTags={(selected, getTagProps) =>
                                                    selected.map((option, index) => {
                                                        const country = countriesJson?.find((c) => c.name === option);
                                                        const flagCode = country ? country.code : null;

                                                        return (
                                                            <Chip
                                                                {...getTagProps({ index })}
                                                                key={option}
                                                                icon={
                                                                    option !== t('dashboard.filters.all') ? (
                                                                        flagCode ? (
                                                                            <FlagIcon
                                                                                key={flagCode}
                                                                                code={flagCode}
                                                                                sx={{ width: 16, height: 16, borderRadius: '50%' }}
                                                                            />
                                                                        ) : (
                                                                            <img style={{ width: 14, height: 14, marginRight: 1 }} src="/assets/location.png" />
                                                                        )
                                                                    ) : undefined
                                                                }
                                                                label={option}
                                                                size="small"
                                                                deleteIcon={option === t('dashboard.filters.all') ? <></> : undefined}
                                                                variant="soft"
                                                            />
                                                        );
                                                    })
                                                }
                                                isOptionEqualToValue={(option, value) => option === value}
                                            />
                                        </Box>
                                    }
                                </Box>
                                <Box sx={{ height: { sx: 'auto', sm: 662 } }}>
                                    {!allDataFetched ? (
                                        <Skeleton width={'100%'} height={'100%'} variant="rectangular" animation={"wave"} sx={{
                                            borderBottomLeftRadius: '15px',
                                            borderBottomRightRadius: '15px'
                                        }}>
                                            <>
                                                <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                                <Typography color={"success.main"}>Active</Typography>
                                            </>
                                        </Skeleton>
                                    ) : (
                                        <Box >
                                            <Box sx={{ mb: 3, backgroundColor: theme.palette.mode === "dark" ? '#28323c' : '#f4f6f8', py: 3 }}>
                                                <Grid container justifyContent="center" spacing={2} sx={{ px: 5 }}>
                                                    <Grid item xs={12} sm={4} md={4}>
                                                        {workspace?.template !== "E-commerce"
                                                            ? <Box sx={{ width: '100%', backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white', p: 2, borderRadius: 2 }}>
                                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Typography fontSize={13} variant="body1" sx={{ fontWeight: 'bold', whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row' }}>
                                                                        <span style={{ width: 14, height: 14, borderRadius: '50%', backgroundColor: '#0666aa', marginRight: 5, position: 'relative', top: 1 }} />
                                                                        {t('dashboard.brandOverview.blended_leads')}
                                                                        {filterStore?.compare === true && (
                                                                            <span style={{ marginLeft: 4 }}>
                                                                                {((dataBusinessStatistics?.businessStatistics?.totals?.blended_conversions ?? 0) - (data?.total_blended_conversions_compare ?? 0)) / (data?.total_blended_conversions_compare ?? 1) * 100 >= 0
                                                                                    ? `(+${fPercent((((dataBusinessStatistics?.businessStatistics?.totals?.blended_conversions ?? 0) - (data?.total_blended_conversions_compare ?? 0)) / (data?.total_blended_conversions_compare ?? 1)) * 100)})`
                                                                                    : `(${fPercent((((dataBusinessStatistics?.businessStatistics?.totals?.blended_conversions ?? 0) - (data?.total_blended_conversions_compare ?? 0)) / (data?.total_blended_conversions_compare ?? 1)) * 100)})`}
                                                                            </span>
                                                                        )}
                                                                    </Typography>

                                                                </Box>
                                                                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                                                                    {formatNumber(dataBusinessStatistics?.businessStatistics?.totals?.blended_conversions, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}
                                                                </Typography>
                                                            </Box>
                                                            : <Box sx={{ width: '100%', backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white', p: 2, borderRadius: 2 }}>
                                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Typography fontSize={13} variant="body1" sx={{ fontWeight: 'bold', whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row' }}>
                                                                        <span style={{ width: 14, height: 14, borderRadius: '50%', backgroundColor: '#0666aa', marginRight: 5, position: 'relative', top: 1 }} />
                                                                        {t('dashboard.brandOverview.blended_revenue')}
                                                                        {filterStore?.compare === true && (
                                                                            <span style={{ marginLeft: 4 }}>
                                                                                {((workspace?.averageCart * (dataBusinessStatistics?.businessStatistics?.totals?.blended_conversions ?? 0) - workspace?.averageCart * (data?.total_blended_conversions_compare ?? 0))
                                                                                    / (workspace?.averageCart * (data?.total_blended_conversions_compare ?? 1))) * 100 >= 0
                                                                                    ? `(+${fPercent(((workspace?.averageCart * (dataBusinessStatistics?.businessStatistics?.totals?.blended_conversions ?? 0) - workspace?.averageCart * (data?.total_blended_conversions_compare ?? 0))
                                                                                        / (workspace?.averageCart * (data?.total_blended_conversions_compare ?? 1))) * 100)})`
                                                                                    : `(${fPercent(((workspace?.averageCart * (dataBusinessStatistics?.businessStatistics?.totals?.blended_conversions ?? 0) - workspace?.averageCart * (data?.total_blended_conversions_compare ?? 0))
                                                                                        / (workspace?.averageCart * (data?.total_blended_conversions_compare ?? 1))) * 100)})`}
                                                                            </span>
                                                                        )}
                                                                    </Typography>

                                                                </Box>
                                                                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                                                                    {formatCurrency(workspace?.averageCart * dataBusinessStatistics?.businessStatistics?.totals?.blended_conversions, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}
                                                                </Typography>
                                                            </Box>
                                                        }
                                                    </Grid>

                                                    <Grid item xs={12} sm={4} md={4}>
                                                        <Box sx={{ width: '100%', backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white', p: 2, borderRadius: 2 }}>
                                                            <Box>
                                                                <Typography fontSize={13} variant="body1" sx={{ fontWeight: 'bold', whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row' }}>
                                                                    <span style={{ width: 14, height: 14, borderRadius: '50%', backgroundColor: '#fdab0f', marginRight: 5, position: 'relative', top: 1 }} />
                                                                    {t('dashboard.global.ad_cost')}
                                                                    {filterStore?.compare === true && (
                                                                        <span style={{ marginLeft: 4 }}>
                                                                            {((statsData?.totals?.ad_cost ?? 0) - (statsData?.compare_totals?.ad_cost ?? 0)) / (statsData?.compare_totals?.ad_cost ?? 1) * 100 >= 0
                                                                                ? `(+${fPercent((((statsData?.totals?.ad_cost ?? 0) - (statsData?.compare_totals?.ad_cost ?? 0)) / (statsData?.compare_totals?.ad_cost ?? 1)) * 100)})`
                                                                                : `(${fPercent((((statsData?.totals?.ad_cost ?? 0) - (statsData?.compare_totals?.ad_cost ?? 0)) / (statsData?.compare_totals?.ad_cost ?? 1)) * 100)})`}
                                                                        </span>
                                                                    )}
                                                                </Typography>

                                                                {dataBusinessStatistics?.businessStatistics?.totals?.ad_cost &&
                                                                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                                                                        {formatCurrency(dataBusinessStatistics?.businessStatistics?.totals?.ad_cost, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}
                                                                    </Typography>
                                                                }

                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4} md={4}>
                                                        <Box sx={{ width: '100%', backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white', p: 2, borderRadius: 2 }}>
                                                            <Box>
                                                                <Typography fontSize={13} variant="body1" sx={{ fontWeight: 'bold', whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row' }}>
                                                                    <span style={{ width: 14, height: 14, borderRadius: '50%', backgroundColor: '#0b9255', marginRight: 5, position: 'relative', top: 1 }} />
                                                                    {t('dashboard.global.savings')}
                                                                    {filterStore?.compare === true && (
                                                                        <span style={{ marginLeft: 4 }}>
                                                                            {((data?.total_actual_savings ?? 0) - (data?.total_actual_savings_compare ?? 0)) / (data?.total_actual_savings_compare ?? 1) * 100 >= 0
                                                                                ? `(+${fPercent((((data?.total_actual_savings ?? 0) - (data?.total_actual_savings_compare ?? 0)) / (data?.total_actual_savings_compare ?? 1)) * 100) || '0%'} )`
                                                                                : `(${fPercent((((data?.total_actual_savings ?? 0) - (data?.total_actual_savings_compare ?? 0)) / (data?.total_actual_savings_compare ?? 1)) * 100) || '0%'})`}
                                                                        </span>
                                                                    )}
                                                                </Typography>

                                                                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                                                                    {formatCurrency(dataBusinessStatistics?.businessStatistics?.totals?.savings || 0, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            <Box>
                                                <ChartColumn
                                                    series={businessStatistic}
                                                    labels={customLabels}
                                                />
                                            </Box>
                                        </Box>
                                    )}
                                </Box>

                            </div>
                        </Paper>
                    </Grid>
                }

                {filterCountry?.length > 1 ?
                    <Grid container spacing={2} >
                        {/* Brand Traffic */}
                        <Grid item xs={12} md={6} sx={{ width: '100%', marginTop: { xs: 3, md: 0 } }}>
                            <Paper
                                sx={{
                                    backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                    boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                                    height: '640px',
                                    borderRadius: 2,
                                    marginTop: 2,
                                    marginBottom: 1,


                                }}
                            >
                                <div style={{ width: '100%' }}>
                                    <Box sx={{ padding: '20px 25px', display: 'flex', justifyContent: 'space-between' }}>
                                        <Box>
                                            <Typography variant="overline" color="textSecondary" sx={{ mb: 1 }}>Brand FootPrint</Typography>

                                            <Typography variant="h6" sx={{ mb: 1 }}>
                                                {t('nav.brandTraffic')}
                                            </Typography>
                                        </Box>

                                        <Box sx={{ mt: 1 }}>
                                            <InfiniteScrollKeywords
                                                keywords={(filterKeyword || [])}
                                                onKeywordChange={(it: any) => handleKeywordFilterChangeTopCountries(it)}
                                            />
                                        </Box>
                                    </Box>

                                    <Box sx={{ height: 470 }}>
                                        {isFetchingTopCountries || (!topCountriesData && !topCountriesDataError) ? (
                                            <Skeleton width={'100%'} height={'100%'} variant="rectangular" animation={"wave"}>
                                                <>
                                                    <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                                    <Typography color={"success.main"}>Active</Typography>
                                                </>
                                            </Skeleton>
                                        ) : (
                                            <Box>
                                                <DataGridCustomCountries data={dataCountries} />
                                            </Box>
                                        )}
                                    </Box>

                                    <Box
                                        sx={{
                                            borderBottom: '1px #5e6d7b dotted',
                                            width: '100%',
                                        }}
                                    />

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            width: '100%',
                                            height: 50
                                        }}
                                    >
                                        <Link
                                            component="button"
                                            onClick={() => router.push(`/dash/traffic-details/countries?workspace=${workspace?.id}`)}
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: 900,
                                                px: 3,
                                                mt: 2,
                                                textDecoration: 'none',
                                                color: 'inherit',
                                                '&:hover': {
                                                    textDecoration: 'underline',
                                                    cursor: 'pointer',
                                                },
                                            }}
                                        >
                                            <Typography fontSize={14} fontWeight={900}>
                                                {t('nav.workspaces.all')} {'>'}
                                            </Typography>
                                        </Link>
                                    </Box>

                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ width: '100%', }} >
                            <Paper
                                sx={{
                                    backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                    boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                                    height: { xs: '100%', sm: '640px' },
                                    borderRadius: 2,
                                    marginTop: 2,
                                    marginBottom: 1
                                }}
                            >

                                <div style={{ width: '100%' }}>
                                    <Box sx={{ padding: '20px 25px', display: { xs: 'block', sm: 'flex' }, justifyContent: 'space-between' }}>
                                        <Box >
                                            <Typography variant="overline" color="textSecondary" sx={{ mb: 1 }}>{t('nav.brandTraffic')}</Typography>

                                            <Typography variant="h6" sx={{ mb: 1 }}>
                                                {t('nav.trafficAnalysis')}

                                            </Typography>
                                        </Box>

                                        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 1, marginTop: 1 }}>
                                            {filterCountry?.length > 1 &&
                                                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, '& .css-3x67ru-MuiAutocomplete-root': { width: '100%' } }}>
                                                    <Autocomplete
                                                        multiple
                                                        size="small"
                                                        limitTags={3}
                                                        disableCloseOnSelect
                                                        options={filterCountryTrafficAnalysis || []}
                                                        getOptionLabel={(option) => option}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={t('dashboard.filters.countries')}
                                                                sx={{ minWidth: minWidth, maxWidth: 500 }}
                                                            />
                                                        )}
                                                        value={
                                                            selectedCountriesTrafficAnalysis?.length === 0
                                                                ? [t('dashboard.filters.all')]
                                                                : selectedCountriesTrafficAnalysis
                                                        }
                                                        renderOption={(props, option) => {
                                                            const country = countriesJson?.find((c) => c.name === option);
                                                            const flagIcon = country ? `circle-flags:${country.code}` : null;

                                                            return (
                                                                <li {...props} style={{ whiteSpace: 'nowrap' }} key={option}>
                                                                    {option !== t('dashboard.filters.all') && (
                                                                        <>
                                                                            <Checkbox
                                                                                checked={selectedCountriesTrafficAnalysis.includes(option)}
                                                                            />
                                                                            {
                                                                                flagIcon ? (
                                                                                    <Iconify icon={flagIcon} width={16} sx={{ mr: 1 }} />
                                                                                ) : (
                                                                                    <img style={{ width: 14, height: 14, marginRight: 6 }} src="/assets/location.png" />
                                                                                )
                                                                            }
                                                                        </>


                                                                    )}
                                                                    {option}
                                                                </li>
                                                            );
                                                        }}
                                                        onChange={handleCountryChangeTrafficAnalysis}
                                                        renderTags={(selected, getTagProps) =>
                                                            selected.map((option, index) => {
                                                                const country = countriesJson?.find((c) => c.name === option);
                                                                const flagCode = country ? country.code : null;

                                                                return (
                                                                    <Chip
                                                                        {...getTagProps({ index })}
                                                                        key={option}
                                                                        icon={
                                                                            option !== t('dashboard.filters.all') ? (
                                                                                flagCode ? (
                                                                                    <FlagIcon
                                                                                        key={flagCode}
                                                                                        code={flagCode}
                                                                                        sx={{ width: 16, height: 16, borderRadius: '50%' }}
                                                                                    />
                                                                                ) : (
                                                                                    <img style={{ width: 14, height: 14, marginRight: 1 }} src="/assets/location.png" />
                                                                                )
                                                                            ) : undefined
                                                                        }
                                                                        label={option}
                                                                        size="small"
                                                                        deleteIcon={option === t('dashboard.filters.all') ? <></> : undefined}
                                                                        variant="soft"
                                                                    />
                                                                );
                                                            })
                                                        }
                                                        isOptionEqualToValue={(option, value) => option === value}
                                                    />
                                                </Box>
                                            }

                                            <Box>
                                                <InfiniteScrollKeywordsTrafficAnalysis
                                                    keywords={(filterKeywordTrafficAnalysis || [])}
                                                    onKeywordChange={(it: any) => handleKeywordFilterChangeTrafficAnalysis(it)}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box sx={{ height: 470 }}>
                                        {!allDataFetched || isFetchingTrafficAnalysisData ? (
                                            <Skeleton width={'100%'} height={'100%'} variant="rectangular" animation={"wave"}>
                                                <>
                                                    <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                                    <Typography color={"success.main"}>Active</Typography>
                                                </>
                                            </Skeleton>
                                        ) : (
                                            <Box>
                                                <Box sx={{
                                                    justifyContent: 'center',
                                                    mb: 3,
                                                    backgroundColor: theme.palette.mode === "dark" ? '#28323c' : '#f4f6f8',
                                                    py: 3
                                                }}>
                                                    <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{ px: 4 }}>
                                                        <Grid item xs={12} md={6}>
                                                            <Button
                                                                fullWidth
                                                                onClick={() => handleChooseTrafficAnalysis('Traffic distribution')}
                                                                sx={{
                                                                    whiteSpace: 'nowrap',
                                                                    px: 12,
                                                                    backgroundColor: trafficAnalysis === "Traffic distribution" ?
                                                                        theme.palette.mode === "dark" ? '#131a22' : 'white'
                                                                        : 'transparent'
                                                                }}
                                                            >
                                                                {t('dashboard.brandOverview.traffic_distribution')}
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <Button
                                                                fullWidth
                                                                onClick={() => handleChooseTrafficAnalysis('Ad Cost & Blended CTR')}
                                                                sx={{
                                                                    px: 12,
                                                                    whiteSpace: 'nowrap',
                                                                    backgroundColor: trafficAnalysis === "Ad Cost & Blended CTR" ?
                                                                        theme.palette.mode === "dark" ? '#131a22' : 'white'
                                                                        : 'transparent'
                                                                }}
                                                            >
                                                                {t('dashboard.brandOverview.ad_cost_blended_ctr')}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Box>

                                                <Box sx={{ px: 2 }}>
                                                    {trafficAnalysis === "Traffic distribution"
                                                        ? <ChartColumnStacked
                                                            series={BlendedCTRAdCostChart}
                                                            labels={customTrafficAnalysisLabels}
                                                        />
                                                        : <ChartMixed
                                                            series={BrandedTrafficDistributionChart}
                                                            labels={customTrafficAnalysisLabels}
                                                        />
                                                    }
                                                </Box>
                                            </Box>
                                        )}
                                    </Box>

                                    <Box
                                        sx={{
                                            borderBottom: '1px #5e6d7b dotted',
                                            width: '100%',
                                        }}
                                    />

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            width: '100%',
                                            height: 70
                                        }}
                                    >
                                        <Link
                                            component="button"
                                            onClick={() => router.push(`/dash/traffic-analysis?workspace=${workspace?.id}`)}
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: 900,
                                                px: 3,
                                                textDecoration: 'none',
                                                color: 'inherit',
                                                '&:hover': {
                                                    textDecoration: 'underline',
                                                    cursor: 'pointer',
                                                },
                                            }}
                                        >
                                            <Typography fontSize={14} fontWeight={900}>
                                                {t('dashboard.brandOverview.traffic_analysis_dashboard')} {'>'}
                                            </Typography>
                                        </Link>
                                    </Box>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                    : <Grid item xs={12} md={12} lg={12}>
                        <Paper
                            sx={{
                                backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                                height: { xs: '100%', sm: '640px' },
                                borderRadius: 2,
                                marginTop: 2,
                                marginBottom: 1
                            }}
                        >
                            <div style={{ width: '100%' }}>
                                <Box sx={{ padding: '20px 25px', display: 'flex', justifyContent: 'space-between' }}>
                                    <Box>
                                        <Typography variant="overline" color="textSecondary" sx={{ mb: 1 }}>{t('nav.brandTraffic')}</Typography>

                                        <Typography variant="h6" sx={{ mb: 1 }}>
                                            {t('nav.trafficAnalysis')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                                        {filterCountry?.length > 1 &&
                                            <Box>
                                                <Autocomplete
                                                    multiple
                                                    size="small"
                                                    limitTags={3}
                                                    disableCloseOnSelect
                                                    options={filterCountryTrafficAnalysis || []}
                                                    getOptionLabel={(option) => option}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={t('dashboard.filters.countries')}
                                                            sx={{ minWidth: minWidth, maxWidth: 315 }}
                                                        />
                                                    )}
                                                    value={
                                                        selectedCountriesTrafficAnalysis?.length === 0
                                                            ? [t('dashboard.filters.all')]
                                                            : selectedCountriesTrafficAnalysis
                                                    }
                                                    renderOption={(props, option) => {
                                                        const country = countriesJson?.find((c) => c.name === option);
                                                        const flagIcon = country ? `circle-flags:${country.code}` : null;

                                                        return (
                                                            <li {...props} style={{ whiteSpace: 'nowrap' }} key={option}>
                                                                {option !== t('dashboard.filters.all') && (
                                                                    <>
                                                                        <Checkbox
                                                                            checked={selectedCountriesTrafficAnalysis.includes(option)}
                                                                        />
                                                                        {
                                                                            flagIcon ? (
                                                                                <Iconify icon={flagIcon} width={16} sx={{ mr: 1 }} />
                                                                            ) : (
                                                                                <img style={{ width: 14, height: 14, marginRight: 6 }} src="/assets/location.png" />
                                                                            )
                                                                        }
                                                                    </>


                                                                )}
                                                                {option}
                                                            </li>
                                                        );
                                                    }}
                                                    onChange={handleCountryChangeTrafficAnalysis}
                                                    renderTags={(selected, getTagProps) =>
                                                        selected.map((option, index) => {
                                                            const country = countriesJson?.find((c) => c.name === option);
                                                            const flagCode = country ? country.code : null;

                                                            return (
                                                                <Chip
                                                                    {...getTagProps({ index })}
                                                                    key={option}
                                                                    icon={
                                                                        option !== t('dashboard.filters.all') ? (
                                                                            flagCode ? (
                                                                                <FlagIcon
                                                                                    key={flagCode}
                                                                                    code={flagCode}
                                                                                    sx={{ width: 16, height: 16, borderRadius: '50%' }}
                                                                                />
                                                                            ) : (
                                                                                <img style={{ width: 14, height: 14, marginRight: 1 }} src="/assets/location.png" />
                                                                            )
                                                                        ) : undefined
                                                                    }
                                                                    label={option}
                                                                    size="small"
                                                                    deleteIcon={option === t('dashboard.filters.all') ? <></> : undefined}
                                                                    variant="soft"
                                                                />
                                                            );
                                                        })
                                                    }
                                                    isOptionEqualToValue={(option, value) => option === value}
                                                />
                                            </Box>
                                        }

                                        <Box>
                                            <InfiniteScrollKeywordsTrafficAnalysis
                                                keywords={(filterKeywordTrafficAnalysis || [])}
                                                onKeywordChange={(it: any) => handleKeywordFilterChangeTrafficAnalysis(it)}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box sx={{ height: { xs: 'auto', sm: 470 } }}>
                                    {!allDataFetched || isFetchingTrafficAnalysisData ? (
                                        <Skeleton width={'100%'} height={'100%'} variant="rectangular" animation={"wave"}>
                                            <>
                                                <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                                <Typography color={"success.main"}>Active</Typography>
                                            </>
                                        </Skeleton>
                                    ) : (
                                        <Box>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', mb: 3, backgroundColor: theme.palette.mode === "dark" ? '#28323c' : '#f4f6f8', py: 3 }}>
                                                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, width: '100%' }}>
                                                    <Box sx={{ width: { sm: '100%' }, textAlign: 'center' }}>
                                                        <Button onClick={() => handleChooseTrafficAnalysis('Traffic distribution')} sx={{ px: 12, backgroundColor: trafficAnalysis === "Traffic distribution" ? theme.palette.mode === "dark" ? '#131a22' : 'white' : 'transparent' }}>{t('dashboard.brandOverview.traffic_distribution')}</Button>
                                                    </Box>
                                                    <Box sx={{ width: { sm: '100%' }, textAlign: 'center' }}>
                                                        <Button onClick={() => handleChooseTrafficAnalysis('Ad Cost & Blended CTR')} sx={{ px: 12, backgroundColor: trafficAnalysis === "Ad Cost & Blended CTR" ? theme.palette.mode === "dark" ? '#131a22' : 'white' : 'transparent' }}>{t('dashboard.brandOverview.ad_cost_blended_ctr')}</Button>
                                                    </Box>
                                                </Box>
                                            </Box>

                                            <Box sx={{ px: 2 }}>
                                                {trafficAnalysis === "Traffic distribution"
                                                    ? <ChartColumnStacked
                                                        series={BlendedCTRAdCostChart}
                                                        labels={customTrafficAnalysisLabels}
                                                    />
                                                    : <ChartMixed
                                                        series={BrandedTrafficDistributionChart}
                                                        labels={customTrafficAnalysisLabels}
                                                    />
                                                }
                                            </Box>
                                        </Box>
                                    )}
                                </Box>

                                <Box
                                    sx={{
                                        borderBottom: '1px #5e6d7b dotted',
                                        width: '100%',
                                    }}
                                />

                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        width: '100%',
                                        height: 70
                                    }}
                                >
                                    <Link
                                        component="button"
                                        onClick={() => router.push(`/dash/traffic-analysis?workspace=${workspace?.id}`)}
                                        sx={{
                                            fontSize: 14,
                                            fontWeight: 900,
                                            px: 3,
                                            textDecoration: 'none',
                                            color: 'inherit',
                                            '&:hover': {
                                                textDecoration: 'underline',
                                                cursor: 'pointer',
                                            },
                                        }}
                                    >
                                        <Typography fontSize={14} fontWeight={900}>
                                            {t('dashboard.brandOverview.traffic_analysis_dashboard')} {'>'}
                                        </Typography>
                                    </Link>
                                </Box>
                            </div>
                        </Paper>
                    </Grid>
                }

                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} sx={{ width: '100%', marginTop: { xs: 3, md: 0 } }} >
                        <Paper
                            sx={{
                                backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                                height: { xs: 'auto', sm: '580px' },
                                borderRadius: 2,
                                marginTop: 2,
                                marginBottom: 1
                            }}
                        >

                            <div style={{ width: '100%' }}>
                                <Box sx={{ padding: '20px 25px', display: 'flex', justifyContent: 'space-between' }}>
                                    <Box>
                                        <Typography variant="overline" color="textSecondary" sx={{ mb: 1 }}>{t('nav.brandTraffic')}</Typography>

                                        <Typography variant="h6" sx={{ mb: 1 }}>
                                            {t('dashboard.brandOverview.brand_traffic_potential')}
                                        </Typography>
                                    </Box>

                                    <Box>
                                        {filterCountry?.length > 1 &&
                                            <Box sx={{ mt: 1 }}>
                                                <Autocomplete
                                                    multiple
                                                    size="small"
                                                    limitTags={3}
                                                    disableCloseOnSelect
                                                    options={filterCountry || []}
                                                    getOptionLabel={(option) => option}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={t('dashboard.filters.countries')}
                                                            sx={{ minWidth: minWidth, maxWidth: 315 }}
                                                        />
                                                    )}
                                                    value={
                                                        selectedCountriesBrandTrafficPotential?.length === 0
                                                            ? [t('dashboard.filters.all')]
                                                            : selectedCountriesBrandTrafficPotential
                                                    }
                                                    renderOption={(props, option) => {
                                                        const country = countriesJson?.find((c) => c.name === option);
                                                        const flagIcon = country ? `circle-flags:${country.code}` : null;

                                                        return (
                                                            <li {...props} style={{ whiteSpace: 'nowrap' }} key={option}>
                                                                {option !== t('dashboard.filters.all') && (
                                                                    <>
                                                                        <Checkbox
                                                                            checked={selectedCountriesBrandTrafficPotential.includes(option)}
                                                                        />
                                                                        {
                                                                            flagIcon ? (
                                                                                <Iconify icon={flagIcon} width={16} sx={{ mr: 1 }} />
                                                                            ) : (
                                                                                <img style={{ width: 14, height: 14, marginRight: 6 }} src="/assets/location.png" />
                                                                            )
                                                                        }
                                                                    </>


                                                                )}
                                                                {option}
                                                            </li>
                                                        );
                                                    }}
                                                    onChange={handleCountryChangeBrandTrafficPotential}
                                                    renderTags={(selected, getTagProps) =>
                                                        selected.map((option, index) => {
                                                            const country = countriesJson?.find((c) => c.name === option);
                                                            const flagCode = country ? country.code : null;

                                                            return (
                                                                <Chip
                                                                    {...getTagProps({ index })}
                                                                    key={option}
                                                                    icon={
                                                                        option !== t('dashboard.filters.all') ? (
                                                                            flagCode ? (
                                                                                <FlagIcon
                                                                                    key={flagCode}
                                                                                    code={flagCode}
                                                                                    sx={{ width: 16, height: 16, borderRadius: '50%' }}
                                                                                />
                                                                            ) : (
                                                                                <img style={{ width: 14, height: 14, marginRight: 1 }} src="/assets/location.png" />
                                                                            )
                                                                        ) : undefined
                                                                    }
                                                                    label={option}
                                                                    size="small"
                                                                    deleteIcon={option === t('dashboard.filters.all') ? <></> : undefined}
                                                                    variant="soft"
                                                                />
                                                            );
                                                        })
                                                    }
                                                    isOptionEqualToValue={(option, value) => option === value}
                                                />
                                            </Box>
                                        }
                                    </Box>
                                </Box>
                                <Box sx={{ height: { xs: 'auto', sm: 410 } }}>
                                    {!allDataFetched || isFetchingBrandTrafficPotentialData ? (
                                        <Skeleton width={'100%'} height={'100%'} variant="rectangular" animation={"wave"}>
                                            <>
                                                <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                                <Typography color={"success.main"}>Active</Typography>
                                            </>
                                        </Skeleton>
                                    ) : (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            <ChartDonutCustom
                                                labels={brandTrafficPotentialData?.keywords?.map((item: any) => {
                                                    const label = item?.search_term;
                                                    return label.length > 25 ? `${label.slice(0, 25)}..` : label;
                                                })}
                                                series={brandTrafficPotentialData?.keywords?.map((item: any) => Number(item?.total_organic_searches))}
                                            />
                                        </Box>
                                    )}
                                </Box>
                            </div>

                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ width: '100%' }} >
                        <Paper
                            sx={{
                                backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                                height: '580px',
                                borderRadius: 2,
                                marginTop: 2,
                                marginBottom: 1
                            }}
                        >
                            <div style={{ width: '100%' }}>
                                <Box sx={{ padding: '20px 25px', display: 'flex', justifyContent: 'space-between' }}>
                                    <Box>
                                        <Typography variant="overline" color="textSecondary" sx={{ mb: 1 }}>{t('nav.brandTraffic')}</Typography>

                                        <Typography variant="h6" sx={{ mb: 1 }}>
                                            {t('dashboard.brandOverview.brand_keywords')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ mt: 1 }}>
                                        {filterCountry?.length > 1 &&
                                            <Box>
                                                <Autocomplete
                                                    multiple
                                                    size="small"
                                                    limitTags={3}
                                                    disableCloseOnSelect
                                                    options={filterCountry || []}
                                                    getOptionLabel={(option) => option}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={t('dashboard.filters.countries')}
                                                            sx={{ minWidth: minWidth, maxWidth: 315 }}
                                                        />
                                                    )}
                                                    value={
                                                        selectedCountriesTopKeywords?.length === 0
                                                            ? [t('dashboard.filters.all')]
                                                            : selectedCountriesTopKeywords
                                                    }
                                                    renderOption={(props, option) => {
                                                        const country = countriesJson?.find((c) => c.name === option);
                                                        const flagIcon = country ? `circle-flags:${country.code}` : null;

                                                        return (
                                                            <li {...props} style={{ whiteSpace: 'nowrap' }} key={option}>
                                                                {option !== t('dashboard.filters.all') && (
                                                                    <>
                                                                        <Checkbox
                                                                            checked={selectedCountriesTopKeywords.includes(option)}
                                                                        />
                                                                        {
                                                                            flagIcon ? (
                                                                                <Iconify icon={flagIcon} width={16} sx={{ mr: 1 }} />
                                                                            ) : (
                                                                                <img style={{ width: 14, height: 14, marginRight: 6 }} src="/assets/location.png" />
                                                                            )
                                                                        }
                                                                    </>


                                                                )}
                                                                {option}
                                                            </li>
                                                        );
                                                    }}
                                                    onChange={handleCountryChangeTopKeywords}
                                                    renderTags={(selected, getTagProps) =>
                                                        selected.map((option, index) => {
                                                            const country = countriesJson?.find((c) => c.name === option);
                                                            const flagCode = country ? country.code : null;

                                                            return (
                                                                <Chip
                                                                    {...getTagProps({ index })}
                                                                    key={option}
                                                                    icon={
                                                                        option !== t('dashboard.filters.all') ? (
                                                                            flagCode ? (
                                                                                <FlagIcon
                                                                                    key={flagCode}
                                                                                    code={flagCode}
                                                                                    sx={{ width: 16, height: 16, borderRadius: '50%' }}
                                                                                />
                                                                            ) : (
                                                                                <img style={{ width: 14, height: 14, marginRight: 1 }} src="/assets/location.png" />
                                                                            )
                                                                        ) : undefined
                                                                    }
                                                                    label={option}
                                                                    size="small"
                                                                    deleteIcon={option === t('dashboard.filters.all') ? <></> : undefined}
                                                                    variant="soft"
                                                                />
                                                            );
                                                        })
                                                    }
                                                    isOptionEqualToValue={(option, value) => option === value}
                                                />
                                            </Box>
                                        }
                                    </Box>

                                </Box>
                                <Box sx={{ height: 410 }}>

                                    {!allDataFetched || isFetchingTopKeywordsData ? (
                                        <Skeleton width={'100%'} height={'100%'} variant="rectangular" animation={"wave"}>
                                            <>
                                                <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                                <Typography color={"success.main"}>Active</Typography>
                                            </>
                                        </Skeleton>
                                    ) : (
                                        <Box>
                                            <DataGridCustomKeywords data={dataKeywords} />
                                        </Box>
                                    )}
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        width: '100%',
                                        height: 20
                                    }}
                                >
                                    <Link
                                        component="button"
                                        onClick={() => router.push(`/dash/traffic-analysis?workspace=${workspace?.id}`)}
                                        sx={{
                                            fontSize: 14,
                                            fontWeight: 900,
                                            px: 3,
                                            position: 'relative',
                                            marginTop: 3.5,
                                            textDecoration: 'none',
                                            color: 'inherit',
                                            '&:hover': {
                                                textDecoration: 'underline',
                                                cursor: 'pointer',
                                            },
                                        }}
                                    >
                                        <Typography fontSize={14} fontWeight={900}>
                                            {t('nav.workspaces.all')} {'>'}
                                        </Typography>
                                    </Link>
                                </Box>

                            </div>
                        </Paper>
                    </Grid>
                </Grid>

                <Grid container spacing={2}  >
                    <Grid item xs={12} md={6} sx={{ width: '100%' }} >
                        <Paper
                            sx={{
                                backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                                height: { xs: 'auto', sm: '750px' },
                                borderRadius: 2,
                                marginTop: 2,
                                marginBottom: 1
                            }}
                        >

                            <div style={{ width: '100%' }}>
                                <Box sx={{ padding: '20px 25px', display: { xs: 'block', sm: 'flex' }, justifyContent: 'space-between' }}>
                                    <Box>
                                        <Typography variant="overline" color="textSecondary" sx={{ mb: 1 }}>{t('nav.competitiveIntelligence')}</Typography>

                                        <Typography variant="h6" sx={{ mb: 1 }}>
                                            {t('dashboard.brandOverview.top_competitors')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 1, marginTop: 1 }}>
                                        {filterCountry?.length > 1 &&
                                            <Box>
                                                <Autocomplete
                                                    multiple
                                                    size="small"
                                                    limitTags={1}
                                                    disableCloseOnSelect
                                                    options={filterCountryTopCompetitors || []}
                                                    getOptionLabel={(option) => option}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={t('dashboard.filters.countries')}
                                                            sx={{ minWidth: minWidth, maxWidth: lgUp ? 315 : '100%' }}
                                                        />
                                                    )}
                                                    value={
                                                        selectedCountriesTopCompetitors?.length === 0
                                                            ? [t('dashboard.filters.all')]
                                                            : selectedCountriesTopCompetitors
                                                    }
                                                    renderOption={(props, option) => {
                                                        const country = countriesJson?.find((c) => c.name === option);
                                                        const flagIcon = country ? `circle-flags:${country.code}` : null;

                                                        return (
                                                            <li {...props} style={{ whiteSpace: 'nowrap' }} key={option}>
                                                                {option !== t('dashboard.filters.all') && (
                                                                    <>
                                                                        <Checkbox
                                                                            checked={selectedCountriesTopCompetitors.includes(option)}
                                                                        />
                                                                        {
                                                                            flagIcon ? (
                                                                                <Iconify icon={flagIcon} width={16} sx={{ mr: 1 }} />
                                                                            ) : (
                                                                                <img style={{ width: 14, height: 14, marginRight: 6 }} src="/assets/location.png" />
                                                                            )
                                                                        }
                                                                    </>
                                                                )}
                                                                {option}
                                                            </li>
                                                        );
                                                    }}
                                                    onChange={handleCountryChangeTopCompetitors}
                                                    renderTags={(selected, getTagProps) =>
                                                        selected.map((option, index) => {
                                                            const country = countriesJson?.find((c) => c.name === option);
                                                            const flagCode = country ? country.code : null;

                                                            return (
                                                                <Chip
                                                                    {...getTagProps({ index })}
                                                                    key={option}
                                                                    icon={
                                                                        option !== t('dashboard.filters.all') ? (
                                                                            flagCode ? (
                                                                                <FlagIcon
                                                                                    key={flagCode}
                                                                                    code={flagCode}
                                                                                    sx={{ width: 16, height: 16, borderRadius: '50%' }}
                                                                                />
                                                                            ) : (
                                                                                <img style={{ width: 14, height: 14, marginRight: 1 }} src="/assets/location.png" />
                                                                            )
                                                                        ) : undefined
                                                                    }
                                                                    label={option}
                                                                    size="small"
                                                                    deleteIcon={option === t('dashboard.filters.all') ? <></> : undefined}
                                                                    variant="soft"
                                                                />
                                                            );
                                                        })
                                                    }
                                                    isOptionEqualToValue={(option, value) => option === value}
                                                />
                                            </Box>
                                        }

                                        <Box>
                                            <InfiniteScrollKeywordsTopCompetitors
                                                keywords={(filterKeywordTopCompetitors || [])}
                                                onKeywordChange={(it: any) => handleKeywordFilterChangeTopCompetitors(it)}
                                            />
                                        </Box>
                                    </Box>

                                </Box>

                                <Box sx={{ height: 550 }}>
                                    {!topCompetitorsData || isFetchingTopCompetitorsData ? (
                                        <Skeleton width={'100%'} height={'100%'} variant="rectangular" animation={"wave"}>
                                            <>
                                                <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                                <Typography color={"success.main"}>Active</Typography>
                                            </>
                                        </Skeleton>
                                    ) : (
                                        <DataGridCustomPresence pageSize={1} data={dataPresence} />
                                    )}
                                </Box>

                                <Box
                                    sx={{
                                        borderBottom: '1px #5e6d7b dotted',
                                        width: '100%',
                                    }}
                                />

                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        width: '100%',
                                        height: 70,
                                        position: 'relative',
                                        top: 15
                                    }}
                                >
                                    <Link
                                        component="button"
                                        onClick={() => router.push(`/dash/competitors-monitoring?workspace=${workspace?.id}`)}
                                        sx={{
                                            fontSize: 14,
                                            fontWeight: 900,
                                            px: 3,
                                            textDecoration: 'none',
                                            color: 'inherit',
                                            '&:hover': {
                                                textDecoration: 'underline',
                                                cursor: 'pointer',
                                            },
                                        }}
                                    >
                                        <Typography fontSize={14} fontWeight={900}>
                                            {t('dashboard.brandOverview.competitor_overview_dashboard')} {'>'}
                                        </Typography>
                                    </Link>
                                </Box>

                            </div>

                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ width: '100%' }} >
                        <Paper
                            sx={{
                                backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                                height: { xs: 'auto', sm: '750px' },
                                borderRadius: 2,
                                marginTop: 2,
                                marginBottom: 1,
                            }}
                        >
                            <div style={{ width: '100%' }}>
                                <Box sx={{ padding: '20px 25px', display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between' }}>
                                    <Box>
                                        <Typography variant="overline" color="textSecondary" sx={{ mb: 1 }}>{t('nav.competitiveIntelligence')}</Typography>

                                        <Typography variant="h6" sx={{ mb: 1, textWrap: 'nowrap' }}>
                                            {t('dashboard.brandOverview.top_competitor_ads')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 1, marginTop: 1 }}>
                                        {filterCountry?.length > 1 &&

                                            <Box>
                                                <Autocomplete
                                                    multiple
                                                    size="small"
                                                    limitTags={3}
                                                    disableCloseOnSelect
                                                    options={filterCountryTopCompetitorsAds || []}
                                                    getOptionLabel={(option) => option}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={t('dashboard.filters.countries')}
                                                            sx={{ minWidth: minWidth, maxWidth: lgUp ? 315 : '100%' }}
                                                        />
                                                    )}
                                                    value={
                                                        selectedCountriesTopCompetitorsAds?.length === 0
                                                            ? [t('dashboard.filters.all')]
                                                            : selectedCountriesTopCompetitorsAds
                                                    }
                                                    renderOption={(props, option) => {
                                                        const country = countriesJson?.find((c) => c.name === option);
                                                        const flagIcon = country ? `circle-flags:${country.code}` : null;

                                                        return (
                                                            <li {...props} style={{ whiteSpace: 'nowrap' }} key={option}>
                                                                {option !== t('dashboard.filters.all') && (
                                                                    <>
                                                                        <Checkbox
                                                                            checked={selectedCountriesTopCompetitorsAds.includes(option)}
                                                                        />
                                                                        {
                                                                            flagIcon ? (
                                                                                <Iconify icon={flagIcon} width={16} sx={{ mr: 1 }} />
                                                                            ) : (
                                                                                <img style={{ width: 14, height: 14, marginRight: 6 }} src="/assets/location.png" />
                                                                            )
                                                                        }
                                                                    </>
                                                                )}
                                                                {option}
                                                            </li>
                                                        );
                                                    }}
                                                    onChange={handleCountryChangeTopCompetitorsAds}
                                                    renderTags={(selected, getTagProps) =>
                                                        selected.map((option, index) => {
                                                            const country = countriesJson?.find((c) => c.name === option);
                                                            const flagCode = country ? country.code : null;

                                                            return (
                                                                <Chip
                                                                    {...getTagProps({ index })}
                                                                    key={option}
                                                                    icon={
                                                                        option !== t('dashboard.filters.all') ? (
                                                                            flagCode ? (
                                                                                <FlagIcon
                                                                                    key={flagCode}
                                                                                    code={flagCode}
                                                                                    sx={{ width: 16, height: 16, borderRadius: '50%' }}
                                                                                />
                                                                            ) : (
                                                                                <img style={{ width: 14, height: 14, marginRight: 1 }} src="/assets/location.png" />
                                                                            )
                                                                        ) : undefined
                                                                    }
                                                                    label={option}
                                                                    size="small"
                                                                    deleteIcon={option === t('dashboard.filters.all') ? <></> : undefined}
                                                                    variant="soft"
                                                                />
                                                            );
                                                        })
                                                    }
                                                    isOptionEqualToValue={(option, value) => option === value}
                                                />
                                            </Box>
                                        }
                                        <Box>
                                            <InfiniteScrollKeywordsTopCompetitorsAds
                                                keywords={(filterKeywordTopCompetitorsAds || [])}
                                                onKeywordChange={(it: any) => handleKeywordFilterChangeTopCompetitorsAds(it)}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box sx={{ height: { xs: 'auto', sm: 550 } }}>
                                    {!ads || isFetchingAds ? (
                                        <Skeleton width={'100%'} height={'100%'} variant="rectangular" animation={"wave"}>
                                            <>
                                                <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                                <Typography color={"success.main"}>Active</Typography>
                                            </>
                                        </Skeleton>
                                    ) : (
                                        <Box>
                                            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'center', mb: 3, backgroundColor: theme.palette.mode === "dark" ? '#28323c' : '#f4f6f8', py: 3 }}>
                                                <Box sx={{ width: { xs: '100%', sm: '50%' }, textAlign: 'center' }}>
                                                    <Button onClick={() => handleChooseTopCompetitorsAds('Text Ads')} sx={{ px: { xs: 8, sm: 12 }, backgroundColor: topCompetitorsAds === "Text Ads" ? theme.palette.mode === "dark" ? '#131a22' : 'white' : 'transparent' }}>{t('dashboard.brandOverview.text_ads')}</Button>
                                                </Box>
                                                <Box sx={{ width: { xs: '100%', sm: '50%' }, textAlign: 'center' }}>
                                                    <Button disabled={ads?.length === 0 ? true : false} onClick={() => handleChooseTopCompetitorsAds('Product Listing Ads')} sx={{ px: { xs: 8, sm: 12 }, backgroundColor: topCompetitorsAds === "Product Listing Ads" ? theme.palette.mode === "dark" ? '#131a22' : 'white' : 'transparent' }}>{t('dashboard.brandOverview.product_listing_ads')}</Button>
                                                </Box>
                                            </Box>

                                            {topCompetitorsAds === "Product Listing Ads"
                                                ? <>
                                                    {ads?.map((ad: any, index: any) => (
                                                        <Box key={index} sx={{ marginBottom: 2, px: 3, mb: 3 }}>
                                                            <Stack direction="row" alignItems="center" spacing={2}>
                                                                {ad?.image
                                                                    ? <Avatar
                                                                        variant="square"
                                                                        src={ad?.image}
                                                                        alt={ad?.title}
                                                                        sx={{ width: 45, height: 45, borderRadius: 1 }}
                                                                    />
                                                                    : <Box sx={{ width: 45, height: 45, borderRadius: 1, backgroundColor: theme.palette.mode === 'dark' ? '#28323c' : '#f4f6f8', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                        <Iconify sx={{ width: 20, height: 20 }} color={theme.palette.mode === 'dark' ? 'white' : '#28323c'} icon={`mdi:shopping`} />
                                                                    </Box>
                                                                }
                                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexGrow: 1 }}>
                                                                    <Box>
                                                                        <Typography
                                                                            fontSize={14}
                                                                            fontWeight={700}
                                                                            variant="body1"
                                                                            color={theme.palette.mode === 'dark' ? "white" : "black"}
                                                                            sx={{
                                                                                maxWidth: { xs: '15rem', sm: '450px' },  // Limite la largeur du texte
                                                                                whiteSpace: 'nowrap',
                                                                                overflow: 'hidden',
                                                                                textOverflow: 'ellipsis'
                                                                            }}
                                                                        >
                                                                            {ad.title.length > 70 ? `${ad.title.substring(0, 70)}...` : ad.title}
                                                                        </Typography>

                                                                        <Stack direction="row" alignItems="center" spacing={1}>
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row' }} fontSize={12} color="gray">
                                                                                <Box sx={{ position: 'relative', top: -3 }}>
                                                                                    <img style={{ width: 12, height: 12 }} src={`data:image/png;base64,${ad?.favicon_base64}`} alt="Favicon" />
                                                                                </Box>
                                                                                <Box sx={{ ml: .5 }}>
                                                                                    {ad.seller_name}
                                                                                </Box>
                                                                            </Box>

                                                                            <span style={{ color: '#797a7a' }}>•</span>

                                                                            <Typography fontSize={12} color="gray">
                                                                                <Iconify sx={{ mr: .5, position: 'relative', top: 2 }} width={14} icon="mdi:eye" />
                                                                                {ad?.impression_share?.toFixed(2) + "%"}
                                                                            </Typography>

                                                                            <span style={{ color: '#797a7a' }}>•</span>

                                                                            <Typography fontSize={12} color="gray">
                                                                                <Iconify sx={{ mr: .5, position: 'relative', top: 2 }} width={14} icon={"mdi:podium-gold"} />
                                                                                {ad?.item_position}
                                                                            </Typography>
                                                                        </Stack>
                                                                    </Box>
                                                                    <Box marginLeft="auto" sx={{ ml: 1, position: 'relative', top: -8 }}>
                                                                        <Typography variant="body1" color="white" fontSize={13} sx={{ textWrap: 'nowrap' }}>
                                                                            <span style={{ backgroundColor: theme.palette.mode === 'dark' ? '#343c47' : '#eaecf0', padding: 4, color: theme.palette.mode === 'dark' ? "white" : '#637381', borderRadius: 4, fontWeight: 700 }}>
                                                                                {ad.price}
                                                                            </span>
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                            </Stack>
                                                        </Box>
                                                    ))}
                                                </>
                                                : <>
                                                    {txtAds?.map((ad: any, index: any) => (
                                                        <Box key={index} sx={{ px: 3, mb: 3 }}>
                                                            <Stack direction="row" alignItems="center" spacing={2}>
                                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexGrow: 1 }}>
                                                                    <Box>
                                                                        <Stack direction={'row'} spacing={1} sx={{ display: 'flex', alignItems: 'center', mb: 1, justifyContent: 'flex-start' }}>
                                                                            <Avatar sx={{ width: 24, height: 24, border: '1px solid #dbdde1', backgroundColor: '#f1f3f4' }} src={ad?.link_base64 || "/assets/no_img.png"} />
                                                                            <Box gap={1} >
                                                                                <Typography variant="body1" sx={{ fontSize: 12, color: theme.palette.mode === 'dark' ? '#ffffff' : '#000' }} >{ad.domain}</Typography>
                                                                                <Typography variant="body1" sx={{ fontSize: 12, color: theme.palette.mode === 'dark' ? '#ffffff' : '#000' }} >{urlTransformer(ad.link)}</Typography>
                                                                            </Box>
                                                                        </Stack>
                                                                        <Typography fontSize={14} fontWeight={700} variant="body1" color="#7da5dd">
                                                                            {ad?.ad_title.length > 40 ? `${ad?.ad_title.substring(0, 40)}...` : ad?.ad_title}
                                                                        </Typography>
                                                                        {ad?.description &&
                                                                            <Stack direction="row" alignItems="center" spacing={1}>
                                                                                <Box
                                                                                    sx={{
                                                                                        color: '#a7acb1',
                                                                                        fontSize: 12,
                                                                                        whiteSpace: 'nowrap',
                                                                                        overflow: 'hidden',
                                                                                        textOverflow: 'ellipsis',
                                                                                        width: '100%',
                                                                                        maxWidth: { lg: '650px', xl: '650px', xs: '380px', sm: '380px' },
                                                                                    }}
                                                                                >
                                                                                    {ad?.description}
                                                                                </Box>
                                                                            </Stack>
                                                                        }
                                                                    </Box>
                                                                </Box>
                                                            </Stack>
                                                        </Box>
                                                    ))}
                                                </>
                                            }
                                        </Box>
                                    )}
                                </Box>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

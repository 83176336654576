import { useState, useEffect, useCallback, useRef } from 'react';
import {
  DataGridPro,
  GridColDef,
  GridColumnVisibilityModel,
  GridRowModel,
  GridToolbar,
  useGridApiContext,
  GridRenderCellParams,
  GridRenderEditCellParams
} from '@mui/x-data-grid-pro';
import { Autocomplete, Box, IconButton, TextField, Typography } from '@mui/material';
import Iconify from '../iconify';
import { useScrapingExistMutation } from 'src/context/api/admin/api';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { usePopover } from '../custom-popover';
import React from 'react';
import { useGetCampaignsQuery, useGetKeywordsQuery, useGetCountriesQuery, useGetProfileQuery } from 'src/context/api/liveBidding/api';

type Country = {
  country_code: string;
  country_name: string;
};

type DataRow = {
  id: number;
  keyword: string;
  country: Country;
  frequency: string;
  device: string[];
  status?: boolean;
  isDeleted?: boolean;
  isDuplicate?: boolean;
};

type EditCellProps = {
  id: number | string;
  value: any;
  field: string;
};

const DeviceEditInputCell: any = ({ id, value, field }: EditCellProps) => {
  const apiRef = useGridApiContext();

  const handleChange = useCallback(
    (event: any, newValue: string[]) => {
      if (newValue.length === 0) {
        return;
      }
      apiRef.current.setEditCellValue({ id, field, value: newValue }, event);
      apiRef.current.stopCellEditMode({ id, field });
    },
    [apiRef, id, field]
  );

  return (
    <Box sx={{ width: '100%', '& .MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall': { padding: 1.3 } }}>
      <Autocomplete
        multiple
        options={['desktop', 'mobile']}
        disableClearable
        value={value || []}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
        disableCloseOnSelect
        size="small"
      />
    </Box>
  );
};

const KeywordEditInputCell = ({ id, value, field, api }: GridRenderEditCellParams) => {
  const workspace: any = useCurrentWorkspace();

  const { data: keywordOptions, isLoading: isLoadingKeywords } = useGetKeywordsQuery({
    client: workspace?.bqTable,
  });

  const handleKeywordChange = (event: any, newValue: string) => {
    api.setEditCellValue({ id, field: 'keyword', value: newValue });
    api.stopCellEditMode({ id, field });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Autocomplete
        disableClearable
        options={keywordOptions || []}
        value={value || ''}
        onChange={(event, newValue) => handleKeywordChange(event, newValue)}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
        disabled={isLoadingKeywords}
        size='medium'
      />
    </Box>
  );
};

const CountryEditInputCell = ({ id, value, field, api }: GridRenderEditCellParams) => {
  const workspace: any = useCurrentWorkspace();

  const { data: countryOptions, isLoading: isLoadingCountries } = useGetCountriesQuery({
    client: workspace?.bqTable,
  });

  const countriesArray: any = countryOptions ? Object.values(countryOptions) : [];

  const handleCountryChange = (event: any, newValue: Country | null) => {
    if (newValue) {
      api.setEditCellValue({ id, field: 'country', value: newValue.country_name });
    }

    api.stopCellEditMode({ id, field });
  };

  const selectedCountry = countriesArray.find((opt: any) => opt.country_name === value[0]) || null;

  return (
    <Box sx={{ width: '100%' }}>
      <Autocomplete
        disableClearable
        options={countriesArray || []}
        getOptionLabel={(option) => option.country_name || ''}
        value={selectedCountry}
        onChange={handleCountryChange}
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            <Iconify sx={{ mr: 1 }} icon={`circle-flags:${option.country_code.toLowerCase()}`} />
            {option.country_name}
          </Box>
        )}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
        disabled={isLoadingCountries}
        size="small"
        isOptionEqualToValue={(option, value) => option.country_name === value}
      />
    </Box>
  );
};



const CampaignEditInputCell = ({ id, value, field, api, row, campaignOptionsRef }: GridRenderEditCellParams) => {
  const workspace: any = useCurrentWorkspace();

  const keyword = row.keyword;
  const profile = row.account;

  const { data: campaignOptions, isLoading: isLoadingcampaign_name } = useGetCampaignsQuery(
    { client: workspace?.bqTable, keyword: keyword, profile, match_type: "Exact" },
  );

  useEffect(() => {
    if (campaignOptions) {
      campaignOptionsRef.current = campaignOptions;
    }
  }, [campaignOptions]);


  const handleCampaignChange = (event: any, newValue: any) => {
    const selectedCampaignNames = newValue.map((campaign: any) => campaign.campaign_name);
    const selectedCampaignIds = newValue.map((campaign: any) => campaign.campaign_id);

    api.setEditCellValue({ id, field: 'campaign_name', value: selectedCampaignNames });

    api.stopCellEditMode({ id, field });
  };

  const selectedcampaign_name = campaignOptions?.filter((opt: any) => value?.includes(opt.campaign_name)) || [];

  return (
    <Box sx={{ width: '100%' }}>
      <Autocomplete
        multiple
        disableClearable
        options={campaignOptions || []}
        getOptionLabel={(option) => option.campaign_name || ''}
        value={selectedcampaign_name}
        onChange={handleCampaignChange}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
        size="small"
        isOptionEqualToValue={(option, value) => option.campaign_id === value?.campaign_id}
      />
    </Box>
  );
};


const AccountEditInputCell = ({ id, value, field, api, row, accountOptionsRef }: GridRenderEditCellParams) => {
  const workspace: any = useCurrentWorkspace();

  // Récupérer les options de campagnes en fonction du keyword
  const keyword = row.keyword;

  // Récupérer les options de profils à partir de l'API
  const { data: profileOptions, isLoading: isLoadingProfiles } = useGetProfileQuery({
    client: workspace?.bqTable,
    keyword
  });

  // Mettre à jour la référence des options d'account lorsque les options changent
  useEffect(() => {
    if (profileOptions) {
      accountOptionsRef.current = profileOptions; // Met à jour la référence
    }
  }, [profileOptions]);

  const handleProfileChange = (event: any, newValue: any) => {
    if (newValue) {
      // Mettre à jour la valeur de l'account et de l'account_id
      api.setEditCellValue({ id, field: 'account', value: newValue.profile });
      api.setEditCellValue({ id, field: 'account_id', value: newValue.profile_id });

      // Stopper l'édition du champ account
      api.stopCellEditMode({ id, field });
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Autocomplete
        disableClearable
        options={profileOptions || []} // Charger les options de profils
        getOptionLabel={(option) => option.profile} // Afficher profil et profile_id
        value={profileOptions?.find((option: any) => option.profile === value) || null} // La valeur actuelle du champ
        onChange={handleProfileChange} // Appeler la fonction handleProfileChange lors d'une modification
        renderInput={(params) => <TextField {...params} variant="outlined" />}
        disabled={isLoadingProfiles} // Désactiver pendant le chargement
        size='medium'
      />
    </Box>
  );
};


const createColumns = (
  deleteItem: (id: number | string) => void,
  resetItem: (id: number | string) => void,
  editedRows: Map<number | string, Partial<GridRowModel>>, // Ajout de la parenthèse de fermeture
  campaignOptionsRef: React.RefObject<any>,
  accountOptionsRef: React.RefObject<any>
): GridColDef[] => [
    {
      field: 'Actions',
      headerName: '',
      width: 100,
      sortable: false,
      filterable: false,
      disableExport: true,
      renderCell: (params: GridRenderCellParams) => (
        <ActionMenu
          params={params}
          editedRow={editedRows.has(params.id)}
          deleteItem={deleteItem}
          resetItem={resetItem}
        />
      ),
    },
    {
      field: 'keyword',
      headerName: 'Keyword',
      flex: 1,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) => (
        <KeywordEditInputCell {...params} />
      ),
    },
    {
      field: 'country',
      headerName: 'Country',
      editable: true,
      flex: 1,
      filterable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Box display="flex" alignItems="center">
            <Iconify sx={{ mr: 1 }} icon={`circle-flags:${params.row.country_code}`} />
            {params.row.country}
          </Box>
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) => (
        <CountryEditInputCell {...params} />
      ),
    },
    {
      field: 'account',
      headerName: 'Account',
      editable: true,
      flex: 1,
      renderEditCell: (params: GridRenderEditCellParams) => (
        <AccountEditInputCell accountOptionsRef={accountOptionsRef} {...params} />
      ),
    },
    {
      field: 'device_gads',
      headerName: 'Active Mode Device(s)',
      flex: 1,
      editable: true,
      type: 'custom',
      renderCell: (params: GridRenderCellParams) => (
        <span>
          {params.value.join(', ')}
        </span>
      ),
      renderEditCell: (params: GridRenderEditCellParams) => (
        <DeviceEditInputCell {...params} />
      ),
    },
    {
      field: 'campaign_name',
      headerName: 'Campaign(s)',
      editable: true,
      flex: 1,
      renderEditCell: (params: GridRenderEditCellParams) => (
        <CampaignEditInputCell campaignOptionsRef={campaignOptionsRef} {...params} />
      ),
    },
    {
      field: 'account_id',
      headerName: 'account_id',
      editable: true,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: true,
      hideable: true,
      hideSortIcons: true,
    },
    {
      field: 'campaign_id',
      headerName: 'campaign_id',
      editable: true,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: true,
      hideable: true,
      hideSortIcons: true,
    },
    {
      field: 'device',
      headerName: 'Scraping Device(s)',
      flex: 1,
      editable: true,
      type: 'custom',
      renderCell: (params: GridRenderCellParams) => (
        <span>
          {params.value.join(', ')}
        </span>
      ),
      renderEditCell: (params: GridRenderEditCellParams) => (
        <DeviceEditInputCell {...params} />
      ),
    },

    {
      field: 'status',
      headerName: 'Status',
      editable: true,
      type: 'boolean',
      flex: 1,
    },
  ];

// Action menu component
type ActionMenuProps = {
  params: GridRenderCellParams;
  editedRow: boolean;
  deleteItem: (id: number | string) => void;
  resetItem: (id: number | string) => void;
};

const ActionMenu = ({ params, deleteItem, resetItem, editedRow }: ActionMenuProps) => {
  const handleDelete = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    deleteItem(params.row.id);
  };

  const handleReset = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    resetItem(params.row.id);
  };

  return (
    <div>
      <IconButton
        onClick={handleReset}
        onMouseDown={(e) => e.stopPropagation()}
        onMouseUp={(e) => e.stopPropagation()}
        disabled={!editedRow}
      >
        <Iconify sx={{ opacity: !editedRow ? 0.2 : 1 }} icon="material-symbols:refresh" />
      </IconButton>

      <IconButton
        onClick={handleDelete}
        onMouseDown={(e) => e.stopPropagation()}
        onMouseUp={(e) => e.stopPropagation()}
      >
        <Iconify icon="solar:trash-bin-trash-bold" />
      </IconButton>
    </div>
  );
};

type Props = {
  data: DataRow[];
  pageSize: number;
  deleteItem?: (ids: (number | string)[]) => void;
  handleSave: (editedRows: { id: string | number; changes: Partial<GridRowModel> }[]) => void;
  onEdit: (hasEditedRows: boolean) => void;
  onApply: boolean;
  onReset: boolean;
};

export default function DataGridCustomLiveBiddingBasedKoBidding({
  data,
  pageSize,
  deleteItem,
  handleSave,
  onEdit,
  onApply,
  onReset,
}: Props) {

  const campaignOptionsRef = useRef([]);
  const accountOptionsRef = useRef([]);

  const [rows, setRows] = useState<DataRow[]>(data);
  const [initialRows] = useState<Map<number | string, DataRow>>(new Map(data.map(row => [row.id, { ...row }])));
  const [selectionModel, setSelectionModel] = useState<(number | string)[]>([]);
  const [editedRows, setEditedRows] = useState<Map<number | string, Partial<GridRowModel>>>(new Map());
  const [deletedRowIds, setDeletedRowIds] = useState<(number | string)[]>([]);
  const popover = usePopover();
  const [pendingChanges, setPendingChanges] = useState<Map<number | string, Partial<DataRow>>>(new Map());

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    id: true,
    campaign_id: false,
    account_id: false
  });

  const [paginationModel, setPaginationModel] = useState({
    pageSize: pageSize,
    page: 0,
  });

  const handleChangeColumnVisibilityModel = useCallback((newModel: GridColumnVisibilityModel) => {
    setColumnVisibilityModel(newModel);
  }, []);

  const handlePaginationModelChange = useCallback((newModel: any) => {
    setPaginationModel(newModel);
  }, []);

  const [scrapingExist] = useScrapingExistMutation();
  const workspace: any = useCurrentWorkspace();



  const handleProcessRowUpdateError = (error: any) => {
    console.error("Error updating row:", error);
    // Affichez un message d'erreur utilisateur si nécessaire
    alert("An error occurred while updating the row. Please try again.");
  };

  const processRowUpdate = useCallback(
    async (newRow: GridRowModel, oldRow: GridRowModel) => {
      const initialRow: any = initialRows.get(newRow.id);
      const isModified = Object.keys(newRow).some((key) => newRow[key] !== initialRow?.[key]);

      // Vérifiez si le keyword, country, ou account ont changé
      const keywordChanged = newRow.keyword !== oldRow.keyword;
      const countryChanged = newRow.country !== oldRow.country;
      const accountChanged = newRow.account !== oldRow.account;

      if (keywordChanged) {
        newRow.campaign_name = [];
        newRow.campaign_id = []; // Réinitialise campaign_id
        newRow.account = ''; // Vide le champ account
        newRow.account_id = ''; // Vide le champ account_id
        newRow.ad_group = ''; // Vide le champ account_id
        newRow.ad_group_id = ''; // Vide le champ account_id

      }

      if (accountChanged) {
        newRow.campaign_name = []; // Réinitialise campaign_name
        newRow.campaign_id = []; // Réinitialise campaign_id

        // Mettre à jour l'account_id basé sur le nom du compte sélectionné
        const selectedAccount: any = accountOptionsRef.current.find((opt: any) => opt.profile === newRow.account);
        newRow.account_id = selectedAccount ? selectedAccount.profile_id : ''; // Met à jour account_id
      }

      // Mettre à jour campaign_id basé sur les noms des campagnes
      if (newRow.campaign_name && campaignOptionsRef.current) {
        const selectedCampaignNames = newRow.campaign_name; // Obtenez les noms des campagnes sélectionnées
        const updatedCampaignIds = selectedCampaignNames.map((name: string) => {
          const campaign: any = campaignOptionsRef.current.find((opt: any) => opt.campaign_name === name);
          return campaign ? campaign.campaign_id : null; // Obtenir l'ID ou null si pas trouvé
        }).filter(Boolean); // Filtrer les nulls

        newRow.campaign_id = updatedCampaignIds; // Mettre à jour campaign_id avec les IDs trouvés
      }

      // Mettez à jour la ligne avec les changements
      const updatedRow = {
        ...newRow,
      };

      // Mettez à jour la liste des lignes et enregistrez les modifications
      setRows((prevRows: any) =>
        prevRows.map((row: any) => (row.id === newRow.id ? updatedRow : row))
      );

      if (isModified) {
        setEditedRows((prev) => {
          const updated = new Map(prev);
          updated.set(newRow.id, updatedRow);
          handleSave(Array.from(updated.entries()).map(([id, changes]) => ({ id, changes })));
          onEdit(updated.size > 0);
          return updated;
        });
      }

      return updatedRow;
    },
    [initialRows, rows, scrapingExist, handleSave, onEdit] // Assurez-vous de ne pas inclure des hooks ici
  );



  useEffect(() => {
    if (data) {
      setRows((prevRows) => {
        // Fusionner les nouvelles données avec l'état local
        const updatedRows = data.map((newRow: DataRow) => {
          const existingRow = prevRows.find((row) => row.id === newRow.id);

          if (existingRow) {
            // Conserver l'état local des lignes modifiées ou supprimées
            if (existingRow.isDeleted) {
              return { ...newRow, isDeleted: true };
            }
            if (editedRows.has(newRow.id)) {
              return existingRow; // Conserver les modifications locales
            }
          }

          // Sinon, retourner la nouvelle ligne telle qu'elle est
          return newRow;
        });

        return updatedRows;
      });
    }
  }, [data, editedRows]); // Ajouter editedRows pour éviter de réinitialiser les lignes modifiées


  const handleDeleteItem = useCallback(
    (id: number | string) => {
      // Trouver la ligne à supprimer par ID
      const rowToDelete = rows.find((row) => row.id === id);

      if (rowToDelete) {
        // Mettre à jour uniquement la ligne spécifique à supprimer
        const updatedRows = rows.map((row) =>
          row.id === id ? { ...row, isDeleted: true } : row
        );

        // Mettre à jour l'état des lignes
        setRows(updatedRows);
        setDeletedRowIds((prev) => [...prev, id]);

        // Mettre à jour les lignes éditées
        setEditedRows((prev) => {
          const updated = new Map(prev);
          updated.set(id, { ...rowToDelete, isDeleted: true });
          handleSave(Array.from(updated.entries()).map(([id, changes]) => ({ id, changes })));
          onEdit(updated.size > 0);
          return updated;
        });
      }
    },
    [rows, handleSave, onEdit]
  );

  const handleResetItem = useCallback(
    (id: number | string) => {
      const originalRow = data.find((row) => row.id === id);
      if (originalRow) {
        setRows((prevRows) => prevRows.map((row) => (row.id === id ? originalRow : row)));
        setEditedRows((prev) => {
          const updated = new Map(prev);
          updated.delete(id);
          handleSave(Array.from(updated.entries()).map(([id, changes]) => ({ id, changes })));
          onEdit(updated.size > 0);
          return updated;
        });
      }
    },
    [data, handleSave, onEdit]
  );

  const handleReset = useCallback(() => {
    setRows([...data]);
    setEditedRows(new Map());
    setSelectionModel([]);
    setDeletedRowIds([]);
    onEdit(false);
  }, [data, onEdit]);

  useEffect(() => {
    if (onReset) {
      handleReset();
    }
  }, [onReset, handleReset]);

  useEffect(() => {
    if (!onApply) {
      setEditedRows(new Map());
    }
  }, [onApply]);

  const NoRowsOverlay = () => (
    <Box sx={{ p: 2, textAlign: 'center' }}>
      <Typography variant="h6" color="textSecondary">
        No results found
      </Typography>
    </Box>
  );

  // Get class names for rows and cells
  const getRowClassName = (params: any) => {
    const classes: string[] = [];
    if (params.row.isDeleted) {
      classes.push('deleted');
    } else if (params.row.status === false) {
      classes.push('status-false');
    }
    if (params.row.isDuplicate) {
      classes.push('duplicate'); // Add class for duplicates
    }
    if (editedRows.has(params.id)) {
      classes.push('edited');
    }
    return classes.join(' ');
  };

  const getCellClassName = (params: any) => {
    if (params.row.status === false && ['keyword', 'country', 'frequency', 'device'].includes(params.field)) {
      return 'cell-opacity';
    }
    return '';
  };

  return (
    <Box
      sx={{
        '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
          outline: 'none !important',
        },
        '& .MuiDataGrid-row.edited': {
          backgroundColor: '#666546',
          '&:hover': {
            backgroundColor: '#666546 !important',
          },
        },
        '& .MuiDataGrid-row.deleted': {
          backgroundColor: '#4c3333',
          '&:hover': {
            backgroundColor: '#4c3333 !important',
          },
        },
        '& .MuiDataGrid-row.edited.status-false': {
          backgroundColor: '#666546', /* Override with edited color */
        },
        '& .MuiDataGrid-row.duplicate': {
          backgroundColor: '#4c3333!important', /* Background color for duplicate rows */
          '&:hover': {
            backgroundColor: '#4c3333 !important',
          },
        },
        '& .MuiDataGrid-cell.cell-opacity': {
          opacity: 0.3,
        },
      }}
    >
      <DataGridPro
        disableRowSelectionOnClick
        onProcessRowUpdateError={handleProcessRowUpdateError} // Ajoutez cette ligne
        rows={rows}
        columnVisibilityModel={columnVisibilityModel}
        pagination
        paginationModel={paginationModel}
        columns={createColumns(handleDeleteItem, handleResetItem, editedRows, campaignOptionsRef, accountOptionsRef)} // Passez campaignOptionsRef ici
        onColumnVisibilityModelChange={handleChangeColumnVisibilityModel}
        onPaginationModelChange={handlePaginationModelChange}
        disableDensitySelector
        onRowSelectionModelChange={(newSelectionModel) => setSelectionModel(newSelectionModel as (number | string)[])}
        processRowUpdate={processRowUpdate}
        getRowClassName={getRowClassName}
        getCellClassName={getCellClassName}
        slots={{
          noRowsOverlay: NoRowsOverlay,
          toolbar: GridToolbar

        }}
      />
    </Box>
  );
}
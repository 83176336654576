import React from 'react';
import { useSelector } from 'react-redux';
import Chart, { useChart } from 'src/components/chart';

const StackedBarChartWithLine = ({ series, labels }: any) => {
  const filterStore = useSelector((state: any) => state.filters);

  const stackedColors = [
    '#AD1457', '#C2185B', '#D81B60', '#E91E63',
    '#EC407A', '#F06292', '#F48FB1', '#F8BBD0'
  ];

  const complementaryColors = [
    '#009688', '#FF5722', '#3F51B5', '#00BCD4',
    '#795548', '#607D8B', '#9E9E9E'
  ];

  const getColor = (index: any, isStacked: any) => {
    if (isStacked) {
      return stackedColors[index % stackedColors.length];
    } else {
      return complementaryColors[index % complementaryColors.length];
    }
  };

  const isStacked = series?.some((seriesItem: any) => seriesItem.stacked === true);

  const yaxis = [
    {
      title: {
        text: 'Currency',
      },
      labels: {
        formatter: function (value: any) {
          return value !== undefined ? `$${value?.toFixed(1)}` : "";
        },
      },
      min: 0,
      opposite: true, // Axe de droite
    },
    {
      title: {
        text: 'Number',
      },
      labels: {
        formatter: function (value: any) {
          return value !== undefined ? `${value?.toFixed(1)}` : "";
        },
      },
      min: 0,
      opposite: false, // Axe de gauche
    },
  ];

  // Attribution des couleurs, des axes Y et du mode empilé
  series.forEach((seriesItem: any, index: any) => {
    seriesItem.yAxis = seriesItem.format === 'usd' ? 1 : 0;
    seriesItem.color = getColor(index, seriesItem.type === 'bar');
    if (seriesItem.type === 'bar' && isStacked) {
      seriesItem.stacked = true;
    }
  });

  const chartOptions = useChart({
    chart: {
      type: 'line',
      stacked: isStacked,
    },
    series: series,
    stroke: {
      width: 2,
      show: true,
    },
    plotOptions: {
      bar: {
        columnWidth: '50%',
        dataLabels: {
          position: 'top',
        },
        borderRadius: 0,
        barHeight: '70%',
      },
    },
    labels,
    xaxis: {
      type: 'category',
      // labels: {
      //   formatter: filterStore?.timestep === "date"
      //     ? (val) => {
      //       const date = new Date(val);
      //       const year = date.getUTCFullYear();
      //       const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      //       const day = String(date.getUTCDate()).padStart(2, '0');
      //       return `${year}-${month}-${day}`;
      //     }
      //     : undefined,
      // },
    },
    markers: {
      size: series.filter((item: any) => item.type === 'line').map(() => 6),
    },
    yaxis,
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (value, { seriesIndex, w }) => {
          const seriesFormat = w.config.series[seriesIndex]?.format;
          return seriesFormat === 'usd' ? `$${value?.toFixed(1)}` : `${value?.toFixed(1)}`;
        },
      },
    },
    legend: {
      position: 'top',
    },
    colors: series.map((item: any, index: any) => getColor(index, item.type === 'bar')),
  });

  return <Chart options={chartOptions} series={series} type="line" height={350} />;
};

export default StackedBarChartWithLine;

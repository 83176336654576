import Chart, { useChart } from 'src/components/chart';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { fPercent } from 'src/utils/format-number';
import Box from '@mui/material/Box';

type Props = {
  series: {
    name: string;
    data: number[];
  }[];
  labels: any;
};

export default function ChartColumnStacked({ series, labels }: Props) {
  const theme = useTheme();
  const filterStore = useSelector((state: any) => state.filters);
  enum ColorSchema {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
    SUCCESS = 'success'
  }

  const chartOptions = useChart({
    chart: {
      stacked: false,
      zoom: {
        enabled: true,
      },
    },
    labels: labels || [],
    series: series,
    legend: {
      itemMargin: {
        vertical: 8,
      },
      position: 'top',
      offsetY: 20,
    },

    fill: {
      colors: [theme.palette[ColorSchema.INFO].light]
    },
    colors: [theme.palette[ColorSchema.INFO].light],
    plotOptions: {
      bar: { barHeight: '80%', columnWidth: '40%', borderRadius: 0 },

    },


    stroke: {
      show: false,
    },
    xaxis: {
      // labels: {
      //   formatter: filterStore?.timestep === "date"
      //     ? (val) => {
      //       const date = new Date(val);
      //       const year = date.getUTCFullYear();
      //       const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      //       const day = String(date.getUTCDate()).padStart(2, '0');
      //       return `${year}-${month}-${day}`;
      //     }
      //     : undefined,
      // },
    },
    tooltip: {

      shared: true,
      intersect: false,
      y: {
        formatter: (val: number) => {
          return `${fPercent(val)}` || '0%';
        },
      },
    },
    yaxis: [
      {
        labels: { formatter(value) { return fPercent(value) } }
      },
    ]
  });

  return (
    <Box sx={{
      '.apexcharts-canvas .apexcharts-legend-text': {
        lineHeight: '18px !important',
        textTransform: 'lowercase !important',
      },
    }}>
      <Chart dir="ltr" type="bar" series={series} options={chartOptions} height={420} />
    </Box>
  );
}

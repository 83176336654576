import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { Autocomplete, Box, Button, Chip, Dialog, DialogActions, MenuItem, Paper, Skeleton, Stack, TextField, Typography, useTheme } from "@mui/material";
import FormProvider from 'src/components/hook-form/form-provider';
import { useForm } from "react-hook-form";
import DataGridCustomUsers from "src/components/data-grid/data-grid-custom-users";
import { RHFSelect, RHFTextField } from "src/components/hook-form";
import Iconify from "src/components/iconify";
import { useGetUsersQuery, useGetWorkspacesQuery, useAddUserMutation, useDeleteUserMutation, useUpdateUserMutation } from "src/context/api/admin/api";
import { useInviteMutation } from "src/context/api/workspaces";
import { useBoolean } from "src/hooks/use-boolean";
import * as yup from 'yup';
import { enqueueSnackbar } from "notistack";
import { ConfirmDialog } from "src/components/custom-dialog";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useLocales } from "src/locales";
import { useCurrentUserQuery } from "src/context/api/auth"

export default function AdminUsers() {
    const theme = useTheme();
    const inviteDialog = useBoolean(false);
    const confirmDialog = useBoolean(false);
    const confirmEditDialog = useBoolean(false);
    const [userIdToDelete, setUserIdToDelete]: any = useState<string | null>(null);
    const [userToEdit, setUserToEdit]: any = useState<string | null>(null);
    const { data: users, error, isLoading, refetch: refetchUsers }: any = useGetUsersQuery();
    const location = useLocation()
    const { t } = useLocales()
    const [deleteUser] = useDeleteUserMutation();
    const [updateUser] = useUpdateUserMutation();
    const currentUser: any = useCurrentUserQuery();
    const role = currentUser?.data?.role;

    const handleEditUser = async (id: string, data: any) => {
        {(role === "brand manager")}
        const user = users?.users.find((user: any) => user.id === id);
        setUserToEdit(user);
        confirmEditDialog.onTrue();
    }

    const handleDelete = async (id: string) => {
        try {
            const parsedId = parseInt(id);
            await deleteUser({ user_id: parsedId }).unwrap().then(() => {
                enqueueSnackbar(t('dashboard.snackbar.delete_success'));
            })

            refetchUsers();
        } catch (error: any) {
            enqueueSnackbar(t('dashboard.snackbar.failed_to_delete_user'), { variant: 'error' });

        }
    };

    const handleDeleteRows = (id: any) => {
        if (id) {
            handleDelete(id);
        }
    };

    useEffect(() => {
        refetchUsers()
    }, [refetchUsers])

    useEffect(() => {
        if (location?.pathname?.split("/")[3] === "add") {
            inviteDialog.onTrue()
        }
    }, [location])
    
    return (
        <Box sx={{ px: 2, pb: 2, pt: 1 }}>
            <Paper
                sx={{
                    backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                    boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                    height: 'auto',
                    borderRadius: 2,
                    marginBottom: 1
                }}
            >
                {isLoading || (!users && !error)
                    ? <Skeleton width="100%" height="100%" style={{ borderRadius: 10 }} variant="rectangular" animation="wave">
                        <Iconify py="auto" icon="eva:checkmark-circle-2-fill" color="success.main" />
                        <Typography color="success.main">Active</Typography>
                    </Skeleton>
                    : <>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ paddingTop: 18, paddingLeft: 25, paddingRight: 25 }}>
                                <Typography variant="h6" sx={{ mb: 5 }}>
                                    {t('dashboard.admin.users')}
                                </Typography>
                            </div>
                            <div style={{ paddingTop: 18, paddingLeft: 25, paddingRight: 25 }}
                            >
                                <Button
                                    onClick={() => inviteDialog.onTrue()}
                                    variant="contained"
                                    size="small"
                                    startIcon={<Iconify icon="mingcute:add-line" />}>
                                    {t('dashboard.admin.add_user')}
                                </Button>
                            </div>
                        </div>

                        <Box sx={{ height: 'auto' }}>
                            <DataGridCustomUsers
                                data={users?.users}
                                deleteItem={(id) => {
                                    setUserIdToDelete(id);
                                    confirmDialog.onTrue();
                                }}
                                editItem={(id) => handleEditUser(id, userToEdit)}
                            />
                        </Box>
                    </>
                }
            </Paper>

            <Dialog open={inviteDialog.value} onClose={inviteDialog.onFalse}>
                <UserRegisterForm onSuccess={inviteDialog.onFalse} onCancel={inviteDialog.onFalse} refetchTable={refetchUsers} />
            </Dialog>

            <ConfirmDialog
                open={confirmDialog.value}
                onClose={confirmDialog.onFalse}
                title="Delete"
                content={
                    <>
                        {t('dashboard.global.are_you_sure_you_want_to_delete')}
                    </>
                }
                action={
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            handleDeleteRows(userIdToDelete);
                            confirmDialog.onFalse();
                        }}
                    >
                        {t('dashboard.global.delete')}
                    </Button>
                }
            />


            <Dialog open={confirmEditDialog.value} onClose={confirmEditDialog.onFalse}>
                <UserRegisterForm
                    onSuccess={confirmEditDialog.onFalse}
                    onCancel={confirmEditDialog.onFalse}
                    refetchTable={refetchUsers}
                    user={userToEdit}
                />
            </Dialog>
        </Box>
    )
}

function UserRegisterForm({
    onSuccess,
    onCancel,
    refetchTable,
    user,

}: {
    onSuccess: () => void;
    onCancel: () => void;
    refetchTable: () => void;
    user?: any
}) {
    const [invite, inviteRequest] = useInviteMutation();
    const [deleteUser] = useDeleteUserMutation();
    const { t } = useLocales()

    const schema = yup.object().shape({
        email: yup.string().email().required(),
        role: yup.string().required(),
        workspaceId: yup.array().required()
    });

    // Form methods
    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            email: user?.email || '',
            role: user?.role || '',
            workspaceId: [],
        },
    });

    const [selectedWorkspaces, setSelectedWorkspaces] = useState<any[]>([]);

    useEffect(() => {
        if (user) {
            methods.reset({
                email: user.email,
                role: user.role,
                workspaceId: user.workspaces.map((ws: any) => ws.id),
            });
            setSelectedWorkspaces(user.workspaces);
        }
    }, [user, methods]);

    const [addUser] = useAddUserMutation();
    const [updateUser] = useUpdateUserMutation();

    const handleRegister = async (data: { email: string; role: string; workspaceId: any; }) => {
        try {
            if (user) {
                const existingWorkspaceIds = user.workspaces.map((ws: any) => ws.id);
                const newWorkspaceIds = data.workspaceId.filter((id: any) => !existingWorkspaceIds.includes(id));

                await updateUser({ user_id: user.id, oldUser: user, newUser: data }).unwrap();

                if (newWorkspaceIds.length > 0) {
                    await invite({
                        workspaceIds: newWorkspaceIds,
                        userId: user.id,
                        userExist: true,
                    });
                }

                enqueueSnackbar(t('dashboard.admin.user_edited_successfully'));

            } else {
                const resp = await addUser({ email: data.email, role: data.role.toLowerCase() }).unwrap();
                handleInvite({ ...data, ...resp});
                enqueueSnackbar(t('dashboard.admin.add_user_successfully'));
            }

            onSuccess();
            refetchTable();
        } catch (error: any) {
            if (error?.data?.details === "UserAlreadyExists")
                enqueueSnackbar(t('dashboard.admin.user_already_exist'), { variant: 'error' });
            else enqueueSnackbar(t('dashboard.admin.failed_to_add_user'), { variant: 'error' });

        }
    };

    const handleInvite = async (form: { role: string; workspaceId: number[]; id: number }) => {
        try {
            await invite({
                workspaceIds: form?.workspaceId,
                userId: form.id
            }).unwrap();
        } catch (error: any) {
            enqueueSnackbar(t('dashboard.admin.failed_to_invite_user'), { variant: 'error' });

        }
    };

    const handleDelete = async (id: string) => {
        try {
            const parsedId = parseInt(id);
            await deleteUser({ user_id: parsedId }).unwrap().then(() => {
                enqueueSnackbar(t('dashboard.admin.user_delete_successfully'));
            })

            refetchTable();
        } catch (error: any) {
            enqueueSnackbar(t('dashboard.admin.failed_to_delete_user'), { variant: 'error' });
        }
    };


    const getWorkspaceNameById = (workspaceId: number) => {
        if (!workspaces) return '';

        const workspace = workspaces.find((workspace: any) => workspace.id === workspaceId);
        return workspace ? workspace.name : '';
    };
    const { data: workspaces, error: errorWorkspaces, isLoading: isLoadingWorkspaces }: any = useGetWorkspacesQuery();

    const confirmDialog = useBoolean(false);
    const currentUser: any = useCurrentUserQuery();
    const role = currentUser?.data?.role;

    return (
        <FormProvider methods={methods} onSubmit={methods.handleSubmit(handleRegister)} >
            <Stack spacing={3} sx={{ p: 3, width: '100%' }}>
                <Typography variant="h6">{user ? t('dashboard.admin.edit_user') : t('dashboard.admin.add_user')}</Typography>

                <RHFTextField fullWidth name="email" label={t('dashboard.admin.email_adress')} disabled={user} />

                <RHFSelect name="role" label={t('dashboard.admin.role')}>
                  {(role === "brand manager")
                    ? (
                      <MenuItem key="customer" value="customer">
                        customer
                      </MenuItem>
                    )
                    : (
                      ["admin", "customer", "consultant", "brand manager"].map((roleOption) => (
                        <MenuItem key={roleOption} value={roleOption}>
                          {roleOption}
                        </MenuItem>
                      ))
                    )}
                </RHFSelect>

                <Autocomplete
                    multiple
                    options={workspaces || []}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField {...params} label={t('dashboard.global.workspace')} />
                    )}
                    renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                            {option.name}
                        </li>
                    )}
                    onChange={(event, value: any) => {
                        const selectedWorkspaceIds = value.map((v: any) => v?.id);
                        methods.setValue('workspaceId', selectedWorkspaceIds);
                        setSelectedWorkspaces(value);
                    }}
                    value={selectedWorkspaces}
                    renderTags={(selected, getTagProps) =>
                        selected.map((option, index) => (
                            <Chip
                                {...getTagProps({ index })}
                                key={option.id || index}
                                label={option.name || getWorkspaceNameById(option)}
                                size="small"
                            />
                        ))
                    }
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                />


                <DialogActions sx={{ padding: 0, justifyContent: 'space-between' }}>
                    {
                        user && <Box>
                            <MenuItem onClick={() => confirmDialog.onTrue()} sx={{ color: 'error.main', border: 'solid 1px red', borderRadius: 1 }}>
                                <Iconify sx={{ mr: 1 }} icon="solar:trash-bin-trash-bold" />
                                {t('dashboard.global.delete')}
                            </MenuItem>
                        </Box>

                    }

                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <Button variant="outlined" onClick={onCancel}>
                            {t('dashboard.global.cancel')}
                        </Button>

                        <LoadingButton
                            type="submit"
                            variant="contained"
                            loading={inviteRequest.isLoading}
                        >
                            {user ? t('dashboard.admin.save_changes') : t('dashboard.admin.add_user')}
                        </LoadingButton>
                    </Box>

                </DialogActions>

                <ConfirmDialog
                    open={confirmDialog.value}
                    onClose={confirmDialog.onFalse}
                    title="Delete"
                    content={
                        <>
                            {t('dashboard.global.are_you_sure_you_want_to_delete')}
                        </>
                    }
                    action={
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                                handleDelete(user?.id);
                                confirmDialog.onFalse();
                                onCancel()
                            }}
                        >
                            {t('dashboard.global.delete')}
                        </Button>
                    }
                />


            </Stack>
        </FormProvider>
    );
}

import { createApi } from "@reduxjs/toolkit/query/react";
import fetchBaseQueryCSRF from "../helper";

const liveBidding = createApi({
  baseQuery: fetchBaseQueryCSRF({ baseUrl: CROSS_API_URL }),
  reducerPath: 'api.liveBidding',
  tagTypes: ['liveBidding'],
  endpoints: (builder) => ({
    getActiveMode: builder.query<any, { workspaceId: any | null }>({
      query: (arg) => ({
        url: '/liveBidding/active-mode',
        method: 'GET',
        params: {
          workspaceId: arg.workspaceId,
        },
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          Accept: 'application/json',
        }
      }),
    }),
    getKoBidding: builder.query<any, { workspaceId: any | null }>({
      query: (arg) => ({
        url: '/liveBidding/ko-bidding',
        method: 'GET',
        params: {
          workspaceId: arg.workspaceId,
        },
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          Accept: 'application/json',
        }
      }),
    }),
    addActiveMode: builder.mutation<any, Partial<any>>({
      query: (newStrategy) => ({
        url: '/liveBidding/active-mode/add',
        method: 'POST',
        body: newStrategy,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    updateActiveMode: builder.mutation<any, { updates: any[] }>({
      query: (arg) => ({
        url: `/liveBidding/active-mode/update`,
        method: 'PUT',
        body: arg.updates,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    deleteActiveMode: builder.mutation<any, { id: number }>({
      query: ({ id }) => ({
        url: `/liveBidding/active-mode/delete/${id}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    getKeywords: builder.query<any, { client: string }>({
      query: ({ client }) => ({
        url: `/liveBidding/keywords/active-mode`,
        method: 'POST',
        body: { client },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    getKeywordsKoBidding: builder.query<any, { client: string }>({
      query: ({ client }) => ({
        url: `/liveBidding/keywords/ko-bidding`,
        method: 'POST',
        body: { client },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    getCampaigns: builder.query<any, { client: string; keyword: string, profile: string, match_type: string }>({
      query: ({ client, keyword, profile, match_type }) => ({
        url: `/liveBidding/campaigns/active-mode`,
        method: 'POST',
        body: { client, keyword, profile, match_type },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    getCountries: builder.query<any, { client: string }>({
      query: ({ client }) => ({
        url: `/liveBidding/countries/active-mode`,
        method: 'POST',
        body: { client },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    getProfile: builder.query<any, { client: string, keyword: string }>({
      query: ({ client, keyword }) => ({
        url: `/liveBidding/profile/active-mode`,
        method: 'POST',
        body: { client, keyword },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    getProfileKoBidding: builder.query<any, { client: string, keyword: string, match_type: string }>({
      query: ({ client, keyword, match_type }) => ({
        url: `/liveBidding/profile/ko-bidding`,
        method: 'POST',
        body: { client, keyword, match_type },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    getMatchTypeKoBidding: builder.query<any, { client: string, keyword: string }>({
      query: ({ client, keyword }) => ({
        url: '/liveBidding/match-type/ko-bidding',
        method: 'POST',
        body: { client, keyword },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    getAdGroupKoBidding: builder.query<any, { client: string, keyword: string, profile: string, campaign: string, match_type: string }>({
      query: ({ client, keyword, campaign, profile, match_type }) => ({
        url: '/liveBidding/ad-group/ko-bidding',
        method: 'POST',
        body: { client, keyword, profile, campaign, match_type },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    getKeywordInfoKoBidding: builder.query<any, { client: string, keyword: string, campaign: string, profile: string, match_type: string, ad_group_name: string }>({
      query: ({ client, keyword, campaign, profile, match_type, ad_group_name }) => ({
        url: '/liveBidding/keyword-info/ko-bidding',
        method: 'POST',
        body: { client, keyword, campaign, profile, match_type, ad_group_name },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    
    getCountriesKoBidding: builder.query<any, { client: string, keyword: string, profile: string, campaign: string, match_type: string }>({
      query: ({ client, keyword, campaign, profile, match_type }) => ({
        url: `/liveBidding/countries/ko-bidding`,
        method: 'POST',
        body: { client, keyword, campaign, profile, match_type },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    addKoBidding: builder.mutation<any, Partial<any>>({
      query: (newKoBiddingStrategy) => ({
        url: '/liveBidding/ko-bidding/add',
        method: 'POST',
        body: newKoBiddingStrategy,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    deleteKoBidding: builder.mutation<any, { id: number }>({
      query: ({ id }) => ({
        url: `/liveBidding/ko-bidding/delete/${id}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    updateKoBidding: builder.mutation<any, { updates: any[] }>({
      query: (arg) => ({
        url: `/liveBidding/ko-bidding/update`,
        method: 'PUT',
        body: arg.updates,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),

  }),
});

export const {
  useGetActiveModeQuery,
  useGetKoBiddingQuery,
  useAddActiveModeMutation,
  useGetKeywordsQuery,
  useGetCampaignsQuery,
  useGetCountriesQuery,
  useDeleteActiveModeMutation,
  useGetProfileQuery,
  useUpdateActiveModeMutation,
  useGetKeywordsKoBiddingQuery,
  useGetMatchTypeKoBiddingQuery,
  useGetAdGroupKoBiddingQuery,
  useGetCountriesKoBiddingQuery,
  useAddKoBiddingMutation,
  useDeleteKoBiddingMutation,
  useGetProfileKoBiddingQuery,
  useUpdateKoBiddingMutation,
  useGetKeywordInfoKoBiddingQuery
} = liveBidding;

export default liveBidding;
